.account-settings-container {
  flex:1;
  margin: 32px 75px 10px;
    flex:1;
    @media screen and (max-width:1440px) {
      margin: 32px 25px 10px;
  }
    @media screen and (max-width:1200px) {
      margin: 32px 7% 10px;
      width:37%;
      padding-top: 0;
    }
    @media screen and (max-width:425px) {
      margin: 30px 3% 10px;
    }
  @extend .profile-settings-content;

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 56px;
}

.profile-settings-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;

  @media screen and (max-width: 786px) {
    grid-template-columns: 1fr;
  }
}


.profile-with-dp {
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: 786px) {
    flex-shrink: 1;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__email {
    font-size: 14px;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    color: $color-body-text-2;
  }
}

.profile-picture-update {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  &__image {
    border-radius: 100%;
    width: 63px;
    height: 63px;
    box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 5px $color-secondary;
  }

  &__btn-text {
    @media screen and (max-width: 786px) {
      display: none;
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1;
    justify-content: flex-end;

    button {
      font-size: 12px;
      font-weight: 400;

      @media screen and (max-width: 786px) {
        .MuiButton-startIcon {
          margin-right: 0 !important;
        }
      }  
    }
  }
}

.change-password {
  &__old-password-container {
    width: calc(50% - 30px / 2);

    @media screen and (max-width: 786px) {
      width: auto;
    }

    &--forgot-password {
      display: flex;
      justify-content: end;
    }
  }

  &__new-password-container {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}
.turn-off-btn{
  background-color: $color-secondary;
}
.two-factor-auth {
  &__header {
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 18px;
    font-weight: 500;
    h4 {
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__desc {
    width: 70%;

    @media screen and (max-width: 786px) {
      width: auto;
    }

    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    /* 18.2px */
    color: $color-body-text-1;
  }

  &__options {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__option {
    display: flex;
    justify-content: space-between;
  align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;

      .btn {
        align-self: center;
        @media screen and (max-width: 600px) {
          align-self: flex-start;
        }
      }
    }

    &__details {
      display: flex;
      gap: 24px;

      svg {
        margin-top: 2px;

        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }

    &__title {
      h4 {
        font-size: 16px;
        font-weight: 500;
      }

      p {
        color: $color-body-text-1;
        font-size: 13px;
        max-width: 360px;
        font-weight: 500;
      }
    }
  }
}

.turn-on-two-factor-auth-container {
  width: 432px;
  z-index: 10000;
  height: 501px;
  background-color: white;
  border-radius: 19px;
  padding: 74px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;

  & img {
    width: 90px
  }

  & h4 {
    font-size: 20px;
    font-weight: 500;
    color:$color-text-secondary;
  }

  & p {
    font-size: 14px;
    color: $color-body-text-2;
    font-weight: 500;
  }

  & .form-textfield {
    input {
      width: 40px;
      height: 50px;
      text-align: center;
      font-size: 20px;
    }
  }
}