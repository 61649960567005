 
.faq-accordian{
    .phone-number,a{
        color: $color-secondary;
        text-decoration: none;
    }
}
.help-faq-section {
    padding: 60px 0;

    &__heading {
        color: $color-text-secondary;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 49px;
        @media (max-width: 767px) {
           
            margin-bottom: 20px;
        }
        @media (max-width: 600px) {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    &__content {
        display: flex;

        &__left {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &__right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: min(525px, 100%);
                height: 286px;

                @media (max-width: 600px) {
                    height: 100%;
                }
            }
        }

        @media (max-width: 1280px) {
            gap: 24px;
        }

        @media (max-width: 1100px) {
            flex-direction: column;
            gap: 50px;

            &__right {
                justify-content: center;
            }
        }
    }

    @media (max-width: 1100px) {
        padding: 50px 0 ;
    }
    @media (max-width: 767px) {
        padding: 30px 0 ;
    }
    @media (max-width: 425px) {
        padding: 20px 0 ;
    }
}
.shipping-info-section{
    margin-top: 15px;
    p{
        color: $color-order-total;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 10px;
        @media screen and (max-width:600px) {
            font-size: 16px;
       }
    }
    span{
        color: $color-order-total;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 10px;
        @media screen and (max-width:600px) {
            font-size: 14px;
       }
    }
    a{
        color:$color-secondary;
        text-decoration: underline;
    }
    .phone-number{
        text-decoration: none;
        color:$color-secondary;
    }
}