.story-slide {
    display: flex;
    width: 765px;
    min-width: 765px;
    min-height: 290px;
    background-color: $color-light;
    border-radius: 19.956px;
    // box-shadow: 0px 7.98259px 17.96083px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 7.98259px 17.96083px -5px rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
    overflow: hidden;

    &:last-child {
        margin-right: 40px;
    }


    &__content {
        display: flex;
        flex-direction: column;
        padding: 28.937px 23.948px 11.974px 23.948px;

        .description {
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            margin-top: 14px;
            margin-bottom: 28px;
            height: 168px;

            @media (max-width: 1150px) {
                height: auto;
            }

            @media (max-width: 700px) {
                font-size: 12px;
            }
        }

        .client-info {
            .name {
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;

                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }

            .desgination {
                color: $color-body-text-1;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                @media (max-width: 600px) {
                    font-size: 8px;
                }
            }
        }
    }

    &__map {
        min-width: 287px;
        // height: 233px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 600px) {
            min-width: 230px;
        }

        @media (max-width: 600px) {
            min-width: 180px;
        }

        @media (max-width: 585px) {
            display: none;
        }

        
    }

    @media (max-width: 1150px) {
        width: 100%;
        min-width: 100%;
    }

    @media (max-width: 1300px) {
        &:last-child {
            margin-right: 0;
        }    
    }
}

.blury {
    // filter: blur(1px);
    opacity: 0.5;
}