.help-get-started {
    padding: 16px 0 135px;

    &__go-back {
        display: flex;
        align-items: center;
        gap: 8px;
        // margin-top: 51px;
        // margin-bottom: 41px;
        margin-top: 31px;
        margin-bottom: 21px;
        color: $color-body-text-1;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
    }

    &__heading {
        font-weight: 500;
        margin-bottom: 41px;

        h1 {
            color: $color-text-secondary;
            font-size: 32px;
            line-height: normal;
            margin-bottom: 8px;
        }

        p {
            color: $color-body-text-2;
            font-size: 16px;
            line-height: 150%;
        }
    }

    &__content {
        display: flex;
        gap: 90px;

        &__tabs-container {
            padding: 26px 30px;
            background-color: $color-light;
            border-radius: 20px;
            width: min(416px, 100%);
            min-width: min(416px, 100%);

            .title {
                color: $color-order-total;
                font-size: 20px;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 26px;
            }

            .search-container {
                width: 100%;
                height: 46px;
                border-radius: 340px;
                border: 1px solid $color-body-text-3;
                background-color: $color-light;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                padding: 17px 22px;
                margin-bottom: 26px;
    
                svg {
                    path {
                        stroke: $color-body-text-3;
                    }
                }
    
                input {
                    border: none;
                    outline: none;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 150%;
                    color: $color-body-text-1;
                    width: 100%;
    
                    &:focus {
                        outline: none;
                    }
    
                    &::placeholder {
                        color: $color-body-text-1;
                    }
    
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
                }
            }

            .tabs-wrapper {
                display: flex;
                flex-direction: column;
                gap: 14px;

                .tab {
                    padding: 12px 15px;
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    cursor: pointer;

                    @media (max-width: 600px) {
                        font-size: 14px;
                    }
                }

                .active {
                    background-color: $color-tab-background;
                }
            }

            @media (max-width: 600px) {
                padding: 16px 18px;
            }
        }

        &__tabs-container {

        }

        @media (max-width: 1024px) {
            flex-direction: column;

            &__tabs-container {
                min-width: 100%;
            }
        }
    }
}