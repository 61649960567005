  @mixin displayFlex($direction:row,$justifyContent:center, $alignItems:center) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-direction: $direction;
    flex-wrap:wrap;
  }
  @mixin fontStyles($color:#021021,$fontSize:14px,$lineHeight:140%, $fontWeight:500,$fontStyle:normal) {
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    font-style: $fontStyle;
    color:$color;
  }