.alert-cards {
    border-radius: 8px;
    background: white;
    margin-bottom: 25px;
    padding: 30px;

    @media screen and (max-width: 991px) {
        padding: 15px;
    }

    & .apply-on-all-devices-top {
        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    & .apply-on-all-devices-bottom {
        display: none;

        @media screen and (max-width: 991px) {
            display: flex;
            justify-content: end;
        }
    }
}

.alert-cards .alert-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 25px;

    @media screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
}

.alert-cards .alert-card-header .title-detail {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.alert-cards .alert-card-header .title-detail span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-left: 8px;
}

.alert-cards .alert-card-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-top: 1px solid $color-body-text-3;
    border-bottom: 1px solid $color-body-text-3;
    padding: 25px 0px 0px;
}

.alert-cards .alert-card-body .label {
    color: $color-body-text-1;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 5px;
}

.alert-cards .alert-card-body .option-card {
    border-radius: 20px;
    background: $color-main-bg;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.alert-cards .select-devices-content,
.alert-cards .select-frequency-content {
    border-top: 1px solid #C8D1E8;
    padding: 15px 25px;
    flex-direction: column;
}

.alert-cards .select-devices-content .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

}

.alert-cards .select-devices-content .header-section .search-bar {
    width: 313px;
    display: flex;
    flex-direction: row;
    padding: 12px 15px;
    border-radius: 22px;
    border: 1px solid #FFF;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.alert-cards .select-devices-content .header-section .multi-select {
    width: 300px;
    display: flex;
    flex-direction: row;
    padding: 3px 0px 3px 12px;
    border-radius: 22px;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width:600px) {
        width: 100%;
        padding: 3px 0px ;
    }
}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-content {
    display: block !important;
    border-radius: 20px;
    background: #F6F8FA;
    padding: 10px;
    border: 1px solid $color-border !important;
    margin-top: 10px;
}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-container,
.alert-cards .select-devices-content .header-section .multi-select .dropdown-container .dropdown-heading {
    background-color: transparent;
    box-shadow: none;
    border-radius: 20px;
    width: 100%;
}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-container div {
    background-color: $color-main-bg;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    border: none;
    box-shadow: none;
}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-container ul * {
    background-color: $color-main-bg;


}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-container ul li span {
    overflow: hidden;
    text-overflow: ellipsis;

}

.alert-cards .select-devices-content .header-section .multi-select .dropdown-container input {
    border: 1px solid $color-body-text-3;
    border-radius: 10px;
}

.alert-cards .select-devices-content .header-section .search-bar input {
    background: transparent;
    padding: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.alert-cards .select-devices-content .header-section .search-bar svg {
    margin-right: 10px;
}

.alert-cards .select-devices-content .body-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    max-height: 6rem;
    overflow-y: auto;
}

.alert-cards .select-devices-content .body-section .devices-list {
    margin-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border: 1px solid $color-secondary;
    border-radius: 20px;
    padding: 0px 14px;
    color: white;
    background: rgba(26, 116, 226, 0.753);
}

.alert-cards .select-devices-content .body-section .devices-list svg {
    margin-left: 8px;
    ;
}

.alert-cards .select-devices-content .body-section .devices-list span {
    font-size: 13px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 176px;

}

.alert-cards .select-frequency-content{
    .frequency-panel{
        display: flex;
    flex-direction: row;
    align-items: center;
    gap:14px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    @media screen and (max-width: 1439px) {
        flex-direction: column;
        align-items: flex-start;
    }
    }
.tabs-section{
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: wrap;
gap:14px;

.frequency-tab{
    border-radius: 8px;
    border:1px solid #BDC9E2;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    height: auto;
    color:#172434;
    padding:8px 25px;
    &.active-tab{
        color:$color-secondary;
        border:1px solid $color-secondary;
        background-color:#77B0F621 ;
    }
}
}
.header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {


        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #021021;
    }

    span {


        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        color: #8899B4;


    }
}
}

.alert-cards .alert-card-body .option-card .card-inner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px;

    @media screen and (max-width: 991px) {
        padding: 14px;
        flex-direction: column;
        align-items: stretch;
    }

}

.alert-cards .alert-card-body .option-card .option-toggle .MuiSwitch-switchBase {
    padding: 3px;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiSwitch-thumb {
    width: 18px;
    height: 19px;
    color: white;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiTouchRipple-root {
    color: white !important;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiFormControlLabel-label {
    font-size: 16px !important;
    margin-left: 10px;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiFormControlLabel-root {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiSwitch-track {
    background-color: #D5D5D5;
    opacity: 1;
}

.alert-cards .alert-card-body .option-card .option-toggle .MuiSwitch-root {
    height: 25px;
    border-radius: 20px;
    width: 45px;
    padding: 0;


}

.alert-cards .alert-card-body .option-card .option-toggle .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background: $color-secondary;
}

.alert-card-body .option-card-detail {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 991px) {
        flex-direction: row-reverse;
        justify-content: space-between;
        border-bottom: 1px solid #A0B0D0;
        padding-bottom: 16px;

        img {
            display: none;
        }
    }
}

.alert-card-body .option-card-detail .option-detail {
    border-left: 1px solid #A0B0D0;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    align-items: center;

    @media screen and (max-width: 991px) {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
    }
}

.alert-card-body .option-card-detail .option-detail img {
    width: 34px;
    height: 34px;
    margin-right: 15px;
}

.alert-card-body .option-card-detail .option-detail .text-section {
    display: flex;
    flex-direction: column;
}

.alert-card-body .option-card-detail .option-detail .text-section .option-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.alert-card-body .option-card-detail .option-detail .text-section .option-subtitle {
    color: $color-body-text-1;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    max-width: 385px;

    @media screen and (max-width: 1440px) {
        max-width: 235px;
    }

    @media screen and (max-width: 1200px) {
        max-width: 220px;
    }

    @media screen and (max-width: 991px) {
        max-width: 385px;
    }

    @media screen and (max-width: 585px) {
        max-width: 235px;
        font-size: 10px;
        margin-right: 5px;
    }
}

.alert-card-body .devices-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
  
        justify-content: space-between;
        padding-top: 16px;
        align-items: center;
    }
}

.alert-card-body .devices-section .input-alert-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #A0B0D0;
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 7px 10px;
    max-width: 100px;

    &+.select-device {
        @media screen and (max-width: 585px) {
            width: 100%;
            justify-content: flex-start;
        }
         
    }

    & fieldset {
        display: none;
    }

    @media screen and (max-width: 991px) {
        margin-right: 5px;
        margin-left: 5px;
    }
}

.alert-card-body .devices-section .temperature-input {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.alert-card-body .devices-section .temperature-input .to {
    color: #BDC9E2;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.alert-card-body .devices-section .MuiInputBase-root {
    background-color: transparent !important;
}

.alert-card-body .devices-section .input-alert-section input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    background: none;
    border-radius: 0;
    height: unset;
    text-align: center;
    background: none;
}

.alert-card-body .devices-section .input-alert-section .unit {
    color: #A0B0D0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.alert-card-body .devices-section .alert-availing-option button {
    padding: 0 !important;
    box-shadow: none;
    border-radius: 0;
    min-width: 0;
    border: none;
    width: 38px;
    height: 100%;
    border-top: 1px solid #A0B0D0;
    border-bottom: 1px solid #A0B0D0;
}

.alert-card-body .devices-section .alert-availing-option .center-btn {
    border-radius: 0;
    border-left: 1px solid #A0B0D0;
    border-right: 1px solid #A0B0D0;
}

.alert-card-body .devices-section .alert-availing-option button img {
    width: 16px;
    height: 16px;
}

.alert-card-body .devices-section .alert-availing-option .active-btn {
    background: $color-secondary;
    color: white;
}

.alert-card-body .devices-section .alert-availing-option .first-btn {
    border-radius: 20px 0px 0px 20px;
    border-left: 1px solid #A0B0D0;
}

.alert-card-body .devices-section .alert-availing-option .last-btn {
    border-radius: 0px 20px 20px 0px;
    border-right: 1px solid #A0B0D0;
}

.alert-card-body .devices-section .alert-availing-option .disable-btn {
    background: white;

    &:disabled {
        background: $color-body-text-3
    }

    svg {
        color: $color-body-text-1;
    }
}

.alert-card-body .devices-section .alert-availing-option {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 114px;
    height: 35px;
    /* background: linear-gradient(0deg, rgba(0, 3, 6, 0.84) 0%, rgba(0, 3, 6, 0.84) 100%), #F4F6FB; */
}

.alert-card-body .select-device {
    box-shadow: none;
    background: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: initial;
    border-left: 1px solid #A0B0D0;
    color: $color-text-secondary;
    padding: 5px !important;
    border-radius: 0 !important;
    margin-left: 9px;
    position: relative;
    padding-left: 10px !important;
    padding-right: 0px !important;
    white-space: nowrap;

    &.Mui-disabled {
        color: #A0B0D0;
        opacity: 0.5;
    }

    @media screen and (max-width: 1200px) {
        border-left: none;
    }

    @media screen and (max-width: 991px) {
        border-left: none;
        border-right: 1px solid #A0B0D0;
        margin-right: 9px;
        margin-left: 0;
        padding-left: 0px !important;
        padding-right: 20px !important;
    }

    @media screen and (max-width: 585px) {
        border-right: none;
        font-size: 12px;
        padding: 0 !important;
        margin-right: 0;
    }

    & svg {
        color: $color-text-secondary;
        margin-right: 8px;
    }
}

.alert-card-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
}

.alert-card-footer button {
    border-radius: 8px;
    background: $color-secondary;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 12px 25px !important;
    text-transform: capitalize;

}