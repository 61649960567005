 .shop-page {
     background-color: #FFFFFF;

     @media screen and (min-width:5760px) {
         min-height: 90vh;
     }

     .shop-page-container {
         @include displayFlex(row, space-between, flex-start);
         width: 100%;
         padding: 70px 0px 16px;

         @media screen and (max-width:991px) {
             padding: 30px 0px 15px;
         }

         .devices-image-carousel {
             @include displayFlex(column-reverse, flex-start, flex-start);
             width: 45%;
             max-height: 699px;

             @media screen and (max-width:1600px) {
                 width: 50%;

             }

             @media screen and (max-width:991px) {
                 width: 100%;
                 margin-bottom: 50px;
             }

             @media screen and (max-width:991px) {
                 max-height: unset;
             }

             @media screen and (max-width:767px) {
                 margin-bottom: 30px;

             }

             .device-selected-img {
                 width: 100%;
                 border-radius: 17px;
                 margin-left: 0;
                 background: #E8EDF2;

                 .img-section {
                     display: flex !important;
                     @include displayFlex();
                     height: 510px;
                     padding: 40px;

                     @media screen and (max-width:585px) {
                         height: 400px;
                         padding: 20px;
                     }

                     @media screen and (max-width:475px) {
                         height: 320px;

                     }

                     @media screen and (max-width:425px) {
                         height: 300px;

                     }

                     img {
                         max-height: 100%;
                         width: auto;
                         min-height: 150px;
                     }
                 }
             }

             .device-slider {
                 width: 100%;

                 @media screen and (max-width:991px) {
                     width: 100%;
                     max-height: 150px;
                     // overflow-y: hidden;
                 }

                 .img-section {
                     border-radius: 8px;
                     background: #E8EDF2;
                     display: flex !important;
                     @include displayFlex();
                     padding: 15px;
                     margin: 10px 0px 10px;
                     height: 152px;
                     cursor: pointer;
                     width: 97% !important;

                     @media screen and (max-width:991px) {
                         width: 95% !important;
                         height: 130px;
                     }

                     @media screen and (max-width:585px) {
                         height: 100px;
                         width: 89%
                     }

                     img {
                         width: auto;
                         max-height: 100px;

                         @media screen and (max-width:991px) {
                             height: 100px;
                         }

                         @media screen and (max-width:585px) {
                             height: 65px;
                         }
                     }
                 }

                 .slick-dots li {
                     width: 10px;

                     button {
                         width: 10px;

                         &::before {
                             width: 10px;
                         }
                     }
                 }

                 .slick-slide.slick-current {
                     .img-section {
                         border: 2px solid $color-secondary;
                     }
                 }

                 .slick-arrow {
                     background-image: url('data:image/svg+xml,<svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.69073 11.0002L0.750072 2.68142C0.466573 2.38509 0.307617 1.98549 0.307617 1.56915C0.307617 1.15281 0.466573 0.753221 0.750072 0.456888C0.889061 0.312129 1.05448 0.197219 1.23677 0.118798C1.41907 0.0403771 1.61462 0 1.81213 0C2.00964 0 2.20519 0.0403771 2.38748 0.118798C2.56977 0.197219 2.73519 0.312129 2.87418 0.456888L11.8705 9.88284C12.143 10.1711 12.2991 10.5553 12.3073 10.9578C12.3155 11.3603 12.1751 11.751 11.9146 12.0507L2.88104 21.5414C2.74232 21.6867 2.57699 21.802 2.39466 21.8807C2.21233 21.9595 2.01665 22 1.81899 22C1.62133 22 1.42564 21.9595 1.24332 21.8807C1.06099 21.802 0.895653 21.6867 0.756934 21.5414C0.473434 21.2451 0.314479 20.8455 0.314479 20.4291C0.314479 20.0128 0.473434 19.6132 0.756934 19.3169L8.69073 11.0002Z" fill="%23B3B3B3"/></svg>');
                     background-repeat: no-repeat;
height: 21px;
                     &::before {
                         content: none;
                     }
                 }

                 .slick-prev {
                     // top: -13px;
                     // left: 45%;
                     transform: rotate(180deg);
                     left: -30px;
                     @media screen and (max-width:991px) {
                         top: 45%;
                         left: -32px;
                         
                     }

                     @media screen and (max-width:585px) {
                         left: -15px;
                     }
                 }
             }
         }

         .text-section {
             width: 50%;
             padding-left: 16px;

             @media screen and (max-width:1600px) {
                 width: 47%;
             }

             @media screen and (max-width:991px) {
                 width: 100%;
                 padding: 0;
             }

             &.item-description-mobile {
                 @media screen and (min-width:992px) {
                     display: none;
                 }

             }

             .item-description-desktop {
                 @media screen and (max-width:991px) {
                     display: none;
                 }
             }

             .title {
                 color: $color-secondary;
                 font-size: 28px;
                 font-weight: $font-weight-semibold;
                 line-height: 34.13px;
                 margin-bottom: 8px;



                 @media screen and (max-width:600px) {
                     font-size: 24px;
                     line-height: 29.26px;

                 }
             }

             .sub-title {
                 color: #2E3B4B;
                 font-size: 16px;
                 font-weight: $font-weight-medium;
                 line-height: 24px;

                 @media screen and (max-width:600px) {
                     font-size: 14px;

                 }
             }

             .shipping-information {
                 margin-top: 10px;
                 @include displayFlex(row, flex-start);
                 width: 100%;


                 @media screen and (max-width:991px) {

                     border-bottom: 0px;
                     padding-bottom: 0;
                 }

                 >div {
                     display: flex;
                     flex-direction: row;
                     align-items: center;
                     gap: 8px;

                     &.bordered {
                         margin-left: 15px;
                         padding-left: 15px;
                         border-left: 1px solid #172434;

                         @media screen and (max-width:395px) {
                             margin-left: 10px;
                             padding-left: 10px;
                         }
                     }
                 }

                 span {
                     color: #172434;
                     font-size: 16px;
                     font-style: normal;
                     font-weight: $font-weight-medium;
                     line-height: 26px;

                     @media screen and (max-width:395px) {
                         font-size: 14px;
                     }



                 }
             }

             .price-section {
                 margin-top: 24px;
                 @include displayFlex(row, flex-start, center);
                 padding-bottom: 24px;
                 border-bottom: 1px solid #88BEFF;

                 @media screen and (max-width:991px) {
                     border-bottom: 0;
                 }

                 .original-price {
                     color: #021021;
                     font-size: 24px;
                     font-weight: 600;
                     line-height: 26px;

                     @media screen and (max-width:600px) {
                         font-size: 20px;
                     }

                 }

                 .saving-section {
                     p {
                         margin: 0;
                     }
                 }

                 .discounted-price {
                     color: $color-red;
                     font-size: 20px;
                     font-weight: $font-weight-medium;
                     text-decoration-line: strikethrough;
                     text-decoration: line-through;
                 }
             }

             .color-qty-box {
                 margin: 24px 0;
                 @include displayFlex(column, flex-start, flex-start);
                 gap: 24px;

                 @media screen and (max-width:991px) {
                    margin-top:15px;
                 }

                 @media screen and (max-width:600px) {
                     flex-direction: column;
                     width: 100%;

                     gap: 20px;
                 }
             }

             .color-section {
                 min-width: 175px;
                 @include displayFlex(column, flex-start, flex-start);

                 @media screen and (max-width:600px) {
                     flex-direction: column;
                     width: 100%;

                     min-width: unset;
                 }

                 p {
                     color: #021021;
                     font-size: 16px;
                     font-weight: $font-weight-semibold;
                     line-height: 19.5px;
                     text-transform: capitalize;

                     .color-name {
                         color: #4F5661;
                         font-weight: 500;
                     }
                 }

                 .color-variation-btn {
                     padding: 1px;
                     box-shadow: none;
                     background: none;
                     transition: none;
                     min-height: unset;
                     min-width: unset;
                     margin-right: 15px;
                     width: 30px;
                     height: 30px;
                     margin-top: 10px;
                     border-radius: 20px;
                     border: 1px solid transparent;
                 }
             }

             .form-textfield {
                 .MuiInputBase-root {
                     background-color: transparent !important;
                 }
             }

             .counter-textfield {
                 @include displayFlex(row, space-between, center);
                 border: 1px solid #8899B4;
                 border-radius: 8px;
                 padding: 10px 8px;
                 flex-wrap: nowrap;
                 svg{
                    width: 11px;
                    height:11px;
                }
                 .increment-btn,
                 .decrement-btn {
                     padding: 0;
                     height: auto;
                     min-width: auto;
                     background: none !important;
                     box-shadow: none;
                     outline: none;
                     height: auto;
                     transition: none !important;
                 }

                 .form-textfield {
                     padding: 0;
                     background-color: none;
                     margin-bottom: 0;
                     margin: 0 15px;
                     @media screen and (max-width:1200px) {
                        margin: 0 10px;
                    }
                     @media screen and (max-width:600px) {
                         margin: 0 7px;
                     }

                     input {
                         max-width: 25px;

                         text-align: center;
                         margin-bottom: 0;
                         background: none !important;
                         border: none;
                         background-color: transparent !important;
                         width: fit-content;
                         border: 0;
                         border-radius: 8px;
                         padding: 0;

                         @media screen and (max-width:1200px) {
                             max-width: 20px;
                         }
                     }

                 }

             }

             .item-counter-container {
                 display: flex;
                 gap: 29px;

                 .item-counter,
                 .subscription-plan {
                     display: flex;
                     justify-content: flex-start;
                     align-items: flex-start;
                     flex-direction: column;



                     span {
                         color: #021021;
                         font-size: 16px;
                         font-weight: $font-weight-semibold;
                         line-height: 42px;
                         margin-bottom: 10px;

                     }
                 }

                 .subscription-plan {
                     position: relative;
                     width: max-content;

                     &__container {
                         display: flex;
                         align-items: center;
                         gap: 6px;
                         border: 1px solid $color-body-text-1;
                         border-radius: 20px;
                         padding: 6px 14px;
                         cursor: pointer;
                         width: 120px;

                         p {
                             font-size: 14px;
                             font-weight: 500;
                             color: $color-body-text-1;
                         }

                         svg {
                             margin-top: 2px;
                         }

                         .active-arrow {
                             transform: rotate(180deg);
                         }
                     }

                     &__dropdown {
                         position: absolute;
                         right: -8px;
                         top: 48px;
                         z-index: 100;
                         padding: 6px;
                         width: 133px;
                         border-radius: px;
                         border: 1px solid $color-body-text-1;
                         display: flex;
                         flex-direction: column;
                         gap: 7px;
                         background-color: #fafafa;

                         .active-item {
                             background-color: rgba(81, 160, 255, 0.1);
                         }

                         .dropdown-item {
                             padding: 5px 12px;
                             border-radius: 8px;
                             display: flex;
                             flex-direction: column;
                             cursor: pointer;

                             .duration {
                                 font-size: 14px;
                                 font-weight: 500;
                                 color: $color-body-text-2;
                             }

                             .discount {
                                 font-size: 10px;
                                 font-weight: 500;
                                 color: $color-red;
                                 text-decoration: line-through;
                             }

                             .price {
                                 font-size: 14px;
                                 font-weight: 600;
                                 color: $color-dark;
                             }
                         }
                     }
                 }

                 @media (max-width: 1476px) {
                     flex-direction: column;
                     gap: 0;
                 }

                 @media (max-width: 1200px) {
                     flex-direction: row;
                     gap: 29px;
                 }

                 @media (max-width: 590px) {
                     flex-direction: column;
                     gap: 0;
                 }
             }

             .pdp-plans {
                 .header {
                     font-weight: 500;
                     font-size: 10px;
                     line-height: 22px;
                     color: #C7000D;
                     margin-left: 10px;
                 }

                 .no-plan-selected {
                     border-radius: 8px;
                     border: 1px solid transparent;
                     padding: 0px;
                     display: flex;
                     flex-direction: column;
                     width: fit-content;

                     @media screen and (max-width:991px) {

                         width: 100%;
                     }

                     @media screen and (max-width:600px) {
                         width: 100%;
                     }

                     .MuiFormControl-root {
                         @media screen and (max-width:600px) {
                             width: 100%;
                         }
                     }

                     &.plan-selected {
                         border: 1px solid #C7000D;
                         padding: 15px 15px 10px;
                     }

                     .MuiFormGroup-row {
                         flex-direction: row;
                         gap: 16px;

                         @media screen and (max-width:600px) {
                             flex-direction: column;
                             width: 100%;
                         }
                     }

                     .MuiFormControlLabel-label {
                         @media screen and (max-width:600px) {

                             width: 100%;
                         }
                     }

                     p {

                         font-weight: 600;
                         font-size: 16px;
                         line-height: 19.5px;
                         color: #021021;
                         margin-bottom: 18px;

                         .sub-title {
                             font-weight: 500;

                             line-height: 20px;
                             color: #8899B4;

                         }
                     }

                     .plan-radiogroup {
                         .MuiFormControlLabel-root {
                             margin: 0;
                             .MuiButtonBase-root {
                                 display: none;
                             }
                         }

                         .plan-inner-labels {
                             display: flex;
                             flex-direction: column;
                             align-items: center;
                             border: 1px solid #8899B4;
                             border-radius: 8px;
                             min-height: 67px;
                             min-width: 143px;
                             margin-bottom: 0px;
                             justify-content: center;
                             gap: 4px;

                             @media screen and (max-width:1200px) {
                                 min-width: 127px;
                             }

                             @media screen and (max-width:991px) {
                                 min-width: 160px;
                             }

                             @media screen and (max-width:600px) {
                                 min-width: 100%;
                             }

                             &.checked-label {
                                 background-color: #E9F3FF;
                                 border: 1px solid #1A74E2;
                             }

                             p {
                                 font-weight: 500;
                                 font-size: 12px;
                                 line-height: 14.63px;
                                 text-align: center;
                                 color: #2E3B4B;
                                 gap: 7px;
                                 margin-bottom: 0;
                             }

                             .price-box {
                                 display: flex;
                                 flex-direction: row-reverse;
                                 align-items: center;
                             }

                             span {

                                 font-weight: 600;
                                 font-size: 16px;
                                 line-height: 19.5px;
                                 text-align: center;
                                 color: #172434;
                             }

                             .discounted-price {
                                 text-decoration: line-through;
                                 color: #F13737;
                                 margin-right: 4px;
                                 font-size: 12px;
                             }
                         }
                     }

                     .subscribe-checkbox {


                         span {

                             font-weight: 500;
                             font-size: 12px;
                             line-height: 22px;
                             color: #172434;
                             padding: 0;
                             padding-left: 12px;
                             margin-top: 10px;


                         }
                     }
                 }
             }

             .add-to-cart {
                 @include displayFlex(row, $justifyContent: flex-start, $alignItems: center);
                 gap: 24px;
                 margin: 24px 0 14px;
                 border-bottom: 1px solid #88BEFF;
                 padding-bottom: 24px;

                 @media screen and (max-width:991px) {
                     margin-bottom: 0;
                 }


                 .add-to-cart-btn {
                     color: #ffffff;
                     text-align: center;
                     font-family: inherit;
                     font-size: 16px;
                     font-weight: $font-weight-medium;
                     line-height: 150%;
                     border: none;
                     text-transform: initial;
                     height: auto;
                     background-color: $color-secondary;
                     padding: 12px 25px;
                     width: fit-content;
                     background: #1A74E2;
                     border-radius: 8px;


                     @media screen and (max-width:600px) {
                         width: 100%;
                     }
                 }

                 @media (max-width: 600px) {
                     width: 100%;
                     margin-top: 26px;
                 }
             }

             .peeq-feature {
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                 gap: 7px;
                 @media screen and (max-width:991px) {
                    margin-top:14px;
                 }
                 p {
                     color: $color-secondary;
                     font-size: 16px;
                     font-style: normal;
                     font-weight: $font-weight-medium;
                     width: 100%;
                     margin-top: 10px;
                     line-height: 18.34px;
                     
                     @media screen and (max-width:600px) {
                         line-height: 24px;
                         font-size: 14px;
                        
                     }
                 }

             }

             .quantity-section-box {
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                 gap: 24px;
                 flex-wrap: wrap;
                 @media screen and (max-width:1200px) {
                  gap:12px;
                }
             }

             .contact-sales-section {
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                 padding: 8px 8px 8px 15px;
                 border-radius: 30px;
                 background-color: #F1F7FF;
                 gap: 10px;
                 max-width: 320px;

                 @media screen and (max-width:600px) {
                     width: fit-content;
                     max-width: 282px;
                 }

                 p {
                     color: #4F5661;
                     font-size: 12px;
                     font-style: normal;
                     font-weight: $font-weight-medium;
                     line-height: 18.12px;
                     flex: 1;

                     @media screen and (max-width:600px) {
                         font-size: 10px;
                         line-height: 15.1px;
                     }

                     span {
                         font-weight: 600 !important;
                         font-size: 12px !important;
                         line-height: 18.12px !important;
                         color: #4F5661 !important;

                         @media screen and (max-width:600px) {
                             font-size: 10px !important;
                             line-height: 15.1px !important;
                         }
                     }

                     .link {
                         color: $color-secondary !important;
                         text-decoration: underline;
                         font-weight: 500 !important;
                         font-size: 12px !important;

                         @media screen and (max-width:600px) {
                             font-size: 10px !important;
                             line-height: 15.1px !important;
                         }
                     }
                 }

             }

             .device-info-message {
                 @include displayFlex(row, flex-start);
                 margin: 5px 0 20px 0;
                 background-color: rgba(200, 225, 255, 0.32);
                 padding: 15px;
                 border-radius: 15px;

                 svg {
                     width: 30px;
                     height: 30px;
                     margin-right: 10px;
                 }

                 p {
                     color: $color-body-text-2;
                     font-size: 12px;
                     font-style: normal;
                     max-width: 78%;
                     font-weight: $font-weight-medium;
                     line-height: 18px;

                     span {
                         color: $color-secondary;
                     }
                 }
             }
         }
     }

     .about-item-tab-panel {
         margin: 16px 0 50px;

         @media screen and (max-width:991px) {
             margin: 15px 0 50px;
         }

         .tab-title {
             color: $color-text-secondary;
             font-size: 30px;
             font-style: normal;
             font-weight: $font-weight-semibold;
             line-height: 39px;
             margin-bottom: 15px;

         }

         @media screen and (max-width:1200px) {
             margin: 30px 0px;

         }

         .MuiBox-root {
             padding: 0;
             border-bottom: 1px solid #88BEFF;
         }

         .MuiTabs-scroller {
             overflow: auto !important;

             &::-webkit-scrollbar {
                 display: none;
             }
         }

         .MuiButtonBase-root.MuiTab-root {
             color: #4F5661;
             font-size: 18px;
             font-weight: $font-weight-medium;
             line-height: normal;
             min-height: auto;
             min-width: auto;
             padding: 15px 0px;
             transition: none;
             text-transform: capitalize;
             margin-right: 50px;

             @media screen and (max-width:991px) {
                 padding: 16px 0px;
                 font-size: 16px;
                 margin-right: 25px;
             }

             @media screen and (max-width:600px) {
                 margin-right: 10px;
             }

         }

         .MuiTabPanel-root {
             padding: 0;
         }

         .MuiButtonBase-root.MuiTab-root.Mui-selected {
             position: relative;
             color: $color-secondary;
             background: none;
             font-weight: $font-weight-semibold;

             &::after {
                 position: absolute;
                 content: " ";
                 left: 0;
                 bottom: 0;
                 width: 100%;
                 height: 3px;
                 background-color: $color-secondary;
             }
         }

         .about-item-tab {

             margin-top: 25px;

             ul {
                 list-style-type: none;
                 display: flex;
                 flex-direction: row;
                 flex-wrap: wrap;
                 width: 100%;

                 @media screen and (max-width:991px) {
                     justify-content: space-between;
                 }

                 li {
                     display: flex;
                     flex-direction: column !important;
                     align-items: flex-start;
                     width: 25%;
                     margin: 30px 0;


                     @media screen and (max-width:1369px) {
                         width: 33.33%;
                     }

                     @media screen and (max-width:991px) {
                         width: 50%;
                         margin: 20px 0;
                     }

                     @media screen and (max-width:600px) {
                         width: 44%;
                     }

                     p {

                         font-weight: 500;
                         font-size: 16px;
                         line-height: 19.5px;
                         color: #021021;
                         margin: 10px 0;
                     }

                     span {


                         font-weight: 500;
                         font-size: 14px;
                         line-height: 21.7px;
                         color: #4F5661;
                         max-width: 280px;
                     }
                 }
             }

             &.specifications-tab {
                 display: flex;
                 flex-direction: row;
                 flex-wrap: wrap;
                 width: 100%;

                 @media screen and (max-width:991px) {
                     flex-direction: column;
                 }

                 ul {

                     @include displayFlex(row, space-between, flex-start);
                     padding: 0;
                     list-style-type: none;
                     margin: 0;

                     @media screen and (max-width:991px) {

                         justify-content: space-between;
                     }

                     li {
                         @include displayFlex(row, flex-start, flex-start);
                         width: 100%;
                         margin: 30px 0px;

                         @media screen and (max-width:991px) {
                             margin: 15px 0;
                         }

                         .list-detail {
                             width: 78%;


                             @media screen and (max-width:1440px) {
                                 width: 98%;
                             }



                             p {
                                 color: #131313;
                                 font-size: 18px;
                                 font-weight: 600;
                                 line-height: 28px;
                                 margin-bottom: 8px;


                             }

                             span {
                                 color: #021021;
                                 font-size: 16px;
                                 line-height: 24px;
                                 max-width: 100%;

                             }
                         }
                     }
                 }
             }

             .view-all-btn {
                 @include displayFlex();
                 background: none;
                 padding: 0;
                 box-shadow: none;
                 min-width: unset;
                 min-height: unset;
                 margin: 0 auto 35px;
                 transition: none;

                 span {
                     color: $color-text-secondary;
                     font-size: 16px;
                     font-weight: $font-weight-semibold;
                     line-height: 150%;
                     text-transform: uppercase;
                     margin-right: 8px;
                 }
             }



             .feature-item-tabs {
                 padding-top: 0;
             }

             .specification-list {
                 list-style-type: none;
                 width: 50%;
                 flex-direction: column;
                 padding: 25px 0;

                 @media screen and (max-width:991px) {
                     width: 100%;
                 }

                 li {
                     width: 100%;
                     display: list-item;
                     margin: 15px 0;
                     position: relative;

                     &::after {
                         content: none;
                     }

                     .list-detail {
                         width: 100%;
                         margin-left: 0;
                         display: flex;
                         flex-direction: column;
                         align-items: flex-start;

                         @media screen and (max-width:767px) {
                             flex-direction: column;
                             align-items: flex-start;
                         }

                         p {
                             margin-bottom: 10px;
                             margin-right: 8px;
                             font-family: 'Montserrat';
                             font-style: normal;
                             font-weight: 600;
                             font-size: 18px;
                             line-height: 30px;
                             color: #021021;
                         }
                     }
                 }
             }

         }
     }
 }

 .MuiButtonBase-root {
     text-transform: initial !important;
 }