.btn {
  text-align: center;
  font-size: 14px;
  font-weight: $font-weight-medium;
  line-height: 150%;
  padding: 14px 25px;
  box-shadow: none;
  border-radius: 8px;
  text-transform: initial;
  width: fit-content;
  white-space: nowrap;
  font-family: inherit;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.btn--contained {
  border: none;
  color: $color-footer-link;
  background: $color-secondary;
}

.btn__spinner {
  margin-right: 10px;
}
button,.MuiButtonBase-root{
  font-family: inherit;
}
.btn--outlined {
  color: $color-secondary;
  border: 1px solid $color-secondary;

  svg {
    color: $color-secondary !important;
  }

  &:disabled {
    color: $color-body-text-1;
    border-color: $color-body-text-1;
    background: transparent;

    svg {
      color: $color-body-text-1 !important;
    }
  }
}

.btn--secondary {
  color: $color-body-text-1;

  svg {
    color: $color-body-text-1;
  }
}

.btn--danger {
  color: $color-red;

  svg {
    color: $color-red;
  }
}