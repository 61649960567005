.imei-tab {

    &__heading {
        color: $color-logo-header;
        font-size: 26px;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 11px;

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &__user-info {
        display: flex;
        align-items: center;
        gap: 11px;
        margin-bottom: 35px;

        img {
            min-width: 34px;
        }

        &__info {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;

            .name {
                color: $color-dark;
            }

            .time-posted {
                color: $color-body-text-1;
            }
        }
    }

    &__desc {
        color: $color-body-text-2;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    .mb-24 {
        margin-bottom: 24px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    &__img {
        width: min(402px, 90%);
        margin-bottom: 24px;
    }
}