.drawer {
    width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background-color:#01152F;
padding:17px 14px;
    &__nav-list {
        list-style: none;
        width:100%;
        margin-top:14px;
        li {
            color: #B0BDD9;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            cursor: pointer;
            margin: 18px 0 !important;
            &:hover{
                color:#ffffff;
            }
             .router-link-active{
                color:#ffffff;
             }
            
        }
    }

    .cross-icon {
        
    }
}

