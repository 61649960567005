.feature-item {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    background-color: $color-light;
    border-radius: 2rem;
    transition: all 0.3s ease;
    height: 250.097px;
    svg, img {
        transition: all 0.3s ease;
        width: 55px;
        height: 62px;
    }

    .heading {
        color: $color-text-secondary;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 150%;
        margin-top: 2.2rem;
        margin-bottom: 8px;
        transition: all 0.3s ease;
    }

    .desc {
        color: $color-body-text-2;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 150%;
        transition: all 0.3s ease;
    }

    &:hover {
        background-color: $color-secondary;
        transform: scale(1.1);
        .heading, .desc {
            color: $color-light;
        }
        svg {
            path {
              fill: $color-light;
            }
        }
    }

    @media (max-width: 600px) {
        height: 225px;
        justify-content: center;
        align-items: center;

        .heading {
            font-size: 17px;
            font-weight: 600;
        }

        .desc {
            text-align: center;
        }
    }
}