@import "./text-content";
@import "./status-item";

.who-we-are {
    padding: 8.3rem 0;
    background-color: $color-who-we-are-bg;

    &__heading {
        color: $color-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 1.7rem;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    &__content-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16.6rem;
        row-gap: 7.2rem;

            h3 {
                color: $color-text-secondary;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                word-break: normal;
                margin-bottom: 40px;

                @media (max-width: 1024px) {
                    margin-bottom: 0;
                }

                @media (max-width: 600px) {
                    font-size: 24px;
                    line-height: 150%;
                }
            }

            p {
                color: $color-dark;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 600px) {
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 500;
                }
            }

            .second-paragraph {
                margin-top: 2rem;
                margin-bottom: 40px;

                @media (max-width: 1024px) {
                    margin-bottom: 22px;
                }
            }

            .status-container {
                display: flex;
                gap: 15.5rem;

                @media (max-width: 1400px) {
                    gap: 8.5rem;
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                    gap: 48px;
                }
            }

            @media (max-width: 1500px) {
                column-gap: 10rem;
            }
            
            @media (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .status-container {
                    margin-top: 35px;
                }
            }
    }

    @media (max-width: 700px) {
        padding-bottom: 5rem;
    }
}
