.links {
    &__heading {
        color: #BDC9E2;
        font-size: 14px;
        line-height: 20.95px;
        text-transform: uppercase;
        margin-bottom: 1.4rem;
        font-weight: 500;
        @media (max-width: 600px) {
            font-size: 12px;
             
          }
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        li {
            font-size: 14px;
            font-weight: 500;
            color: $color-footer-link;
            cursor: pointer;
           
            @media (max-width: 600px) {
                font-size: 12px;
            }
        }
    }
}

.customer-links {
    display: none;
    @media (max-width: 850px) {
        display: block;
    }
}