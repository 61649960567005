
@import "./logo";
@import "./reach-out-to-us";
@import "./email-input";
@import "./popular-links";
@import "./contact";

@import "./pages/lower-footer-pages";
@import "./pages/terms-conditions-modal";
@import "./pages/newsletter-unsubscribe";

.footer-user {
    padding-top: 3.2rem;
    padding-bottom: 2.6rem;
    background-color: #01152F;
    color: $color-light;
   

    .footer-upper {
        margin-bottom: 22px;

        &__content {
            display: flex;
            justify-content: space-between;
            gap: 24px;

            &__links-container {
                display: flex;
                gap: 10rem;
                @media (max-width: 991px) {
                    width: 50%;
                }
                @media (max-width: 817px) {
                    gap: 30rem;
                }

                @media (max-width: 600px) {
                    gap: 20rem;
                    width: 35%;
                }

                @media (max-width: 510px) {
                    gap: 10rem;
                }

                @media (max-width: 390px) {
                    gap: 6rem;
                }
            }

            // @media only screen and (min-width: 601px) and (max-width: 850px) {
            //     flex-wrap: wrap;
            // }
            @media (max-width: 850px) {
                flex-wrap: wrap;
            }
        }
    }

    .divider {
        height: 1px;
        background-color: #BDC9E2;
    }

    .footer-lower {
        //    padding-inline: 18.1rem;
        padding-top: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top:1px solid rgba(#ffffff,0.23);
        .customner-service-list {
            list-style: none;
            display: flex;
            gap: 4rem;
            margin: 0;
            padding: 0;
            @media (max-width: 600px) {
              gap:15px;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
            }
            li {
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                cursor: pointer;
                opacity: 0.8;
                transition: all 0.13s ease;
               
                @media (max-width: 600px) {
                    font-size: 12px;
                }
                

                &:hover {
                    color: $color-light;
                }
            }

            
        }

        .copyright {
            font-size: 14px;
            font-weight: 400;
            color: #BDC9E2;
            @media (max-width: 600px) {
               font-size: 12px;
            }
            
        }

        @media (max-width: 1100px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }
        @media (max-width: 600px) {
            gap:8px;
        }

         
    }

     

     
}

.mapFooter {
    position: absolute;
    bottom: 0;
    left: 42%;
    z-index: 4;
    background-image: url("https://d1nxfqk0pytl4a.cloudfront.net/images/footer-bg.svg");
    color: #FFFFFF;
    background-size: cover;
    border-radius: 10px 10px 0px 0px;
}

.devices-footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.copyright span {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width:500px) {
    .copyright span {
        font-size: 14px;
    }
}