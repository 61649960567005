@import "./user-menu";

.logged-in-user {
    position: relative;
.logged-in-btn{
    padding:0;
    height:auto;
    min-width: auto;
    width: auto;
    background: none;
    transition: none;
    box-shadow: none;
    img,svg{
        width: 34px;
        height: 34px;
        border-radius: 50px;
        object-fit: contain;
        object-position: center;
        
    }
}
    .user-icon {
        cursor: pointer;
       
    }
}