.help-get-in-touch {
    padding-bottom: 100px;
    

    &__heading {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 54px;
        @media (max-width: 767px) {
           
            margin-bottom: 20px;
        }
        h2 {
            color: $color-text-secondary;
            font-size: 32px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 16px;
        }

        p {
            color: $color-body-text-2;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            width: min(564px, 100%);
        }

        @media (max-width: 600px) {
            h2 {
                font-size: 28px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    &__content {
        display: flex;
        gap: 33px;

        &__form {
            flex: 1;
            background-color: $color-light;
            border-radius: 8px;
            padding: 38px 44px;

            .title {
                color: $color-dark;
                font-size: 28px;
                font-weight: 500;
                line-height: 150%;
                margin-bottom: 10px;
            }

            .sub-title {
                color: $color-body-text-2;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                margin-bottom: 29px;
            }

            .form__send-btn {
                background-color: $color-secondary;
                display: flex;
                width: 194px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: $color-light;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                text-transform: capitalize;
                border-radius: 2300px;
                margin-left: auto;

                @media (max-width: 600px) {
                    font-size: 14px;
                    width: 100%;
                }
            }

            @media (max-width: 600px) {
                padding: 15px 20px;
                .title {
                    font-size: 26px;
                }
    
                .sub-title {
                    font-size: 14px;
                }
            }
        }

        &__info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .representative {
                border-radius: 20px;
                padding: 50px 35px;
                background-color: $color-light;
                display: flex;
                width:100%;
                justify-content: space-around;
                &__title {
                    color: $color-order-total;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 150%;
                    margin-bottom: 6px;
                }

                &__sub-title {
                    color: $color-dark;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 31px;
                }

                &__info {
                    display: flex;
                    gap: 85px;
                    align-items: center;

                    &__phone, &__email {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .content {
                            &__title {
                                color: $color-body-text-1;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 150%;
                            }

                            &__subtitle {
                                color: $color-dark;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                        }
                    }

                    @media (max-width: 1360px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    }

                    @media (max-width: 1100px) {
                        flex-direction: row;
                        gap: 85px;
                        align-items: center;
                    }

                    @media (max-width: 610px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    }
                }

                @media (max-width: 600px) {
                    padding: 15px;
                    &__title {
                        font-size: 24px;
                    }
        
                    &__sub-title {
                        font-size: 16px;
                    }
                }
            }

            .device-img {
               margin-top:50px;
               img{
                
                object-fit:contain;
               }
            }

            @media (max-width: 600px) {
                gap: 20px;
            }
        }

        @media (max-width: 1100px) {
            flex-direction: column;
            gap: 20px;
        }
    }

    @media (max-width: 600px) {
        padding-bottom: 50px;
    }
}