
@import "./subscription-summary-items";
.orders-page {
    
    margin: 32px 75px 10px;
    width: 75% !important;
    margin-bottom: 56px;
    flex:1;
    @media screen and (max-width:1440px) {
        margin: 32px 25px 10px;
    }
    @media screen and (max-width:1200px) {
      margin: 32px 7% 10px;
      width: 60% !important;
      
    }
    @media screen and (max-width:425px) {
      margin: 30px 3% 10px;
      width: 37% !important;
    }
    
    &__filter-container {
        padding: 33px 35px;
        border-radius: 20px;
        background-color: $color-light;
        margin-bottom: 16px;
        margin-top:16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .date-picker-container {
                position: relative;

                .date-picker {
                    display: flex;
                    align-items: center;
                    gap: 17px;
                    background-color: $form-textfield-bg;
                    padding:  15px;
                    height:auto;
                    border-radius: 10px;
                    cursor: pointer;

                    p {
                        color: #A0B0D0;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }

                    svg {
                        width: 14px;
                        path{
                            stroke: #A0B0D0;
                        }
                    }
                }

                .custom-datepicker {
                    position: absolute;
                    // left: -300px;
                    left: 0;
                    top: 48px;
                    z-index: 1000;

                    .rdrDefinedRangesWrapper {
                        display: none;
                    }

                    .rdrDateRangePickerWrapper {
                        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
                    }

                    @media (max-width: 1050px) {
                        width: 100%;

                        .rdrDateRangePickerWrapper {
                            width: 100%;
                            .rdrCalendarWrapper {
                                width: 100%;
                                .rdrMonths {
                                    width: 100%;
                                    .rdrMonth {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            p {
                color: $color-text-secondary;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }

            .MuiFormControl-root {
                .MuiInputBase-root, .MuiOutlinedInput-root {
                    height: 41px;
                    width: 249px;
                    border-radius: 10px;
                    background-color: #F3F5F6;
                    border: none;
                    border-radius: 10px;
                    color: #A0B0D0;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: 600;

                    .MuiInputBase-input {
                        padding: 0;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            &__time-period-input {
                width: 182px;
                margin: 10px 0px;
                svg{
                    display: none;
                }
                .MuiSelect-select {
                    background-color: $form-textfield-bg !important;
                            border-radius: 10px;
                            max-width: 100% !important;
                            padding: 13px 10px;
                            position: relative;
                            color:$color-body-text-1;
                            &:after{
                                position: absolute;
                                right:15px;
                                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none"><path d="M10.6667 1.66602L6 6.33268L1.33333 1.66602" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                            }
                            input{
                                background-color: $form-textfield-bg !important;
                                color: $form-filled-input-color !important;
                                font-size: 14px;
                                font-weight: $font-weight-medium;
                                box-shadow: none;
                                line-height: 140%;
                                border-radius: 10px;
                                border: none;
                                padding: 12px;
                                outline: none;
                                &::placeholder{
                                    color:$placeholder-color;
                                }
                                &:-webkit-autofill {
                                    -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                                    -webkit-text-fill-color: $form-filled-input-color;
                                    transition: background-color 5000s ease-in-out 0s;
                                    caret-color: $form-filled-input-color !important;
                                    &:hover {
                                        -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                                        -webkit-text-fill-color:$form-filled-input-color;
                                        transition: background-color 5000s ease-in-out 0s;
                                        caret-color: $form-filled-input-color !important;
                                    }
                                    &:focus {
                                        -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                                        -webkit-text-fill-color: $form-filled-input-color;
                                        transition: background-color 5000s ease-in-out 0s;
                                        caret-color: $form-filled-input-color !important;
                                    }
                                }
                        }
                }
                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            @media (max-width: 900px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                width: 100%;
            }
        }

        @media (max-width: 1050px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;

            .order-search-input {
                width: 100%;
            }

            .status-container {
                flex-direction: column;
                width: 100%;

              .date-picker-container {
                width: 100%;
                .date-picker {
                    width: 100%;
                    justify-content: space-between;
                }
              }
              &__time-period-input {
                width: 100%;
              }
            }
        }

        @media (max-width: 600px) {
            padding: 14px 15px;
        }
    }

    &__container {
        background-color: $color-light;
        border-radius: 30px;
        @media (max-width: 600px) {
           border-radius: 20px;
         }
        &__title-container {
            padding: 33px 35px 23px;
            color: $color-logo-header;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            @media screen and (max-width:767px) {
                font-size: 14px;
            }

            @media (max-width: 600px) {
                padding: 18px 12px;
               
            }
        }

        .border-bottom {
            border-bottom: 1px solid rgba($border-color, 0.4);
        }

        &__table-container {
            .table-head {
                background: $color-main-bg;
                padding: 15px 70px;
                font-size: 16px;
                font-weight: 500;
                color: $color-body-text-2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media screen and (max-width:1024px) {
                    font-size: 14px;
                    padding: 15px 30px;
                }
                 
                .name {
                    width: 18%;
                }

                .imei-number {
                    width: 18%;
                }

                .date {
                    width: 18%;
                }

                .status {
                    width: 18%;
                }
                .selected-plan{
                    width:18%;
                }
                .plan-option{
                    opacity: 0;
                    width:3%;
                }
            }

            .table-body {
                padding: 33px 30px 48px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                max-height: 72vh;
                overflow: auto;
                @media (max-width: 1024px) {
                    padding: 33px 10px 48px;
                } 
                @media (max-width: 850px) {
                    max-height: unset;
                    overflow: auto;
                }
                @media (max-width: 600px) {
                    padding: 20px 15px;
                    
                } 
            }
        }
    }
}
