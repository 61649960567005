 .region-form {
   display: grid;
   grid-template-columns: 1fr 1fr;
   column-gap: 30px;

   @media screen and (max-width: 786px) {
     grid-template-columns: 1fr;
   }

   .radiobox-form {
     margin: 5px 0 5px 3px;

     label {
       @include displayFlex(row, flex-start, center);
       color: $color-text-secondary;
       font-size: 12px;
       font-weight: 600;
       line-height: normal;
       margin-right: 2px;
       font-family: inherit;
       margin-bottom: 3px;

       .info-required {
        
       }
     }

     .MuiFormGroup-root {
       label {
         margin: 0;
         margin-right: 12px;
         color: $color-body-text-1;
       }

       .MuiRadio-root {
         padding-right: 2px;
         padding-left: 0;
       }
     }
   }
 }

 .country-dropdown {
   margin-bottom: 10px;
input{
  border:1px solid rgba($border-color,0.4);
  border-radius: 8px;
  ::placeholder{
    color:$placeholder-color;
    font-size: 14px;
    font-weight: 500;
  }
}
::placeholder{
  color:$placeholder-color;
  font-size: 14px;
  font-weight: 500;
}
ul{
  outline: none;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
  background-color: white;
  border-radius: 8px !important;
  border:0 !important;
}
   label {
     @include displayFlex(row, flex-start, center);
     color: $color-text-secondary;
     font-size: 12px;
     font-weight: 500;
     line-height: normal;
     font-family: inherit;
     margin-bottom: 10px;

      
   }
   .select-placeholder-text {
    color: $placeholder-color;
    font-size: 14px;
    font-weight: 500;
  }
  
   .country-select-field {
     padding-bottom: 0;

     button {
       background-color: $form-textfield-bg !important;
       color: $form-filled-input-color !important;
       font-size: 14px !important;
       font-weight: $font-weight-medium;
       box-shadow: none;
       line-height: 140%;
       border-radius: 10px;
       border: none;
       padding: 9px 12px;
       outline: none;

       &::after {
         position: absolute;
         border: 0;
         top: 12px;
         right: 28px;
         content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none"><path d="M13 1L7 6L1 1" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
       }
     }

     ul {
       li {
         span {
           font-size: 14px;
           font-weight: $font-weight-medium;
         }
       }
     }
   }
 }
 .time-zone-field{
  .form-textfield .MuiInputBase-root{
    padding-right: 12px  !important;
  }
  .MuiAutocomplete-endAdornment{
    position: relative;
    right: 0  !important;
    
    svg {
      display: none;
    }
    &:after {
      position: absolute;
      right:0px;
      cursor: pointer;
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none"><path d="M13 1L7 6L1 1" stroke="%23A0B0D0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
 }
 .react-tel-input .selected-flag .flag{
  border-radius: 2px;
 }
 .react-tel-input .selected-flag .arrow {
  position: relative;
  top: 0 !important;
  margin-top: 0px !important;
  left: 26px !important;
  width: 0;
  height: 0;
  border-left: unset !important;
  border-top: unset !important;
  border-right: unset !important;
  border: solid #A0B0D0 !important ;
  border-width: 0 1px 1px 0 !important;
  padding: 2.5px;
  transform: rotate(45deg);
 }
 .react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
  background-color: white;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
  border-radius: 8px !important;
}