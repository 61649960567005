.payment-plan-popup{
    background-size: contain;
    background-repeat: no-repeat;
    min-height:100vh;
    width: 100%;
    overflow: auto;
    background-position: center;
    position: relative;
    &::after{
      position: absolute;
      top:0;
      left:0;
      background: $onboarding-background-linear-gradient;
      content:  ' ';
      width:100%;
      height: 100%;
    }
}
.payment-popup-inner-content{
    border-radius: 8px;
    background-color: $color-light;
    @include displayFlex(column);
    padding:50px 35px 35px;
    h2{
        @include fontStyles($color-text-secondary,24px,normal);
        margin:20px 0px 10px;
    }
    .device-img{
        margin-top:15px;
    }
    .inner-text {
        color: $color-body-text-1;
        font-size: 14px;
        max-width: 383px;
        text-align: center;
        font-weight: $font-weight-medium;
        margin-bottom: 15px;
        @media screen and (max-width:390px) {
            max-width: 90%;
            font-size: 12px;
        }
        span {
            color: $color-secondary;
        }
    }
    .email-section{
        @include fontStyles($color-body-text-1,14px,normal);
        text-align: center;
        margin-bottom:20px;
    }
    .plan-section{
        @include fontStyles($color-body-text-1,14px,normal);
        margin:0px 0px 20px;
        text-align: center;
        span{
            color:$color-secondary;
        }
    }
    .back-btn{
        background-color: $color-secondary;
        color:$color-light;
        font-size: 14px;
        height: auto;
        padding:12px 25px;
        font-weight: 500;
        border-radius:8px;
        box-shadow: none;
        border:none;
    }
}