@import "./stories-slider";
@import "./story-slide";

.success-stories {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading {
        padding: 10rem;
        padding-left: 142px;
        padding-bottom: 5rem;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        @media screen and (max-width:1800px) {
            padding-left: 129px;
        }
        @media screen and (max-width:1600px) {
            padding-left: 79px;
        }

        h2 {
            font-size: 32px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 8px;
        }

        h3 {
            color: $color-secondary;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            text-transform: uppercase;
        }

        @media (min-width: 1601px) {
         padding-left: 120px;
        }

        @media (min-width: 1700px) {
            padding-left: 130px;
        }

        @media (min-width: 1800px) {
            padding-left: 135px;
        }

        @media (min-width: 1900px) {
            padding-left: 145px;
        }

        @media (max-width: 1500px) {
            padding-left: 8rem;
        }

        @media (max-width: 1300px) {
            flex-direction: column-reverse;
        }

        @media (max-width: 600px) {
            h2 {
                font-size: 24px;
            }

            h3 {
                font-size: 12px;
            }
        }
    }

    &__slider-container {
        padding: 10rem;
        padding-bottom: 5rem;
        padding-right: 0;
        padding-left: 0;
    }

    @media (max-width: 1300px) {
        flex-direction: column;

        &__heading {
            padding: 5rem;
            text-align: center;
        }

        &__slider-container {
            padding-top: 0;
            padding-inline: 0;
        }
    }
}