.navbar-list{
    padding: 0rem;
    margin:0rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.navbar-list .list-item {
    margin-right: 2.1rem;
}
.navbar-list .list-item .list-item-link {
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-weight: 500;
}
.navbar-list .list-item .list-item-link.active {
    color: #1A74E2;
    font-weight: 500 ;
}