.white-tooltip {
  background: white;
  color: $color-dark;
  font-size: 12px;
  border: 1px solid $color-border;
  padding: 12px;
  filter: drop-shadow(0px 4px 20px rgba(52, 56, 68, 0.29));
  border-radius: 12px;

  h4 {
    font-size: 14px;
    font-weight: 500;
  }
}

.white-arrow {
  color: white;
  &::before {
    border: 1px solid $color-border;
  }
}