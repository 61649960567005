.purchased-modal-content {
    width: 470px;
    background-color: white;
    border-radius: 19px;
    padding: 25px 0 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    gap: 14px;
    border: none;
    outline: none;

    @media screen and (max-width: 500px) {
        max-width: 85%;
    }

    h2 {
        max-width: 342px;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: #021021;
        @media screen and (max-width: 500px) {
            max-width: 90%;
        }

    }

    & p {
        color: $color-body-text-1;
        font-size: 14px;
        max-width: 383px;
        font-weight: $font-weight-medium;
        @media screen and (max-width:390px) {
            max-width: 90%;
        }
        span {
            color: $color-secondary;
        }
    }

    .btn {
        height: auto;
        padding: 14px 25px;
    }

    .cross-btn{
        background-color: transparent !important;
        align-self: flex-end;
        margin: 0 20px;
    }
}