@import "./about-hero";
@import "./customerExperience/customer-experience";
@import "./our-journey";
@import "./whoWeAre/who-we-are";
@import "./globalReach/global-reach";
@import "./whyPeeq/why-peeq";
.about-us-page{
    background-color: #FBFCFE;
    .get-started-container{
        background-color: rgba(#B0D5FF,0.25);
    }
}