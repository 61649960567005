.hero-feature {
    padding: 31px 40px 36px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: $color-light;
    cursor: pointer;
    height: 250px;

    &__icon {

    }

    &__title {
        color: $color-text-secondary;
        margin-top: 22px;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
    }

    &__desc {
        color: $color-body-3;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;

        span {
            display: block;
        }
    }
}