
.header-logo{
    display: flex;
    text-decoration: none;
        width: 33%;
        align-items: center;
        justify-content: center;
     svg{
        max-height: 34px;
        max-width: 101px;
         
        @media (max-width: 600px) {
            width: 55px;
            height: 18px;
        }
     }
     @media screen and (max-width:1440px) {
        width: 31%;
    }
    @media screen and (max-width:991px) {
        width: 33%;
        margin-top:10px;
    }
     
    span {
        letter-spacing: 0px;
        color: $color-logo-header;
        opacity: 1;
        font-weight: 600;
        font-size: 2.2rem;
    }
}
