.form-autocomplete{
    width:100%;
    margin-bottom: 15px;
    .MuiInputBase-root {
        background-color: $form-textfield-bg;
        border-radius: 10px;
    }
    .MuiFormLabel-root.MuiInputLabel-root{
        color: $color-text-secondary;
        font-size: 16px;
        font-weight: $font-weight-medium;
        line-height: normal;
        margin-bottom: 5px;
    }
    input{
        background-color: $form-textfield-bg !important;
        color: $form-filled-input-color !important;
        font-size: 14px;
        font-weight: $font-weight-medium;
        box-shadow: none;
        line-height: 140%;
        border-radius: 10px;
        border: none;
        padding: 12px;
        outline: none;
        &::placeholder{
            color:$placeholder-color;
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
            -webkit-text-fill-color: $form-filled-input-color;
            transition: background-color 5000s ease-in-out 0s;
            caret-color: $form-filled-input-color !important;
            &:hover {
                -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                -webkit-text-fill-color:$form-filled-input-color;
                transition: background-color 5000s ease-in-out 0s;
                caret-color: $form-filled-input-color !important;
            }
            &:focus {
                -webkit-box-shadow: 0 0 0px 1000px $form-textfield-bg inset;
                -webkit-text-fill-color: $form-filled-input-color;
                transition: background-color 5000s ease-in-out 0s;
                caret-color: $form-filled-input-color !important;
            }
        }
}
        .MuiOutlinedInput-notchedOutline{
        position: relative;
        border: none;
        display: none;
        }
        .MuiInputLabel-shrink{
        color: white;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        padding: 0;
        position: relative;
        display: block;
        transform-origin: top left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        position: relative;
        left: -14px;
        top: 11px;
        font-family: inherit;
        }
}