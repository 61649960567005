.inner-device-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.edit-btn {
		box-shadow: none  !important;
		border-radius: 16px !important;
		color: $color-dark;
		text-transform: capitalize;
		font-weight: 400;
		min-width: auto !important;
		margin-left: 25px;
		padding: 0 !important;
		margin-top: 2px;
		transition: none   !important;
		background-color: transparent   !important;
		img {
			margin-right: 5px;
		}
	}
	.deviceName {
		color: $color-dark;
		font-size: 17px;
		position: relative;
	}
	.my-device-name {
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
		max-width: 88px;
        color: $color-dark;
	}
	.device-name-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		.device-img {
			width: 36px;
			height: 36px;
			margin-right: 5px;
			margin-top: 2px;
			margin-left: 5px;
		}
	}
	.status {
		position: absolute;
		top: 8px;
		right: -15px;
	}
}
