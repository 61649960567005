@import "./pencil-banner";
@import "./logo";
@import "./logged-in-user/loggedIn-user";
@import "./cart/user-cart";
@import "./drawer-menu";

.header-user {
   
    padding:25px 0;
    
   
&.image-header{
 background-color: #ffffff;

}
&.dashboard-header{
    background-color: #ffffff;
    padding:10px 0;
}
    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .hamburger-icon {
            display: none;
            height: auto;
            padding:0;
            min-width: unset;
            background: none;
            border:none;
            box-shadow: none;
            outline: none;
            @media (max-width: 991px) {
              
                width: 33%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
            }
            @media (max-width: 600px) {
                width: 33%;
            }
        }

        svg {
            cursor: pointer;
        }

        &__nav {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 30px;
            width: 33%;
            justify-content: flex-start;
@media screen and (max-width:1440px) {
    width: auto;
    flex:1;
    gap: 20px;
}
            .nav-item {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 150%;
                color: #000000;
                white-space: nowrap;
                border-bottom: 1px solid transparent;
&:hover{
   
   
    border-bottom: 1px solid $color-secondary;
}
            }

            .active {
                color: #000000;
                font-weight: 600;
                border-bottom: 1px solid $color-secondary;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

        &__icons {
            display: flex;
            align-items: center;
            gap: 21px;
            width: 33%;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width:585px) {
                gap: 14px;
            }

            &--button {
                border: none;
                border-radius: 0;
                cursor: pointer;
                background: none;
                box-shadow: none;
                transition: none;
                min-width: auto;
                box-shadow: none;
                padding: 0;
                height: auto;
                transition: none;
            }
        }
    }
}