.MuiContainer-root {
   
    padding: 0;
max-width: 85%;
    @media screen and (max-width:1600px) {
        max-width: 90%;
    }
    @media screen and (min-width:2400px) {
        max-width:1920px;
    }
}

.contact-us-page {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0;
    position: relative;
  
        background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/not-found-bg.webp");
    .header-user {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 35px 0;
        z-index: 2;
    }

    background-position: top;

    

    @media screen and (max-width:1600px) {
        min-height: auto;
        margin: 0;
    }

    @include displayFlex();

    .inner-content {
        position: relative;
        width: 100%;
        padding: 120px 0px;
        min-height: 100vh;
        z-index: 1;
        border: 0;
        @include displayFlex(row, space-between, center);

        @media screen and (max-width:1600px) {
            min-height: auto;
        }
        @media screen and (max-width:1200px) {
           padding-bottom: 60px;
        }

        .container-inner-content {
            @include displayFlex(row, space-between, center);
            position: relative;
            z-index: 2;

            .img-section {

                margin-bottom: 30px;

                @media screen and (max-width:1600px) {
                    width: 500px;
                }

                @media screen and (max-width:1200px) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                @media screen and (max-width:600px) {
                    display: none;
                }
            }

            .contact-us-footer {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-bottom: 30px;
                max-width: 626px;
                gap:15px;
                @media screen and (max-width:1200px) {
                    margin-top: 60px;
                    gap:8px;
                }
                p {
                    margin: 0;
                    margin-left: 8px;
                    max-width: 451px;
                    @include fontStyles($color-text-secondary, 16px, 150%);
                    @media screen and (max-width:1200px) {
                        color:#ffffff;
                    }
                    span,
                    a {
                        color: $color-secondary;
                        text-decoration: none;
                    }

                }
            }

            .contact-us-form {

                margin-bottom: 30px;
                padding: 50px 45px;
                border-radius: 12px;
                background: $color-light;
                max-width: 600px;
                box-shadow: 0px 23.94778px 82.8194px 0px rgba(0, 0, 0, 0.10), 0px 4.98912px 17.96083px 0px rgba(0, 0, 0, 0.06), 0px 1.99565px 5.98694px 0px rgba(0, 0, 0, 0.04);

                @media screen and (max-width:1600px) {
                    max-width: 500px;
                }

                @media screen and (max-width:1200px) {
                    max-width: 100%;

                }

                @media screen and (max-width:767px) {
                    padding: 20px;
                }

                .title {
                    font-size: 28px;
                    font-weight: $font-weight-medium;
                    line-height: 150%;
                    margin-bottom: 10px;
                    color: $color-dark;
                }

                .sub-title {
                    font-size: 16px;
                    font-weight: $font-weight-medium;
                    line-height: 150%;
                    color: $color-body-text-2;
                    margin-bottom: 35px;
                }

                .phone-number-form {
                    margin-top: 5px;

                    @media screen and (max-width:901px) {
                        margin-top: 0;
                    }
                }

                .MuiGrid-root {
                    border: none;
                    padding-top: 0;

                    .MuiGrid-item {
                        padding-top: 0 !important;
                    }
                }

                .subscribe-text {
                    color: $color-body-text-2;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: $font-weight-medium;
                    line-height: 140%;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #AAB6BC;
                }
                .spinner-circle{
                    width: 202.844px;
                    @media screen and (max-width:600px) {
                        width: 95%;
                    }
                }
                .contact-us-form-footer {
                    @include displayFlex(row, flex-end, flex-end);
                    margin-top: 15px;
                    .send-btn {
                        padding: 12px 40px !important;
                        color: $color-footer-link;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: $font-weight-medium;
                        line-height: 150%;
                        text-transform: initial;
                        min-height: auto !important;
                        background-color: $color-secondary;
                       margin: 0;
                        height: auto !important;
                        background: #1A74E2;
                        border-radius: 8px;

                    }

                }
            }
        }

    }
}
.contact-us-textarea{
   min-height: 138px;
    overflow: auto;
}
.character-count{
   
font-weight: 500;
font-size: 14px;
line-height: 22px;
text-align: right;
color: #A0B0D0;


}