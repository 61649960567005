
.footer-logo{
    display: flex;
    text-decoration: none;
    margin-bottom: 2.6rem;

    img {
        margin-right: 0.5rem;
    }

    span {
        letter-spacing: 0px;
        color: $color-logo-footer;
        opacity: 1;
        font-weight: 500;
        font-size: 2.2rem;
    }
}
