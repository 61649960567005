.help-breadcrumbs {
    font-weight: 500;
    line-height: 150%;
    color: $color-body-text-2;

    .link {
        color: $color-text-secondary;

        &:hover {
            text-decoration: underline;
        }
    }

    .current-page {
        color: $color-body-text-2;
    }
}