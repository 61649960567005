.hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 60px 0;
    color: white;
    background-position: center;
    background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/about-us-hero.webp");
    .header-user {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-image: none;
        padding: 25px 0;
        @media screen and (max-width:600px) {
            padding:20px 0;
        }
    }

    .hero-section-inner {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 0 0;

        h2 {

            font-weight: 800;
            font-size: 82px;
            line-height: 120px;
            text-align: center;
            letter-spacing: 0.25em;
            text-transform: uppercase;
            color: #3D3D3D;
            @media screen and (max-width:1240px) {
                font-size: 61px;
            }
            @media screen and (max-width:991px) {
                font-size: 43.82px;
                line-height: 70px;
            }
            @media screen and (max-width:767px) {
                font-size: 38px;
                
            }
            @media screen and (max-width:600px) {
                font-size: 23px;
                line-height: 35px;
                max-width: 341px;
            }
        }

        .subtitle {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #3D3D3D;
            z-index: 1;
            margin-top: 30px;
            @media screen and (max-width:991px) {
                font-size: 18px;
                line-height: 30px;
                max-width: 547px;
                margin: 0;
            }
            @media screen and (max-width:600px) {
                font-size: 16px;
                line-height: 24px;
                max-width: 341px;
            }
        }

        .hero-section-detail {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 170px 0 20px;
            z-index: 1;
            width: 100%;

            @media screen and (max-width:991px) {
                margin: 100px 0 20px;
            }
            img {
                height: 409px;
                width: 467px;

                @media screen and (max-width:600px) {
                    width: 358px;
                    height: 308px;
                }
            }
        }

    }
}

.our-journery-section {
    padding: 70px 0 30px;
    @media screen and (max-width:600px) {
        padding:60px 0 20px;
    }
    .our-journery {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .header {
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            color: #131313;
            margin-bottom: 20px;
            @media screen and (max-width:600px) {
                font-size: 22px;
                line-height: 34px;
            }
            span {
                color: #1A74E2;
                @media screen and (max-width:465px) {
                    display: block;
                }
            }
        }

        .detail {
            font-weight: 400;
            font-size: 22px;
            line-height: 34px;
            color: #131313;
            margin-bottom: 40px;
@media screen and (max-width:600px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 35px;
}


        }


    }
}

.journery-inner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:1369px) {
        flex-direction: column;
    }

    .text-section {
        max-width: 40%;

        @media screen and (max-width:1369px) {
            max-width: 100%;
        }

        p {
            font-weight: 400;
            font-size: 22px;
            line-height: 34px;
            color: #131313;


            margin-bottom: 40px;
        }
    }

    .img-section {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        @media screen and (max-width:1369px) {
            width: 100%;
        }

        .about-peeq-section {
            display: flex;
            flex-direction: row;

        }

        p {
            
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 54px;
            text-align: center;
            color: #131313;
        }

        span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #131313;
            max-width: 192px;
        }

        .about-section {
            position: relative;
            top: -112px;
            left: 75px;
        }

         

         
    }

}