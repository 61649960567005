.total-table-container {
    padding: 18px 0 20px;
    display: flex;
    justify-content: flex-end;
    // width: 77%;
    width: 78%;

    .total-table {
        // width: 246px;
        // width: 196px;
        width: 207px;
        display: flex;
        flex-direction: column;
        gap: 18px;

        .total-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;

            .left {
                color: $color-body-text-1;
                display: flex;
                align-items: center;
                flex-direction: row;
                gap:4px;
            }

            .right {
                color: $color-dark;
            }

            .green {
                color: $color-discounts;
            }
        }

        .grand-total {
            padding-top: 20px;
            margin-top: 12px;
            border-top: 1px solid $color-order-border;

            .left {
                color: $color-dark;
                font-size: 14px;
                font-weight: 500;
            }

            .right {
                color: $color-dark;
                font-size: 15px;
                font-weight: 600;
            }
        }

        @media (max-width: 1800px) {
            width: 250px;
        }

        @media (max-width: 1600px) {
            width: 250px;
        }
    }

    @media (max-width: 1800px) {
        // width: 77.3%;
    }

    @media (max-width: 1600px) {
        // width: 77.5%;
    }

    @media (max-width: 1400px) {
        // width: 78%;
    }

    @media (max-width: 1000px) {
        // width: 79%;
    }
}