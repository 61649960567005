.our-journey {
    background-color: $color-our-journey-bg;
    display: flex;
    justify-content: space-between;

    &__content {
        padding: 8.3rem 4rem;
        flex: 1;

        h2 {
            color: $color-secondary;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: uppercase;
        }

        h3 {
            color: $color-text-secondary;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            margin-top: 8px;
            margin-bottom: 16px;
        }

        p {
            color: $color-dark;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .second-para {
            margin-top: 2.4rem;
        }

        .blue-para {
            color: $color-secondary;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 2.4rem;
        }

        @media (min-width: 1601px) {
            padding: 11.2rem 7rem;
        }

        @media (min-width: 1901px) {
            padding: 11.2rem 7.5rem;
        }


        @media (max-width: 1300px) {
            padding: 8.3rem 3.5rem;
        }

        @media (max-width: 1100px) {
            padding: 8.3rem 3rem;
        }

        @media (max-width: 1024px) {
            padding-inline: 0;
            padding-bottom: 33px;
            padding-top: 42px;
        }

        @media (max-width: 600px) {
            h2, p {
                font-size: 14px;
            }
            p {
                line-height: 150%;
            }
            h3 {
                font-size: 24px;
                font-weight: 500;
            }
        }
    }

    &__image {
        flex: 1;
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
    }
}