.tablelike {
  --cols: 5;
  --gutter-body: 63px;
  --padding-row-x: 33px;
  --padding-row-y: 23px;
  --col-width: 1fr;
  --col-template: repeat(var(--cols), var(--col-width));

  @media screen and (max-width: 786px) {
    --padding-row-x: 16px;
    --gutter-body: 20px;
    --padding-row-y: 16px;
  }

  font-size: 12px;

  &__header {
    background: $color-order-table-header;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: white;

    .tablelike__header-mobile {
      margin-left: var(--gutter-body);
      margin-right: var(--gutter-body);
    }

    &>* {
      padding-top: 16px;
      padding-bottom: 16px;

      @media screen and (max-width: 786px) {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    .tablelike__row {
      margin-left: var(--gutter-body);
      margin-right: var(--gutter-body);
      padding: 15px;
      @media screen and (max-width: 786px) {
        display: none;
      }
    }
  }
.event-table{
  display: flex;
  flex-direction: column;
  width: 100%;
  .event-table-row{
    display: grid;
    grid-template-columns: var(--col-template);
    
    gap: 10px;
    @media screen and (max-width: 786px) {
      grid-template-columns: unset;
      grid-template-rows: unset;
      .tablelike__header-mobile{
        color: #8899b4;
      }
    }
  &>* {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    
  }
  p{
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    @media screen and (max-width: 786px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
}
  }
 button{
  @media screen and (max-width: 786px) {
    display: flex;
    padding: 0;
    justify-content: flex-start;
  }
  
 }
  .selected-map{
    width: 100%  !important;
    height:120px;
    border-radius: 8px;
    display: flex;
    @media screen and (max-width: 786px) {
      height: 95px;
    }
    .maplibregl-canvas{
      width:100%  !important;
    }
  }
}

  .tablelike__header-mobile {
    display: none;
    font-size: 14px;

    @media screen and (max-width: 786px) {
      display: block;
      font-weight: 500;
    }
  }
.tablelike-mobile{
  @media screen and (max-width: 786px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .tablelike__header-mobile{
      color: #8899b4;
    }
  }
}
  &__body {
    padding-left: var(--gutter-body);
    padding-right: var(--gutter-body);
    margin-top: 15px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    .tablelike__row {
      border-radius: 17px;
      border: 1px solid $color-body-text-3;
      padding: 20px 15px;
      @media screen and (max-width: 786px) {
        &>* {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;

          &>* {
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
           
          }
           
        }
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: var(--col-template);
    padding: var(--padding-row-y) var(--padding-row-x);
    gap: 10px;

    &>* {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;
      
    }
    .type-tag{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      padding: 7px 13px 7px 10px;
      border-radius: 18px;
      span{
        margin-left: 6px;
      }
    }
      .command-result{
      background-color: #F7EAEA;
      span{
        color:#E0423B;
      }
      }
      .alarm{
        background-color: #F8F4DC;
        span{
          color:#E66D13;
        }
      }
    @media screen and (max-width: 786px) {
      grid-template-columns: unset;
      grid-template-rows: unset;
    }
  }
}