.addresses-page {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 32px auto;
}

.address-card {
  border: 1px solid rgba($color-border, 0.4);
  padding: 16px 26px;
  border-radius: 21px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  &__details {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h5 {
      font-size: 16px;
      color: $color-dark;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      /* 21px */
      color: #6A6A6A;
      max-width: 275px;
    }

    .switch-label {
      color: $color-body-text-1;
      margin-top: 11px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  &__actions {
    display: flex;
  }
}

.addresses-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 786px) {
    grid-template-columns: 1fr;
  }

  gap: 28px;
}