@import "./custom_select";

.privacy-policy, .terms-and-conditions, .warranty-policy, .shipping-policy, .return-policy, .faqs, .support {
    padding: 40px 0;
    background: #e8edf2;
   .date-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span{
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #8899B4;
    }
    
    p{
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #172434;
        @media screen and (max-width:600px) {
            font-size: 18px;
        }
    }
   }
    &__heading {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40px;

        h1 {
            color: $color-text-secondary;
            font-size: 32px;
            margin-bottom: 20px;
            font-weight: 500;
            line-height: normal;
        }
        .link{
            color: $color-secondary;
            text-decoration: underline;
        }
        p {
            color: $color-dark;
            font-size: 16px;
            line-height: normal;
        }

        .desc {
            color: $color-dark;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        @media (max-width: 700px) {
            h1 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
                
            }

            .desc {
                font-size: 14px;
            }
        }
    }

    &__content {
        background-color: $color-light;
        padding: 25px;
        border-radius: 8px;
        color: $color-dark;

        &--instruction {
            font-size: 16px;
            margin-bottom: 32px;
        }

        .heading {
            color: $color-order-total;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 10px;

            @media (max-width: 700px) {
                font-size: 17px;
            }
            @media (max-width: 585px) {
                font-size: 16px;
            }
        }
        .sub-heading {
            color: $color-order-total;
            font-size: 19px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 8px;
            

            @media (max-width: 700px) {
                font-size: 14px;
            }
             
        }
        .description {
            color: $color-order-total;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 30px;

            
            @media (max-width: 585px) {
                font-size: 14px;
            }
            .link{
                font-size: 16px;
                 
                @media (max-width: 585px) {
                    font-size: 14px;
                }
            }
        }

        .mb-0 {
            margin-bottom: 0 !important;
        }

        .mb-30 {
            margin-bottom: 30px !important;
        }

        .point {
            // color: $color-secondary;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media (max-width: 700px) {
                font-size: 14px;
            }
        }

        .number {
            @media (min-width: 1900px) {
                display: block;
            }
        }

        .bold {
            font-weight: 600;
            font-size: 16px;
            color: $color-text-secondary;

            @media (max-width: 700px) {
                font-size: 14px;
            }
        }

        .list {
            margin-bottom: 20px;

            .list-item {
                color: $color-order-total;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                @media (max-width: 700px) {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 600px) {
            padding-inline: 16px;
            padding-block: 20px;
        }
    }

    &__flex {
        display: flex;
        gap: 60px;
        margin-top: 70px;
        padding-bottom: 25px;

        &__left {
            max-width: 60%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: #ffffff;
            border-radius: 8px;
            padding:40px 63px 40px 30px;
            @media screen and (max-width:1440px) {
                padding: 40px 30px 40px 30px;
            }
            @media screen and (max-width:1200px) {
                max-width: 100%;
            }
            
            svg{
                min-width: 50px;
                margin-top: 8px;
            }

            &__content {
                display: flex;
                gap: 32px;
                @media screen and (max-width:600px) {
                   flex-direction: column;
                   gap:20px;
                }
                .title {
                    color: $color-order-total;
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    line-height: 36px;
                }

                .sub__title {
                    color: $color-dark;
                    font-size: 16px;
                    font-weight: 500;

                    span {
                        color: $color-secondary;
                    }
                    .link{
                        font-size: 16px;;
                    }
                }

                @media (max-width: 600px) {
                    .title {
                        font-size: 16px;
                    }

                    .sub__title {
                        font-size: 14px;
                    }
                }
            }
        }

        &__right {
            flex: 1;
            border-radius: 8px;
            background-color: $color-light;
            padding: 32px 44px;
            .request-form {
                .form-textarea{
                    margin-top: 15px;
                }
                .custom_select{
                    @media screen and (max-width:1200px) {
                        margin-bottom: 20px;
                    }
                }
                .title {
                    color: $color-dark;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
    
                .sub-title {
                    color: $color-body-text-2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    margin-bottom: 40px;
                }

                .form__submit-btn {
                    background-color: $color-secondary;
                    display: flex;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;
                    color: $color-light;
                    font-size: 14px;
                    border-radius: 8px;
                    margin-left: auto;
                }
            }
        }

        @media (max-width: 1400px) {
            gap: 40px;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            gap: 30px;
            margin-top: 30px;
        }
    }
&__need-help{
    margin:25px 0 80px;
}
    &__footer {
        padding: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: $color-light;
        border-radius: 8px;

        &__img {
            margin-bottom: 26px;
            min-width: 72px;
        }

        &__title {
            color: $color-logo-header;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 5px;
        }

        &__subtitle {
            color: $color-secondary;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 17px;
            @media screen and (max-width:1440px) {
                font-size: 14px;
            }
        }

        &__desc {
            color: $color-dark;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 24px;
        }

        &__icons-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 45px;
            flex-wrap: wrap;
            @media screen and (max-width:600px) {
               flex-direction: column;
               gap:30px;
            }
            .item {
                display: flex;
                align-items: center;
                gap: 12px;

                p {
                    color: $color-dark;
                    font-size: 16px;
                    font-weight: 500;

                    @media (max-width: 600px) {
                        font-size: 14px;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            padding-inline: 16px;
            padding-block: 20px;

            &__title { 
                font-size: 24px;
            }

            &__subtitle {
                font-size: 16px;
             }

            &__desc {
                font-size: 16px;
             }
        }
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mt-25 {
        margin-top: 25px !important;
    }
    @media screen and (max-width:600px) {
        padding:20px 0;
   }
   
}
.get-started-box{
    margin-top:100px;
}
.shipping-policy{
    padding:50px 0;
    .help-faq-section__content{
    background: #ffffff;
    padding:15px 40px 30px;
    border-radius: 8px;
    @media screen and (max-width:600px) {
         padding:15px 25px 30px;
    }
    }
}
.contact-us-form-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    button{
        height: auto;
        min-width: unset;
        padding:12px 40px !important; 
        background: #1A74E2;
        border-radius: 8px !important;
        margin: 0 auto;
        @media screen and (max-width:600px) {
            width:100%;
       }
    }
   }
   .request-form{
    .custom_select{
        .arrow{
            top:36px;
            right: 15px;
        }
    }
   }
 