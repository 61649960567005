.address-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 13px;

    .address-column {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: $color-text-secondary;
        padding-right: 38px;

        .title {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 20px;
        }

        .customer-name {
            margin-bottom: 8px;
            text-transform: capitalize;
        }

        .customer-phone {
            font-weight: 500;
            color: $color-body-text-2;
            margin-bottom: 3px;
        }

        .customer-address {
            font-weight: 500;
            color: $color-body-text-2;
            margin-bottom: 3px;
        }
    }

    .border-right {
        border-right: 1px solid $color-order-border;
    }

    .pl-38 {
        padding-left: 38px;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        .pl-38 {
            padding-left: 0;
        }

        .border-right {
            border: none;
        }
    }
}