.order-item-mobile {
    border-radius: 16px;
    border: 1px solid $color-body-text-3;
    overflow: hidden;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 15px;

        &--item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;

            .title {
                color: $color-body-text-1;
                display: flex;
                align-items: center;
                gap: 8px;
            }
            .invoice-btn{
                height: auto;
                padding: 0;
                min-width: unset;
            }
            .value {
                color: $color-text-secondary;
                text-align: left;
                
                .status {
                    padding: 8px;
                    border-radius: 17.5px;
                    text-align: center;
                    text-transform: capitalize;
                    font-weight: $font-weight-medium;
                }

                .pending {
                    background-color: $color-order-status-pending-bg;
                    color: $color-order-status-pending;
                }
        
                .not-paid,.expired,.payment.failed {
                    background-color: $color-order-status-not-paid-bg;
                    color: $color-order-status-not-paid;
                }
        
                .deliverd {
                    background-color: $color-order-status-deliverd-bg;
                    color: $color-order-status-deliverd;
                }
                .Payment.Received, .payment.received{
                    background-color: $color-order-status-deliverd-bg;
                    color: $color-order-status-deliverd;
                }
            }
        }
    }

    .divider {
        background-color: $color-order-border;
        height: 1px;
    }

    .order-open {
        transform: rotate(180deg);
    }

    &__body {
        padding: 20px 15px;

        &--head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $color-body-text-1;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            padding-bottom: 25px;
            border-bottom: 1px solid $color-order-border;

            .item {
                width: 50%;
            }
        }

        &--content {
            padding-top: 18px;

            .item-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item-name {
                    width: 50%;
                    .name {
                        font-size: 12px;
                        font-weight: 500;
                        color: $color-text-secondary;
                    }

                    .color {
                        font-size: 12px;
                        font-weight: 500;
                        color: $color-body-text-1;
                    }
                }

                .item-qty {
                    color: $color-order-total;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 120%;
                }

                .item-total {
                    .total {
                        color: $color-order-total;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }

                    .per-item {
                        color: $color-body-text-1;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }

            .subscription__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 10px;
                background-color: $color-main-bg;
                border-radius: 10px;
                margin: 20px 0;

                &__info {
                    width: 50%;
                    .name {
                        color: $color-text-secondary;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .price {
                        color: $color-secondary;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: normal;

                        span {
                            color: $color-body-text-1;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                &__qty, &__total {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                }

                &__qty {
                    line-height: 120%;
                    color: $color-order-total;
                }

                &__total {
                    line-height: 150%; 
                    color: $color-dark;
                }
            }

            .total__table {
                display: flex;
                flex-direction: column;
                gap: 14px;
                margin-top: 20px;

                &__row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;

                    .item {
                        color: $color-body-text-1;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                    }

                    .value {
                        color: $color-dark;
                        text-align: left;
                    }

                    .green {
                        color: $color-discounts !important;
                    }

                    .total__text, .total__value {
                        font-weight: 500;
                        line-height: 120%;
                        color: $color-text-secondary;
                    } 

                    .total__text {
                        font-size: 14px;

                    }

                    .total__value {
                        font-size: 15px;
                    }
                }
            }

            .address-table {
                &__head {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.p-16-border {
    padding: 16px 0 !important;
    border-top: 1px solid $color-order-border;
    border-bottom: 1px solid $color-order-border;
    margin: 10px 0 20px 0;
}

.w-100 {
    width: 100px;

    @media (max-width: 400px) {
        width: 85px;
    }
}