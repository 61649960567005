.subscription-container {
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 12px 35px;
    // width: 80%;
    font-weight: 500;

    display: flex;
    align-items: center;

    .subscription-plan {
        width: 37%;

        &__name {
            color: $color-logo-header;
            font-size: 12px;
            line-height: normal;
        }

        &__desc {
            color: $color-grey;
            font-size: 10px;
            line-height: 150%;
        }
    }

    .subscription-duration-wrapper {
        width: 14.5%;

        .subscription-duration {
            color: $color-light;
            padding: 10px;
            border-radius: 114px;
            background-color: $color-body-text-1;
            font-size: 12px;
            width: 77px;
            text-align: center;
        }
    }


    .subscrition-quantity, .subscrition-price, .subscrition-total {
        color: $color-order-total;
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
    }

    .subscription-quantity {
        width: 12.5%;
    }

    .subscrition-price {
        width: 11.5%;
    }

    .subscrition-total {

    }
}