.MuiFormHelperText-root.Mui-error{
    color: #F13737 !important;
      font-size: 12px;
      margin: 2px 0 0  5px ;
      line-height: 125%;
      font-family: inherit;
      font-weight: 500;
  }
info-required{
    color: #F13737 !important;
}
.onboarding-banner{
    background-size: cover;
    background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/not-found-bg.webp");
    background-repeat: no-repeat;
    min-height:100vh;
    width: 100%;
    overflow: auto;
    background-position: top;
    position: relative;
    background-position: bottom;
    @include displayFlex();
    .form-section{
        width: 100%;
        padding:70px 0px;
        min-height:100vh;
        @include displayFlex();
        position: relative;
       
        .form-card{
            background-color: $color-light;
            border-radius: 12px;
            z-index:1;
            width: 480px;
            padding:45px 40px;
            &.resgiter-page-form-card{
                width:512px;
                padding: 45px 30px;
                @media screen and (max-width:585px) {
                    width: 93%;
                    padding:40px 20px;
                }
                .MuiGrid-item{
                    padding-top:0;
                }
            }
            @media screen and (max-width:585px) {
                width: 93%;
                padding:40px 20px;
            }
            @include displayFlex(column);
            .logo-section{
                margin-bottom: 20px;
                text-decoration: none;
                span{
                    color: $color-text-secondary;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: $font-weight-medium;
                    line-height: normal;
                }
            }
            .lock-icon{
                margin:20px 0px;
            }
            .title{
                color: $color-text-secondary;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 150%;  
                margin-bottom: 5px;
            }
            .detail{
                color: $color-body-text-2;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 150%; 
                margin-bottom: 30px;
            }
            .remember-me-checkbox{
                margin-left:0 !important;
                .MuiTypography-root.MuiFormControlLabel-label{
                    color: $color-body-text-2;
                    font-weight: $font-weight-medium;
                    font-size: 14px;
                    margin-left: 8px;
                }
            }
            .or{
                width:100%;
                text-align: center;
                color: $placeholder-color;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-regular;
                line-height: normal;
                margin: 5px 0px;
            }
            .register-div{
                color: $form-filled-input-color;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: normal;
                width:100%;
                @include displayFlex();
            }
        }
    }
}
.resend-verification-code{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -8px;
     
    button{
        border:none;
        background: none;
        color:$color-secondary;
        padding: 0  ;
        margin:0;
        line-height: 125%;
        outline:0;
        font-size: 12px;
        box-shadow: none;
        min-height: auto;
        height: auto;
        min-width: unset;
        margin-top: -1px;
    }
}