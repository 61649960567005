.device-sidebar{
    position: absolute;
    top:20px;
    left:35px;
    min-width:380px;
    max-width:382px;
     height: 84vh;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    background: $color-light;
    box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.20);
    border-radius: 20px;
    padding: 15px 0px;
    @media screen and (min-width:2400px) {
        height: 60vh;
        min-height: 25vh;
    }
    @media screen and (max-width:600px) {
        bottom: 0;
        top: unset;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        left: 0;
        border-radius: 20px 20px 0 0;
        height: 65vh;
        max-height: unset;
        min-height: unset;
        overflow: auto;
    }
    .no-tracker-found{
        height: 20vh;
        svg{
            width: 65px;
            height: 65px;
        }
        p{
            font-size: 16px;
            margin: 8px 0px 5px 0;
            line-height: 24px;
        }
        span{
            font-size: 14px;
        }
        .shop-link{
            display: none;
        }
    }
    .device-siderbar-inner{
        background: transparent;
        box-shadow: none;
        border-radius: 0px;
        // height: 100%;

        .devices-header{

            >div{
                display: flex;
                align-items: center;
            }
            .device-back-btn{
                height: auto;
                width: unset;
                min-width: unset;
                padding:0;
                margin-right: 8px;
                box-shadow: none;
            }
            padding: 0 20px;
            @include displayFlex($justifyContent:space-between);
            position: relative;
            @media screen and (max-width:600px) {
                justify-content: center;
                flex-direction: column-reverse;
                &.geofence-header{
                    flex-direction: row;
                    width:100%;
                    justify-content: space-between;
                    margin-top: 10px;
                    .active-icon{
                        @media screen and (max-width:600px) {
                            top: -5px;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            &:after{
                                right: 45%;
                            }
                        }
                    }
                }
            }
            .title{
                @include fontStyles($color-text-secondary,18px,nomral);
            }
            .active-icon{
                padding:0;
                background-color: $color-tab-background;
                border-radius: 0px 8px 8px 0px;
                min-width: unset;
                height: 46px;
                width: 24px;
                box-shadow: rgba(0, 0, 0, 0.12);
                transition: none;
                position: absolute;
                right: -23px;
                top: 8px;
                @media screen and (max-width:600px) {
                    width: auto;
                    height: auto;
                    position: relative;
                    padding-bottom:12px;
                    top:unset;
                    background-color: transparent;
                    box-shadow: none;
                    svg{
                        display: none;
                    }
                    &::after{
                        position: absolute;
                        right: 0;
                        content: " ";
                        width:53px;
                        height: 4px;
                        background-color: rgba(189, 201, 226, 1);
                    }
                }
            }
        }
        .device-search-header{
            padding: 20px;
            @media screen and (max-width:600px) {
                padding: 10px 15px;
                @include displayFlex($justifyContent:flex-start);
            }
            @include displayFlex($justifyContent:space-between);
            .search-input{
                border: 1px solid $color-body-text-3;
                background-color: $color-light;
                border-radius: 30px;
                flex:1;
                padding:10px 10px 10px 3px;
                position:relative;
                .MuiInputBase-root{
                    width:100%;
                }
                @media screen and (max-width:600px) {
                   width:82%;
                   flex:1;
                }
                @media screen and (max-width:550px) {
                    width:78%;
                }
                @media screen and (max-width:470px) {
                    width:65%;
                }
                @include displayFlex($justifyContent:space-between);
                input{
                    width:122px;
                    @include fontStyles($fontSize:14px,$lineHeight:normal);
                    padding-left: 6px;
                    padding-bottom: 0;
                    flex:1;
                    @media screen and (max-width:360px) {
                        width:85px;
                    }
                    ::placeholder{
                        color: $color-body-text-3;
                    }
                    
                }
                ::placeholder{
                    color: $color-body-text-3;
                    opacity: 1;
                    font-size: 14px;
                }
               

            }
        }
        .non-active-trackers{
            margin:10px 15px 5px;
            .MuiFormControlLabel-root{
                margin-left: 0;
            }
            .MuiFormControlLabel-label{
                font-size: 14px;
            }
            .non-active-tracker-count{
                background-color: #FFF3E4;
                display: flex;
                align-items: center;
                margin:5px 0px;
                padding:15px 8px;
                border-radius: 14px;
                flex-direction: row;
                flex-wrap: wrap;
                .btn{
                    padding:0;
                    height:auto;
                    min-width: unset;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16.8px;
                    box-shadow: none;
                    border:none;
                    outline: none;
                }
                svg{
                    margin-right: 8px;
                    width:32px;
                    height:29px;
                }
                p{
                    color:#FF8F00;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16.8px;
                    flex:1;
                    span{
                        font-weight: 600;
                    }
                }
            }
        }
        
        .non-activefilter{
            width: 40px;
            border: 1px solid #BDC9E2 !important;
            height: 40px;
            padding: 0.55rem !Important;
            min-width: unset;
            margin-left: 0.5rem;
            border-radius: 21px !important;
            background-color: #ffffff;
            &:hover{
                background:$color-secondary;
                border: 1px solid $color-secondary !important;
            svg{
                path{
                    fill: $color-light;
                }
            }
            }
        }
        .active-filter{
            padding: 0.55rem !Important;
            width: 40px;
            min-width: unset;
            height: 40px;
            background:$color-secondary;
            border-radius:21px;
            margin-left: 0.5rem;
            border: 1px solid $color-secondary !important;
            svg{
                path{
                    fill: $color-light;
                }
            }
        }
        .filters{
            background-color: $address-form;
            padding:10px 20px;
            @include displayFlex($justifyContent:space-between);
            .filter-header{
                @include displayFlex($justifyContent:space-between);
                width:100%;
                .section-name{
                    @include fontStyles($color-body-text-1, 12px,normal);
                    margin-right: 5px;
                }
                .sort-section{
                    display: flex;
                    align-items: center;
                    .sorting-filter-btn{
                        padding: 0;
                        min-width: unset;
                        height: auto;
                        box-shadow: none;
                        background: none;
                        transition: none;
                        color:transparent;
                    }
                }
                .device-type-section{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    .MuiFormControl-root:has(.MuiInputBase-root > .MuiSelect-select){
                        margin-top:0;
                    } 
                    .MuiInputBase-root:has(> .MuiSelect-select) {
                        background-color: transparent;
                        border-radius: 0;
                        // min-width:80px;
                        
                        svg{
                            display: none;
                        }
                        .MuiInputBase-input{
                            padding:0 18px 0 0;
                            @include fontStyles($color-dark, 12px,normal);
                            position:relative;
                            &::after{
                                position: absolute;
                                top:0px;
                                right:0px;
                                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M1 1L6.5 7L12 1" stroke="%238899B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                            }
                        }
                    }
                    
                }
            }
            .sortButton{
                padding:0;
                box-shadow: none;
                border:none;
                background: none;
                outline: none;
                padding:13px 0px;
                cursor: pointer;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 2px;
                    path{
                        stroke: $color-body-text-2;
                    }
                }
                @include fontStyles($color-body-text-2,12px,normal);
            }
            .activeSortButton{
                color:$color-text-secondary;
                padding:0;
                box-shadow: none;
                border:none;
                background: none;
                outline: none;
                padding:13px 0px;
                cursor: pointer;
                display: flex;
                align-items: center;
                @include fontStyles($color-text-secondary,12px,normal);
                svg{
                    margin-left: 2px;
                    path{
                        stroke:$color-text-secondary;
                    }
                }
            }
        }
        .filters-slider{
            padding:15px 0px 15px 20px ;
            .filter-item{
                border-radius: 20px;
                padding:10px 8px 10px 10px ;
                width: 97% !important;
                display: inline-flex !important;
                align-items: center;
                justify-content: space-between;
                background-color: rgba($color-secondary,0.13);
                > span{
                    @include fontStyles($color-secondary,14px,normal);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    @media screen and (max-width:600px) {
                        @include fontStyles($color-secondary,14px,normal);
                        width: 94% !important;
                    }
                    @media screen and (max-width:425px) {
                        @include fontStyles($color-secondary,12px,normal);
                        width: 97% !important;
                    }
                }
                .cross-btn{
                    padding:0;
                    height: auto;
                    min-width: unset;
                    background:none;
                    transition: none;
                    box-shadow: none;
                    > span{
                        margin:0;
                    }
                }
            }
        }
        .all-trackers-btn{
            color:$color-secondary;
            font-size: 14px;
            line-height: 16.94px;
            font-weight: 500;
            box-shadow: none;
            background: transparent;
            transition: none;
            z-index: 1;
        }
        .no-geofence-container {
            height: 73vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width:600px) {
                height: 43vh;
                svg{
                    width:80px;
                    height: 80px;
                }
            }
            .no-geofence {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .title {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    color: $color-text-secondary;
                    margin-top: 28px;
                    margin-bottom: 8px;
                    @media screen and (max-width:600px) {
                        font-size: 18px;
                        margin-top:20px;
                    }
                }

                .subTitle {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: $color-body-text-1;
                    width: 248px;
                    margin-bottom: 21px;
                }

                .link-to-geofence {
                    padding: 12px 25px;
                    background-color: $color-secondary;
                    border-radius: 23px;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 500;
                    height: auto;
                    svg{
                        margin-right: 8px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
         
    }
    .device-list-row {
        .MuiButtonBase-root.MuiListItemButton-root{
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;;
        margin-top:8px;
       background: none ;    
    }
    .address-checkbox   {
        width: 1.5em;
        position: absolute;
        bottom: 14px;
        right: 25px;
    
    }
    .activate-checkbox{
        position: relative;
        margin-right: 2px;
    }
    }
    .device-list-inner{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $color-light-body-3;
        justify-content: space-between;
        padding:0 20px;
        margin-top: 0px;
        .subscription-date {
            font-size: 12px;
            margin-left: 47px;
            .title {
                color: $color-secondary;
            }
            color: $color-body-text-1;
           }
        &:hover{
            background-color: rgba($color-secondary,0.13) !important;
        }
            .MuiButtonBase-root.MuiListItemButton-root.Mui-selected{
            background-color: transparent;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            }
            &.MuiButtonBase-root.MuiListItemButton-root,.battery-with-percentage{
             svg{
                font-size: 1.5rem;
                transform: rotate(90deg);
            }
        }
        .device-status{
            display: flex;
            flex-direction: row;
            align-items: center;
            .status{
                margin:0px 10px 0 5px;
            }
            .renew-tag{
                color: #FF8F00 !important;
                font-size: 12px;
                font-weight: 600!important;
                letter-spacing: 0;
                margin: 0px;
                border-radius: 14px;
                background: #FFF6E1;
                padding: 4px 12px;
                height: auto;
                display: none;
            }
            .expiring-soon-tag{
                color: #FF3434 !important;
                font-size: 12px;
                font-weight: 600!important;
                letter-spacing: 0;
                margin: 0px;
                border-radius: 14px;
                background: #FFF6F6;
                padding: 5px 12px;
                height: auto;
            }
            .activate-tag{
                color: #069450 !important;
                font-size: 12px;
                font-weight: 600!important;
                letter-spacing: 0;
                margin: 0px;
                border-radius: 14px;
                background: #06945012;
                padding: 5px 12px;
                height: auto;
            }
            img{
                width:30px;
                height: 30px;
                object-fit: cover;
                object-position: center;
            }
            .device-name{
                color: $color-dark;
                font-size: 16px;
                line-height: 150%;
                font-weight: 500;
                margin-right: 8px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
            }
        
        }
        .address-section{
    margin-left: 5px;
            @include displayFlex($justifyContent:flex-start,$alignItems:flex-start);
            .address-section-img{
                height: 24px;
                width: 24px;
                margin-right: 15px;
                margin-left: 3px;
            }
            .address{
                @include fontStyles($color-body-text-2, 14px, normal);
                max-width: 230px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .address-connection{
            @include fontStyles($color-body-text-1, 14px, normal);
            max-width: 220px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left:15px;
        }
    }
    .select-all-checkbox {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        margin-top: 6px;
        .MuiFormControlLabel-root {
            .MuiFormControlLabel-label {
                @include fontStyles($placeholder-color,14px);
            }
        }
    }
    .select-non-activate{
        margin-left: 20px;
        .MuiFormControlLabel-root {
            .MuiFormControlLabel-label {
                @include fontStyles(#8899B4,14px);
            }
        }  
    }
}
.offline-status{
    border-radius: 50%;
    height: 9px;
    width: 9px;
    background-color: #eb3939;
}
.online-status{
    border-radius: 50%;
    height: 9px;
    width: 9px;
    background-color:#31F451 ;
}
.sleep-status{
    border-radius: 50%;
    height: 9px;
    width: 9px;
    background-color:#e2f10a ;
}