.cart-dropdown {
    width: 100%;
    background-color: $address-form;
    color: $color-body-text-2;
    height: 100%;
    height: calc(100vh - calc(100vh - 100%));
    height: 100vh;
    height: 100dvh;

    .cart-dropdown__container {
        padding: 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        position: relative;
        background-color: $address-form;

        &__header {

            background-color: $address-form;
            z-index: 1000;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            justify-content: space-between;
            margin-bottom: 15px;

            p {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                color: $color-body-text-2;

                span {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;

                }
            }

            svg {
                cursor: pointer;
            }

            @media (max-width: 500px) {
                padding-inline: 10px;
            }
        }

        &__cart {
            padding: 25px 15px;
            background-color: $color-light;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            flex: 1;

            justify-content: space-between;

            .item-list {
                display: flex;
                flex-direction: column;
                gap: 48px;

                .subscription-form {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    justify-content: space-between;

                    .trash-btn {
                        background-color: transparent;
                        width: fit-content;
                        padding: 0;
                        background: none;
                    }

                    .order-section-one {
                        margin: 0;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        gap: 10;

                        .img-section {
                            padding: 0;
                            background-color: transparent;

                            img {
                                width: 80px;
                                height: 50px;
                            }
                        }

                    }

                    .total {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .trash-btn {
                        height: auto;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 0;
                        min-width: auto;
                    }

                    .product-total-price-section {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .qty-price {
                        font-size: 12px;
                        color: #8899B4;
                        font-weight: 500;
                    }

                    .price-with-discount {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        align-items: flex-end;

                        .discounted-price {
                            text-decoration: line-through;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            font-style: normal;
                            color: #F24343;
                        }
                    }

                    .product-name {
                        max-width: 128px;
                        margin-left: 15px;

                        @media screen and (max-width:585px) {
                            margin-left: 15px;
                        }

                        span {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%;
                            color: #021021;

                        }
                    }

                    .product-details {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    .sub-text {
                        width: 163px;
                        font-style: italic !important;
                        font-weight: 500 !important;
                        font-size: 10px !important;
                        line-height: 14px !important;
                        color: #8899B4 !important;

                        @media screen and (max-width:375px) {
                            width: auto;
                        }
                    }

                    .subscription-plan {
                        min-width: 109px;
                        padding: 0 10px;
                        border: 1px solid #8899B4;
                        ;
                        border-radius: 8px;
                        height: 37px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                        max-width: 115px;
                        position: relative;
                        margin-top: 10px;
                        left: 15px;

                        &__dropdown {
                            position: absolute;
                            top: 40px;
                            left: 0;
                            width: 100%;
                            border: 1px solid #8899B4;
                            ;
                            border-radius: 8px;
                            padding: 10px 15px 0;

                            .active-item {

                                p {
                                    color: $color-secondary !important;
                                }
                            }

                            p {
                                color: #172434 !important;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 140%;
                                margin-bottom: 15px;
                                cursor: pointer;

                            }
                        }

                        button {
                            height: auto;
                            padding: 0;
                            box-shadow: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: row;
                            width: 100%;

                            background: transparent;

                            p {
                                color: #021A37 !important;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 140%;


                            }
                        }
                    }
                }
            }

            .divider {
                height: 1px;
                background-color: #AAB6BC;
                margin: 24px 0;
            }

            .device-info-message {
                @include displayFlex(row, flex-start);
                margin: 5px 0 20px 0;
                background-color: rgba(200, 225, 255, 0.32);
                padding: 15px;
                border-radius: 15px;

                p {
                    color: $color-body-text-2;
                    font-size: 12px;
                    font-style: normal;
                    max-width: 100%;
                    font-weight: $font-weight-medium;
                    line-height: 18px;


                    span {
                        color: $color-secondary;

                    }
                }
            }

            .sub-total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
                border-top: 1px solid #AAB6BC;
                padding-top: 15px;


                .key {
                    color: #8899B4;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;

                }

                .value {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #021021;

                }
            }



            button {
                width: 100%;
                border-radius: 8px;
                background-color: $color-light;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: $color-light;
                text-transform: capitalize;
                height: auto;
                padding: 14px 25px;
                font-family: inherit;
                background: #1A74E2;
                border-radius: 8px;


            }

            @media (max-width: 500px) {
                padding-inline: 8px;
            }
        }

        .empty-cart-wrapper {
            height: calc(100vh - 102px);
            margin-bottom: 0 !important;
        }

        .trackers {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__footer {
            display: flex;
            align-items: center;
            gap: 14px;
            margin-top: 34px;

            p {
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;

                span {
                    font-weight: 600;
                    text-decoration-line: underline;
                    color: $color-secondary;
                }

                @media (max-width: 500px) {
                    font-size: 14px;
                }
            }

            @media (max-width: 500px) {
                justify-content: center;
            }
        }

        @media (max-width: 500px) {
            padding-inline: 10px;
        }

        @media (max-width: 500px) {
            padding-inline: 8px;
        }
    }

    .empty-cart {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        img {

            margin-bottom: 24px;
        }

        .title {
            color: $color-text-secondary;
            font-size: 20px;
            font-weight: 500;
            line-height: 150%;
        }

        .desc {
            color: $color-body-text-1;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
        }

        button {
            width: 100%;
            border-radius: 8px;
            background-color: $color-light;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            color: $color-light;
            text-transform: capitalize;
            height: auto;
            padding: 14px 25px;
            font-family: inherit;
            background: #1A74E2;
            border-radius: 8px;


        }

        button {
            width: 128px;
            margin-top: 24px;
        }
    }
}

.shopping-cart {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 8px;

}

.sticky-footer {
    position: sticky;
    bottom: 0;
    z-index: 1055;
    background-color: #ffffff;
    padding: 15px;
    flex-shrink: 0;

    .sub-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        border-top: 1px solid #AAB6BC;
        padding-top: 15px;


        .key {
            color: #8899B4;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;

        }

        .value {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #021021;

        }
    }



    button {
        width: 100%;
        border-radius: 8px;
        background-color: $color-light;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: $color-light;
        text-transform: capitalize;
        height: auto;
        padding: 14px 25px;
        font-family: inherit;
        background: #1A74E2 !important;
        border-radius: 8px;


    }

}