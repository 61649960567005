.buy-in-bulk-btn {
	padding: 0 !important;
	color: #ffffff !important;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	box-shadow: none  ;
	min-width: unset !important;
	transition: none !important;
	background: none !important;
	text-transform: capitalize !important;
	span {
		margin-right: 8px;
        @include fontStyles($color-secondary,16px,nomral);
        cursor: pointer;
	}
}
.buy-in-bulk-stepper {
	
	.buy-in-bulk-popup{
		background-color:$color-light;
	border-radius:20px;
	padding:10px 25px 25px;
	margin:0 10px;
	position: relative;
	.spinner-circle{
		width:auto !important;
	}
	@media screen and (max-width: 767px) {
		padding:10px 2px 25px;
		margin:0 0px;
	}
	.payment-btn{
		position: absolute;
		bottom:25px;
		right:15px;
		background-color: $color-secondary;
		border:none;
		color: #ffffff !important;
		width: auto ! important;
		height: auto !important;
		padding: 14px 25px !important;
		font-size: 14px;
		box-shadow: none !important;
		border-radius: 30px !important;
		text-transform: unset;
		@media screen and (max-width: 767px) {
			right:0;
		}
		@media screen and (max-width: 575px) {
			width:100%;
		}
	}
	}
	.MuiStepConnector-root {
		position: relative;
		top: -8px;
	}
	.MuiStepIcon-root {
		color: $color-body-text-1;
		width: 32px;
		height: 32px;
	}
	.MuiStepConnector-alternativeLabel {
		top: 17px;
		left: calc(-50% + 15px);
		right: calc(50% + 15px);
		position: absolute;
	}
	.MuiStep-horizontal {
		padding: 0 !important;
	}
	.MuiStepContent-root {
		margin: 0;
		padding: 0;
		border: none;
	}
}
.buy-in-bulk-stepper .MuiStepLabel-label, .buy-in-bulk-stepper .MuiStepLabel-label.MuiStepLabel-completed, .buy-in-bulk-stepper .MuiStepLabel-label.MuiStepLabel-active {
	@include fontStyles($color-text-secondary,14px,normal);
	margin-top: 8px !important;
	font-family: inherit;
	@media screen and (max-width:575px) {
		font-size: 12px;
	}
}
.buy-in-bulk-stepper .MuiStepIcon-root.Mui-active,.buy-in-bulk-stepper .MuiStepIcon-root.MuiStepIcon-active,.buy-in-bulk-stepper .MuiStepIcon-root.Mui-completed, .buy-in-bulk-stepper .MuiStepIcon-root.MuiStepIcon-completed {
	color: #1A74E2;
}
/*Upload CSV*/
.upload-csv-devices{
	margin-top:25px;
	display: flex;
	flex-direction: column-reverse;
	.title{
		@include fontStyles($color-text-secondary,14px,normal);
		margin:10px 0px;
	}
	.sub-title{
		@include fontStyles($color-body-text-1,12px,normal);
		margin:10px auto 20px;
		width: 100%;
		text-align: center;
		max-width: 90%;
	}
	.csv-template {
		@include fontStyles($color-secondary,14px,normal);
		text-decoration: none;
	}
		.upload-btn-wrapper {
			position: relative;
			overflow: hidden;
			display: inline-block;
			cursor: pointer;
			width: 100%;
			>div{
				width:100%;
				justify-content: center;
				display: flex;
			}
			input[type=file] {
				width: 100%;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				opacity: 0;
				height: 100%;
				cursor: pointer;
				min-height: 106px;
				font-size: 14px;
				font-weight: 500;
				box-shadow: none;
				outline: none;
			}
		}
		.uploaded-file-section {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			background-color: #ffffff;
			.uploaded-file-desc {
				.uploaded-file-name {
					color: $color-text-secondary;
					margin-left: 8px;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
		.uploaded-file-desc {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}
		.uploaded-file-btns {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: #4F5661;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			min-width: unset !important;
			text-decoration: none;
			background: transparent !important;
			span {
				color: #4F5661;
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				text-transform: capitalize;
				line-height: normal;
				margin-left: 10px;
				text-decoration: none;
			}
		}
	
	.upload-btn-wrapper {
		.upload-file {
			border: 2px dashed $color-body-text-1 !important;
			color: $color-text-secondary;
			background-color: $color-tab-background !important;
			width: 70%;
			height: 204px;
			border-radius: 20px !important;
			font-size: 15px;
			cursor: pointer;
			box-shadow: none;
			text-transform: unset;
			@media screen and (max-width:575px) {
               width:90%;
			   height: 165px;				
			}
		}
		.upload-btn {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			box-shadow: none;
			color: $color-text-secondary;
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 140%;
				color: $color-body-text-2;
				margin-top: 15px;
			}
		}
	}
.uploaded-file-btns .delete, .uploaded-file-btns .preview {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
}
/*verify CSV files*/
.verified-csv-devices {
	
	.verified-csv-devices-header {
		 display: flex;
		 flex-direction: row;
		 align-items: center;
		 background-color: #EFFBFF;
		 padding:15px 10px 15px;
		 width: fit-content;
		 border-radius: 15px;
		 margin:20px auto;
		 justify-content: center;
		 svg{
			margin-right: 8px;
		 }
		 p{ 
			font-weight: 500;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: 0.01em;
			color: #2E3B4B;
			flex:1;
			span{
				color: $color-secondary;
			}
		 }
		 
	}
	.verified-csv-devices-body{
		background-color: #F6F8FA;
		border-radius: 20px;
		padding:20px 0px;
	}
}
.verified-devices-body {
	max-height: 53vh;
	overflow-y: auto;
    padding:0 0px 0 25px;
	display: flex;
	flex-direction: row;
	 
	flex-wrap:wrap;
	@media screen and (max-width:600px) {
		max-height: 27vh;
	}
	@media screen and (max-width:991px) {
		justify-content: space-between;
		padding:0 25px;
	}
	.devices-section {
		padding: 10px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 32%;
		@media screen and (max-width:991px) {
			width: 50%;
		}
		@media screen and (max-width:600px) {
			width: 100%;
		}
		img {
			width: 50px;
			height: 50px;
			margin-right: 10px;
		}
		.devices-title {
			color: $color-text-secondary;
			margin-bottom: 5px;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.devices-modal-number {
			color: $color-body-text-1;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.devices-details {
		display: flex;
		flex-direction: column;
	}
}
.bulk-devices-footer{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-top:1px solid rgba($color-border,0.4);
	padding-top:15px;
    margin-top:15px;
	width: 100%;
	flex-wrap: wrap;
	gap:10px;
	@media screen and (max-width:575px) {
		flex-direction: column-reverse;
		justify-content: center;
		.MuiButton-root{
width:90% !important;
		}
	}
}
