.hero {
    padding: 0;
    background-position: top;

 .hero-section-text{
    width: 100%;
    position:absolute;
    left:0;
    
    @media screen and (max-width:991px) {
        //position: relative;
    }
 }
    &__section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        min-height:490px;
        @media screen and (max-width:991px) {
            
           background-color: #000613D9;
           min-height:auto;
        }
        &:after{
            position: absolute;
            left:0;
            top:0;
            height:100%;
            content:' ';
            width:100%;
            background:  linear-gradient(90deg, #000613 0%, #000613D9 38%, #000B1E59 57%, #042F5000 100%) 0% 0% no-repeat padding-box;
            //@media screen and (max-width:991px) {
            //   content: none;
            // }
        }
        &--content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 631px;
            position: relative;
            z-index:1;
            @media screen and (max-width:991px) {
            
                width:auto;
             }
            h1 {
                font-size: 41px;
                line-height: 130%;
                margin-bottom: 14px;
                font-weight: 500;
                color:$color-light;
                @media screen and (max-width:1200px) {
                    max-width: auto;
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                color:#EAF3FF;
                margin-bottom: 26px;    
            }

            .shop-now-btn {
                display: flex;
                flex-direction: row;
                padding: 12px 18px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background: $color-secondary;
                box-shadow: none;
                align-self: center;
                text-transform: capitalize;
                border: none;
                color: $color-light;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                cursor: pointer;
                height: auto;
                margin: 0;
                min-width: unset;
                svg{
                    margin-left: 5px;
                    height: 22px;
                    
                }
            }

            @media (max-width: 1100px) {
                h1 {
                    font-size: 4.5rem;
                    line-height: 45px;
                }

                p {
                    font-size: 1.9rem;
                }
            }
            @media (max-width: 800px) {
                h1 {
                    font-size: 4rem;
                }

                p {
                    font-size: 1.8rem;
                }
            }
            @media (max-width: 700px) {
                h1 {
                    font-size: 3.5rem;
                }

                p {
                    font-size: 1.7rem;
                }

                button {
                    font-size: 14px;
                    
                }
            }

            @media (max-width: 600px) {
                h1 {
                    font-size: 22px;
                    line-height: 36px;
                }
                p {
                    width: 100%;
                    font-size: 14px;
                }
            }
        }

        &--video {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0;
            min-height: 490px;
             video{
                max-width: 100%;
                 min-height: 490px;
                 object-fit: unset;
             }

             @media (min-width: 991px) {
                height: 83vh;
                video {
                    object-fit: cover;
                    width: 100%;
                }
            }
            @media (min-width: 1800px) {
                height: 84vh;
            }
            @media (min-width: 1921px) {
                height: auto;
            }
            // @media screen and (max-width:991px) {
            //    display: none;
            //}
        }
    }

    @media (min-width: 991px) {
        height: 83vh;
    }
    @media (min-width: 1800px) {
        height: 84vh;
    }
    @media (min-width: 1921px) {
        height: auto;
    }
}