.saving-section{
    border-radius: 20px;
    border: 0.8px solid #27AE60;
    background: rgba(220, 252, 233, 0.26);
    @include displayFlex();
    flex-wrap:nowrap;
    padding: 7px 13px;
    p{
        margin: 0;
        margin-left: 8px;
        color: #27AE60;
        font-size: 16px;
        font-weight: $font-weight-medium;
        line-height: 120%;
        span{
            font-weight: $font-weight-semibold;
        }
    }

}