.alerts-page {
  margin: 32px 75px 10px;
  flex:1;
  @media screen and (max-width:1200px) {
    margin: 32px 7% 10px;
  }
  @media screen and (max-width:600px) {
    margin: 30px 3% 10px;
    width: 37%;
  }
}

.alerts-page .page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap:wrap;
  gap:10px;
  margin-bottom: 30px;
   
}

.alerts-page .page-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.alerts-page .alert-toggle {
  border-radius: 35px;
  background: white;
  padding: 14px 18px 14px 11px;
  @media screen and (max-width:786px) {
    padding: 15px 20px;
  }
}

.alerts-page .alert-toggle .MuiSwitch-switchBase {
  padding: 3px;
}

.alerts-page .alert-toggle .MuiSwitch-thumb {
  width: 18px;
  height: 19px;
  color: white;
}

.alerts-page .alert-toggle .MuiTouchRipple-root {
  color: white !important;
}

.alerts-page .alert-toggle .MuiFormControlLabel-label {
  font-size: 14px !important;
  margin-left: 10px;
  @media screen and (max-width:786px) {
    font-size: 14px !important;
    margin-left: 6px;
  }
}

.alerts-page .alert-toggle .MuiFormControlLabel-root {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alerts-page .alert-toggle .MuiSwitch-track {
  background-color: #D5D5D5;
  opacity: 1;
}

.alerts-page .alert-toggle .MuiSwitch-root {
  height: 25px;
  border-radius: 20px;
  width: 45px;
  padding: 0;
}

.alerts-page .alert-toggle .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: $color-secondary;
}
.alerts-page-tabs{
  .MuiTabs-flexContainer{
    width:100%;
    justify-content: space-evenly;
    display: flex;
  }
}