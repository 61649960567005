.verification-msg-box{
    .title{
        color: $color-text-secondary;
        font-size: 24px;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: normal;
        margin-top:25px;
    }
    .sub-title{
        font-size: 24px;
        color: $color-secondary;
        font-weight: $font-weight-medium;
        line-height: normal;
        margin: 10px 0px;
    }
    .description{
        color: $color-dark;
        text-align: center;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 21px; 
        margin:8px 0px 10px;
        .gmailLink{
            text-decoration: underline;
            color: $color-secondary;
        }
    }
    .resend-description{
        color: $color-text-secondary;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: normal;
        text-align: center;
        .spinner-circle{
            margin-top:10px;
            padding: 6px 25px;
        }
        .resend-button{
            color: $color-secondary;
            font-size: 14px;
            font-weight: $font-weight-medium;
            line-height: normal;
            padding: 0;
            text-transform: initial;
        }
    }
    .reset-password-section{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            color: $color-dark;
            font-size: 14px;
            font-weight: $font-weight-medium;
            line-height: normal;
        }
        .login-btn{
            padding:0;
            color:$color-secondary;
            font-size: 14px;
            font-weight: $font-weight-semibold;
            min-height: auto;
            background: none;
            box-shadow: none;
            min-width: auto;
            margin-left: 3px;;
        }

    }
    .tracking-btn{
        color:$color-light;
        background-color: $color-secondary;
        font-size: 16px;
        font-weight: $font-weight-medium;
        text-transform: initial;
        line-height: 150%;
        padding:12px 25px;
        margin-top:15px;
        border-radius: 8px;
    }
}