@import "./mobile-view";

.how-to-start {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 6rem ;
    @media screen and (max-width:600px) {
        margin-top: 5rem ;
        gap: 0px;
    }
    .heading {
        margin-bottom: 7rem;
        h2 {
            color: $color-secondary;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            text-transform: uppercase;
        }

        h3 {
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        @media (max-width: 600px) {
            margin-bottom: 54px;

            h2 {
                font-size: 12px;
            }
            
            h3 {
                font-size: 24px;
            }
        }
    }

    .content-container {
        display: flex;
        gap: 30px;
@media screen and (max-width:1200px) {
    flex-direction: column;
}
        .list {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
    
            .start-item {
                padding: 24px 16px;
                border-radius: 16px;    
                display: flex;
                gap: 16px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
    
                .number {
                    min-width: 56px;
                    height: 56px;
                    display: flex;
                    padding: 8px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 50px;
                    background: #F0F0F0;
                    font-size: 36px;
                    font-weight: 700;
                    color: $color-secondary;
                }
    
                .content {
                    .title {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 150%;
                        margin-bottom: 4px;
                    }
                    .desc {
                        color: $color-body-text-2;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 150%;
    
                        a {
                            color: $color-secondary;
                        }
                    }
                }

                &:hover {
                    box-shadow: 0px 12px 64px -8px rgba(21, 21, 21, 0.16);
                }
            }
        }
    
        .display {
            flex: 1;
            display: flex;
            justify-content: flex-end;
    
            &__border {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                img {
                    width: 90%;
                    height: 90%;
                    border-radius: 29px;
                    object-fit: contain;
                    object-position: center;
                }

                .animate {
                    animation: imageTransition 0.3s ease-in-out;
                }

                @media (max-width: 600px) {
                  width: 100%;
                }
            }
        }
    }

    @media (max-width: 600px) {
        padding: 0 2rem;
    }
}


@keyframes imageTransition {
    0% {
      transform: translateY(25%);
      opacity: 0;
    }
  
    50% {
      transform: translateY(-25%);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }