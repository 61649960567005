.content-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .item-container {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .start-item {
            padding: 24px 16px;
            border-radius: 16px;    
            display: flex;
            gap: 16px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            .number {
                min-width: 56px;
                height: 56px;
                display: flex;
                padding: 8px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 50px;
                background: #F0F0F0;
                font-size: 36px;
                font-weight: 700;
                color: $color-secondary;
            }

            .content {
                .title {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 150%;
                    margin-bottom: 4px;
                }
                .desc {
                    color: $color-body-text-2;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 150%;

                    span, a {
                        color: $color-secondary;
                    }
                }
            }

            @media (max-width: 600px) {
                .number {
                    font-size: 26px;
                }

                .content {
                    .title {
                        font-size: 16px;
                    }
                    .desc {
                        font-size: 14px;
                    }
                }
            }
        }

        .display {
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            max-height: 600px;
            display: flex;
            justify-content: center;

            img {
                // width: 100%;
                width: 50%;
                max-height: 600px;

                @media (max-width: 600px) {
                    width: 100%;
                }
            }
        }

        .active {
            opacity: 1;
            visibility: visible;
            transform: translate(0);
        }

        .non-active {
            max-height: 0;
            transform: translateY(-100px);
            opacity: 0;
            visibility: hidden;
        }
    }
}