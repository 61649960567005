.reach-out {
   width: 36rem;

   &__heading {
    color: $color-body-text-3;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1.4rem;
    line-height: 20.95px;
   
   }

   &__description {
    color: $color-footer-text;
    font-size: 14px;
    line-height: 23.95px;
    font-weight: 500;
    margin-bottom: 2rem;
   
   }

   @media (max-width: 991px) {
      width: 87%;
   }
   @media (max-width: 600px) {
      width: 100%;
   }
   
}