.add-devices-payment {
	.payment-plan-header {
		display: flex;
		flex-direction: column;
		color: #D5D5D5;
		margin-top:25px;
		p {
			margin-bottom: 8px;
			@include fontStyles($color-text-secondary,12px,normal);
			text-transform: uppercase;
		}
		span {
			@include fontStyles($color-body-text-1,12px,normal);
		}
	}
	.billed-radiobox {
		border: 1px solid $color-body-text-3;
		border-radius: 46px;
		padding: 15px 0px 17px 15px;
		width: 100%;
		position: relative;
		margin-top: 15px;
		.billed-radiobox-inner {
			display: flex;
			flex-direction: column;
			.bill-cancel {
				color: $color-body-text-1;
				font-size: 12px;
				display: block;
				margin-left: 20px;
				margin-top: 2px;
			}
			.dollar {
				font-size: 18px;
				font-weight: 500;
				@media screen and (max-width:390px) {
					font-size: 14px;
				}
			}
			.months {
				font-size: 22px;
				font-weight: 600;
				@media screen and (max-width:390px) {
					font-size: 16px;
				}
			}
			.monthly-plan {
				.original-bill {
					font-size: 14px;
					color: #FF0000;
					text-decoration: line-through;
					position: absolute;
					top: -15px;
					@media screen and (max-width:390px) {
						font-size: 12px;
					}
				}
			}
			.MuiFormControlLabel-root {
				margin-left: -11px !important;
			}
		}
	}
	
	.MuiFormControlLabel-label {
		color: $color-text-secondary;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-left: 6px;
	}
	.MuiButtonBase-root {
		&.MuiRadio-root {
			position: relative;
			top: 10px;
			padding: 0;
			left: 3px;
		}
	}
	.billed-radiobox-inner {
		.monthly-plan {
			position: absolute;
			right: 25px;
			top: 22px;
			@include fontStyles($color-body-text-2,10px,normal);
			@media screen and (max-width:390px) {
				top: 24px;
				right:15px;
			}
		}
	}
	.billed-checked-radiobox{
		border: 1px solid transparent;
		background-color: $color-secondary;
		.dollar,.months,.MuiFormControlLabel-label,.monthly-plan{
			color:$color-light !important;
		}
		.bill-cancel{
			color:#E9E8E8 !important;
		}
	}
	.safe-checkout {
		margin: 15px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		p {
		@include fontStyles($color-dark,14px,normal);
			margin: 0;
			margin-left: 7.76px;
		}
		span {
			@include fontStyles($color-secondary,14px,normal);
		}
	}
	.renew-plan-footer .btn, .devices-summary .btn{
		background-color: $color-secondary;
		@include fontStyles($color-light,14px,normal);
		padding:14px 25px;
		width:100%;
		height:auto;
		min-width:unset;
		border-radius: 8px;
		text-transform: initial;
		img{
			margin-left: 4px;
		}
	} 
}
.renew-plan-footer  p{
	margin:5px 0;
	@include fontStyles($color-body-text-2,12px,normal);
	.link{
		color:$color-secondary;
		text-decoration:underline;
		font-size: 12px;
}
}