.welcome-modal-content {
  max-width: 553px;
  z-index: 10000;
  height: 401px;
  background-color: white;
  border-radius: 19px;
  padding: 175px 55px 74px 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 14px;
  outline: none;
  border: none;
.btn--outlined{
  display: flex;
  align-items: center;
  border:1px solid #8899B4;
color: #8899B4;
gap:5px;
padding:12px 25px;
text-decoration: none;
box-shadow: none;

}
  @media screen and (max-width: 500px) {
    width: 332px;
  }
  
&.new-tracker-modal{
  height: auto;
  padding: 55px 55px 74px 55px;
  @media screen and (max-width: 500px) {
    width:auto;
    max-width: 90%;;
  }
}
&.delete-geofence-modal{
  height:auto;
  padding:55px 64px;
  svg{
    margin-bottom: 30px;
  }
  @media screen and (max-width: 500px) {
    width: auto;
    max-width: 90%;
  }
  .btn{
    height: auto;
  }
}
&.add-new-trackers-popup{
  height:auto;
  padding:55px 64px;
  max-width: 480px;
  @media screen and (max-width: 500px) {
    width: auto;
    max-width: 90%;
  }
  .btn{
    height: auto;
  }
}
  & p {
    color: $color-body-text-1;
    font-size: 14px;
    max-width: 100%;
    line-height: 19.07px;
  }

  & h2 {
    color: $color-text-secondary;
    font-size: 24px;
    font-weight: 500;
    span{
      color: $color-secondary;
    }
  }

  & .animation {
    position: absolute;
    left: 50%;
    height: 300px;
    top: 0;
    transform: translateX(-50%);
  }

  & .x {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .btn{
  height: auto;
  }
}