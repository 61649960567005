.clients {
    background-color: $color-client-light;
    padding-bottom: 4rem;
    
    h2 {
        color: $color-body-text-2;
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 3.2rem;
    }

    .showcase {
        overflow-x: hidden;
        .row {
            display: flex;
            gap: 7.3rem;
            white-space: nowrap;
            box-sizing: content-box;
            animation: showcase 30s linear infinite;

            img {
                width: 150px;
            }

            @media (max-width: 1024px) {
                animation-duration: 20s;

                img {
                    width: 100px;
                }
            }

            @media (max-width: 600px) {
                animation-duration: 15s;

                img {
                    width: 80px;
                }
            }
        }
    }
}

@keyframes showcase {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}