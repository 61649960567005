.stories-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {
        width: 1002px;
        min-width: 1002px;
        display: flex;
        align-items: center;
        gap: 44px;
        overflow-x: hidden;
        scroll-behavior: smooth;

        .shadow-slide {
            min-width: 949px;
            opacity: 0;
        }

        // & .story-slide:nth-child(2) {
        //     filter: blur(1px);
        // }

        @media (max-width: 1300px) {
            width: 1102px;
            min-width: 1102px;
        }

        @media (max-width: 1150px) {
            width: 90vw;
            min-width: 90vw;
        }
    }

    .next {
        position: absolute;
        right: 205px;
        top: 50%;
        transform: translate(0%, -75%);

        &:hover {
            box-shadow: none !important;
        }

        @media (max-width: 1300px) {
            // right: 445px;
            right: 305px;
        }

        @media (max-width: 1150px) {
            right: -32px;
        }

        @media (max-width: 600px) {
            right: -31px;
        }
    }

    .prev {
        position: absolute;
        left: -35px;
        top: 50%;
        transform: translate(0%, -75%) rotate(180deg);

        &:hover {
            box-shadow: none !important;
        }

        @media (max-width: 600px) {
            left: -31px;
        }
    }

    &__pagination {
        margin-top: 26px;
        display: none;

        &__dots {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-inline: 15px;

            .active-dot {
                g {
                    rect {
                        fill:  #1A74E2
                    }
                }
            }
        }

        &__prev {
            transform: rotate(-180deg);
        }

        .un-active {
            svg {
                path {
                    stroke: #BDC9E2;
                }
            }
        }

        @media (max-width: 600px) {
            display: flex;
        }
    }
}