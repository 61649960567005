.status-item {
    .title {
        color: $color-body-text-1;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    svg, img {
        margin-top: 3.4rem;
        margin-bottom: 4.1rem;
        width: 73px;
        height: 61px;
    }

    .status {
        color: $color-dark;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.status-item-mobile {
    display: flex;
    align-items: center;
    gap: 32px;

    svg, img {
        width: 73px;
        height: 61px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title {
            color: $color-body-text-1;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        .status {
            color: $color-dark;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}