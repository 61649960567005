@import "./hero-feature";

.help-hero {
    padding: 110px 0;
    padding-bottom: 50px;

    &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 94px;

        h1 {
            color: $color-text-secondary;
            font-size: 44px;
            font-weight: 500;
            line-height: 130%;

            @media (max-width: 600px) {
                font-size: 32px;
            }
        }

        p {
            color: $color-body-text-2;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin: 14px 0 32px;

            span {
                display: block;

                @media (max-width: 700px) {
                    display: inline;
                }
            }

            @media (max-width: 600px) {
                font-size: 14px;
            }
        }

        &__search-container {
            width: min(527px, 100%);
            height: 54px;
            border-radius: 340px;
            border: 1px solid $color-body-text-3;
            background-color: $color-light;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            padding: 17px 22px;

            svg {
                path {
                    stroke: $color-body-text-3;
                }
            }

            input {
                border: none;
                outline: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
                color: $color-body-text-1;
                width: 100%;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: $color-body-text-1;
                }

                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }
        }
    }

    &__features-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 38px;
        row-gap: 28px;
        
        @media (max-width: 1024px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 700px) {
            grid-template-columns: 1fr;
        }
    }
}