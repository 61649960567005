@import "./customer-slider";

.customer-experience {
    background-color: $color-customer-experience-bg;
    // padding: 12rem 14.2rem;
    padding: 12rem 0;

    &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6rem;

        h2 {
            text-align: center;
            color: $color-secondary;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: uppercase;
        }

        h3 {
            color: $color-text-secondary;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            margin-top: 8px;
            margin-bottom: 12px;

        }

        p {
            color: $color-dark;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            width: min(566px, 100%);
        }

        @media (max-width: 600px) {
            h2, p {
                font-size: 14px;
            }
            h3 {
                font-size: 24px;
            }
        }
    }
 
    @media (max-width: 800px) {
        padding: 8rem 0;
    }
}