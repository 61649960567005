.device-detail-popup{
    position: absolute;
    left: 35px;
    top: 15px;
    z-index: 2;
    background: $color-light;
    box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.20);
    border-radius: 20px;
    padding: 20px 15px;
    min-width: 360px;
    max-width:372px;
    @media screen and (max-width:600px) {
        bottom: 0;
        top: unset;
        width: 100%;
        min-width: 100%;
        max-width:100%;
        left: 0;
        border-radius: 20px 20px 0 0;
        max-height: 48vh;
        overflow-y: auto;
    }
}