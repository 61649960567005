.edit-device-header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
	width: 100%;
	.back-btn {
		min-width: unset !important;
		box-shadow: none !important;
		padding: 0 !important;
		background-color: $color-light !important;
		border-radius: 12px !important;
		margin-right: 0px;
		box-shadow: none !important;
		height: 24px;
		width: 24px;
		position: relative;
        .device-name{
            @include fontStyles($color-dark,14px,150%);
        }
	}
	.device-img {
		width: 36px;
		height: 36px;
		margin-top: 5px;
	}
	.device-name {
		color: $color-dark;
		font-size: 16px;
		margin-left: 10px;
		font-weight: 500;
		margin-right: 10px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 66%;
	}
}
.edit-device-body {
	padding-top: 15px;
	.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
		background-color: $color-light;
		border-radius: 8px;
		color: $color-light;
		font-size: 14px;
		padding: 8px 14px;
		margin-bottom: 0;
		&::after {
			content: url('../../../resources/images/arrow-up.svg');
			position: absolute;
			right: 12px;
			top: 11px;
		}
	}
	.select-field {
		background-color: $color-light !important;
		width: 100%;
		border-radius: 8px;
		color: $color-light;
		border: none !important;
		outline: none;
		padding: 6px 12px;
		font-size: 13px;
		margin-top: 5px;
		position: relative;
	}
	.MuiAutocomplete-root {
		.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
			&::after {
				content: url('../../../resources/images/arrow-up.svg');
				position: absolute;
				right: 12px;
				top: 11px;
			}
		}
	}
	.MuiInputBase-root.MuiOutlinedInput-root {
		svg {
			display: none;
		}
	}
	.MuiOutlinedInput-notchedOutline {
		display: none;
	}
    .device-icon-selection-box{
        border-top: 1px solid rgba($border-color,0.4);
        margin-top: 20px;
        .device-icon-header {
            display: flex ;
            justify-content: space-between;
            @include fontStyles($color-text-secondary,14px,normal);
            align-items: center;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    .device-icon-selection-box {
        .device-icon-header {
            .edit-btn {
                padding: 0;
                color: $color-text-secondary;
                text-transform: initial;
                font-weight: 400;
                margin-left: 6px;
                font-size: 12px;
                box-shadow: none;
                transition: none;
                background-color: transparent !important;
                span{
                    margin-left:6px;
                }
            }
        }
        .MuiButton-root {
            &.Mui-disabled {
                color: white;
            }
        }
        .save-btn {
            border-radius: 8px;
            background-color: $color-secondary !important;
            font-size: 14px;
            color: #ffffff;
            text-transform: initial;
            font-weight: 500;
            margin-top: 8px;
            box-shadow: none;
            transition: none;
            padding: 12px 25px  !important;
            height:auto;
        }
        .btn-section {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            // @media screen and (max-width:600px) {
            //     padding: 10px 20px;
            //     background-color: $color-light;
            //     width: 100%;
            //     position: fixed;
            //     bottom: 0;
            //     left:0;
            //     box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.2);
            // }
            .spinner-circle{
                width: fit-content;
                border-radius: 8px;
                padding: 10px 40px;

            }
        }
    }
    .changed-device-checkbox{
        .MuiFormControlLabel-root .MuiFormControlLabel-label{
            @include fontStyles($color-body-text-2,12px);
            margin-left:-6px;
        }
    }
    .map-img {
        background-image: url('https://d1nxfqk0pytl4a.cloudfront.net/images/map-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        margin-bottom: 1px;
        .selected-device {
            width: 55px;
            height: 55px;
            object-fit: cover;
            object-position: center;
        }
    }
    
    
}
