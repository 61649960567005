@import "./why-peeq-card";

.why-peeq {
    padding: 80px 0;
    background-color: $color-why-bg;
    @media (max-width: 1200px) {
        padding:60px 0px;
    }
     @media (max-width: 600px) {
        padding:30px 0px;
    }
    &__container {
        display: flex;
        gap: 113px;

        &__content {
            flex: 1;
            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
                text-transform: uppercase;
                color: $color-secondary;
            }

            .sub-title {
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                color: $color-text-secondary;
                margin-top: 8px;
                margin-bottom: 16px;
            }

            .description {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: $color-dark;
                margin-bottom: 10px;
                span {
                    color: $color-secondary;
                    font-size: 20px;
                    @media (max-width: 1200px) {
                        font-size: 18px;
                    }
                    @media (max-width: 600px) {
                        font-size: 16px;
                    }
                }
            }

            @media (max-width: 600px) {
                .title {
                    font-size: 14px;
                }

                .sub-title {
                    font-size: 24px;
                }

                .description {
                    font-size: 14px;
                }
            }
        }

        &__cards {
            flex: 1;
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 18px;
            column-gap: 18px;

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
        }

        @media (max-width: 1400px) {
            gap: 50px;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            gap:30px;
        }
    }

    @media (max-width: 600px) {
        padding: 42px 0;
    }
}