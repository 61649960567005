.why-peeq-card {
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.09);

    &__icon {
        width: 47px;
        margin-bottom: 14px;

        img {
            width: 100%;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        color: $color-dark;
        margin-bottom: 10px;
    }

    &__price-container {
        .annually {
            font-size: 12px;
            font-weight: 500;
            color: $color-body-text-1;
            margin-bottom: -5px;
            opacity: 0;
        }

        .price {
            font-size: 42px;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: -0.84px;
            color: $color-secondary;
        }

        .show {
            opacity: 1;
        }
    }
}