.thank-you {
    padding: 75px 0;

    &__container {
        background-color: $color-light;
        border-radius: 8px;
        padding-bottom: 50px;


        .btn-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        &__header {
            padding: 52px 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 72px;
            @media screen and (max-width:600px) {
                margin-bottom: 36px;
            }

            &__img {
                margin-bottom: 34px;
                min-width: 117px;
            }

            &__title {
                color: $color-dark;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 6px;
                line-height: 34.13px;

                @media screen and (max-width:600px) {
                    font-size: 22px;
                    line-height: 26.82px;
                }
            }

            &__desc {
                width: min(545px, 100%);
                font-size: 16px;
                font-weight: 500;
                color: $color-dark;
                margin-bottom: 20px;
                line-height: 24px;
                @media screen and (max-width:600px) {
                    font-size: 14px;
                }
                span,
                a {
                    color: $color-secondary;
                    font-weight: 600;
                    cursor: auto;
                    text-decoration: underline;
                }
            }

            &__btn {
                display: flex;
                padding: 11px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                height: auto;
                font-weight: 500;
                line-height: 24px;
                background-color: $color-secondary !important;
                color: #F4f5fb;
                font-size: 16px;
                border-radius: 8px;
                border: none;
                outline: none;
                cursor: pointer;
                min-width: 175px;

            }

            @media (max-width: 600px) {
                padding-inline: 18px;
            }
        }

        &__login {
            &__btn {
                display: flex;
                padding: 12px 16px;
                justify-content: center;
                align-items: center;
font-weight: 500;
                height: auto;
                color: $color-secondary !important;
                background-color: #ffffff !important;
                font-size: 16px;
                border-radius: 8px;
                border: 1px solid $color-secondary;
                outline: none;
                cursor: pointer;
                min-width: 201px;

            }
        }

        &__order-detail {
            margin-bottom: 40px;
            padding: 0 90px;
            display: flex;
            gap: 175px;

            @media (max-width: 1800px) {
                gap: 75px;
            }
            @media (max-width: 991px) {
                gap: 0px;
            }
            &__table {
                flex: 1;
                overflow: auto;

                &__wrapper {
                    @media (max-width: 600px) {
                        min-width: 100%;
                    }
                }

                .order-table__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $color-main-bg;
                    border-radius: 8px;
                    margin-bottom: 13px;
                    padding: 14px 18px;
                    .item__price{
                        min-width: 108px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        @media screen and (max-width:600px) {
                           min-width: 90px;
                        }
                    }
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: $color-dark;
                        line-height: 21px;
                    }


                }

                .order-table__body {
                    padding: 14px 18px;

                    .order__item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &__info {
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            width: 230px;
                            @media (max-width: 600px) {
                                gap:10px;
                            }
                            &__img {
                                width: 55px;
                                @media (max-width: 600px) {
                                    width: 40px;
                                }
                                @media (max-width: 425px) {
                                    width: 33px;
                                }
                            }

                            &__content {
                                font-weight: 500;
                                font-size: 12px;

                                .name {
                                    color: $color-text-secondary;
                                    font-weight: 600;
                                    line-height: 18px;
                                    @media (max-width: 600px) {
                                        font-size: 12px;
                                    }
                                }

                                .color {
                                    @include fontStyles(#172434, 12px);
                                    margin-top: 5px;
                                    font-weight: 500;
                                    text-transform: capitalize;

                                    @media (max-width: 600px) {
                                        font-size: 10px;
                                    }

                                    span {
                                        @include fontStyles(#4F5661, 12px);
                                        text-transform: capitalize;
                                        font-weight: 500;

                                        @media (max-width: 600px) {
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        &__qty {
                            color: $color-dark;
                            font-size: 14px;
                            font-weight: 500;
                            @media screen and (max-width:600px) {
                                font-size: 12px;
                            }
                         
                        }

                        &__price {
                            color: $color-dark;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            min-width: 108px;
                            span{
                                color:#8899B4;
                            }
                            @media screen and (max-width:600px) {
                                font-size: 12px;
                                min-width: 90px;
                            }
                        }
                    }

                    &__total {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        margin-top: 15px;

                        .total__container {
                            border-top: 1px solid #AAB6BC;
                           
                            padding: 14px 0px;
                            display: flex;
                            flex-direction: column;
                            gap: 14px;
                            @media screen and (max-width:1300px) {
                                width: 48%;
                            }
@media screen and (max-width:991px) {
    width: 100%;
}
                            .total__item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 80px;
                                font-weight: 500;
                                @media screen and (max-width:600px) {
                                    gap: 35px;
                                }
                                .item__name {
                                    color: $color-body-text-2;
                                    font-size: 14px;
                                    display: flex;
                                    flex-direction: row;
                                    gap: 4px;
                                    align-items: center;
                                    padding-left: 15px;
                                    @media screen and (max-width:600px) {
                                        padding-left: 0px;
                                    }
                                }

                                .item__price {
                                    color: $color-dark;
                                    font-size: 14px;

                                    &.discount {
                                        color: #27AE60;
                                    }
                                }
                            }
                        }

                        .order__total {
                          padding:15px 0 ;
                            border-top: 1px solid #AAB6BC;

                            .total__item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 150px;
                                font-weight: 500;

                                .item__name,
                                .item__price {
                                    color: $color-dark;
                                    font-size: 14px;
                                    
                                    &.discount {
                                        color: #27AE60;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__summary {
                flex: 1;

                .order-summary__container {
                    background-color: $color-main-bg;
                    border-radius: 8px;
                    padding: 20px 25px;
                    display: flex;
                    align-items: flex-start;
                    @media (max-width: 600px) {
                       flex-direction: column;
                       gap:20px;
                    }
                    .summary__item {
                        flex: 1;

                        .title {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-bottom: 23px;

                            p {
                                color: $color-text-secondary;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }

                        .payment-detail {
                            display: flex;
                            align-items: center;

                            span {
                                color: #021021;
                                font-size: 14px;
                                font-weight: 500;
                                margin-right: 10px;
                            }
                        }

                        .detail {
                            color: $color-body-text-2;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;

                            span {
                                font-weight: 600;
                                color: $color-dark;
                                display: inline-block;
                            }
                            &.user-name{
                                span{
                                    font-weight: 500;
                                    color: "#021021";
                                }
                            }
                        }
                        
                    }

                    @media (max-width: 500px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;
                    }
                }
            }

            @media (max-width: 1300px) {
                flex-direction: column;
            }

            @media (max-width: 700px) {
                padding: 0 18px;
            }
        }

        &__footer {

            &__heading {
                color: $color-dark;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h2 {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: 12px;
                }

                p {
                    width: min(515px, 100%);
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 36px;
                }
            }

            &__points {
                display: flex;
                align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 50px;
                row-gap: 30px;
                margin-bottom: 65px;

                .point {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    &__img {
                        margin-bottom: 14px;
                        min-width: 38px;
                    }

                    &__title {
                        margin-bottom: 6px;
                        font-size: 20px;
                        font-weight: 500;
                        color: $color-dark;
                    }

                    &__subtitle {
                        color: $color-davy-grey;
                        font-size: 14px;
                        font-weight: 500;
                        width: min(326px, 100%);

                        .link {
                            text-decoration: underline;
                        }

                        span {
                            color: $color-secondary;
                        }
                    }

                    &.grid-last-child {
                        @media (min-width: 601px) and (max-width: 1000px) {
                            margin: auto;
                            grid-column: span 2;
                        }
                    }
                }

                @media (max-width: 1000px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto auto;
                }

                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                }
            }

            &__img {
                text-align: center;

                img {
                    width: min(674px, 100%);
                }
            }
        }
    }
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-18 {
    margin-bottom: 18px;
}

.mb-8 {
    margin-bottom: 8px;
}

.border-right {
    border-right: 1px solid $color-body-text-1;

    @media (max-width: 500px) {
        border-right: none;
    }
}

.pl-52 {
    padding-left: 52px;

    @media (max-width: 500px) {
        padding-left: 0;
    }
}

.pr-52 {
    padding-right: 52px;

    @media (max-width: 500px) {
        padding-right: 0;
    }
}

.mt-50 {
    margin-top: 50px;
    @media (max-width: 600px) {
       margin-top:20px;
    }
}

.color-secondary {
    color: $color-secondary !important;
}

.order-table__header {
    .item {
        &__name {
            width: 230px;
        }
    }
}

.discount-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EDFFF0;
    border-radius: 30px;
gap:4px;
padding: 5px 10px 5px 5px;
svg{
    width: 14px;
    height: 14px;

}
    p {
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        color: #179B2D;

    }
}