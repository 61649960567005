.add-device-modal {
    .spinner-circle{
        width: 140px !important;
        border-radius: 30px;
	}
    .add-tracker-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin:5px 0px 15px;
        padding: 0 15px;
        @media screen and (max-width:575px) {
            padding: 0 5px;
        }
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 22.4px;
            color: $color-order-total;
        }

        .btn {
            padding: 0;
            min-width: unset;
            height: auto;
            box-shadow: none;
            background-color: transparent;
            width: unset;
        }
    }

    .edit-device-body {
        padding: 25px;
        margin: 0px 0px 15px;
        border-radius: 20px;
        background-color: $color-light;
        @media screen and (max-width:575px) {
            padding: 25px 10px;
        }
        .MuiFormControl-root {
            width: 50%;
            @media screen and (max-width:575px) {
                width: 100%;
            }
            .form-textfield {
                width: 97%;
                @media screen and (max-width:575px) {
                    width: 100%;
                }
            }
        }

        .map-img {
            width: 100%;
            background-size: 100%;
            height: 87px;
            border-radius: 9px;
        }

        .device-icon-selection-box {
            margin-top: 15px;
        }

        .stepper-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0;
            margin-bottom: 12px;

            p {
                margin-bottom: 0;
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                line-height: 22.4px;
                color: $color-order-total;
            }

            .step-counter {
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background: $color-body-text-1;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;

                span {
                    color: $color-light;
                    font-size: 14px;
                    text-align: center;
                }

                &.active {
                    background: $color-secondary;

                }
            }
        }
    }

    .add-devices-payment {
        padding: 25px;
        border-radius: 20px;
        background-color: $color-light;
        @media screen and (max-width:575px) {
            padding: 25px 10px;
        }
        .stepper-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0;

            p {
                margin-bottom: 0;
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                line-height: 22.4px;
                color: $color-order-total;
            }

            .step-counter {
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background: $color-body-text-1;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;

                span {
                    color: $color-light;
                    font-size: 14px;
                    text-align: center;
                }

                &.active {
                    background: $color-secondary;

                }
            }
        }

        .plans-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid rgba($border-color, 0.4);
            padding-top: 15px;
            border-radius: 0;
            width: 100%;
            flex-wrap: wrap;
            margin-top: 15px;
            gap: 10px;
            @media screen and (max-width:575px) {
                flex-direction: column-reverse;
                justify-content: center;
                .MuiButton-root{
                    width:90% !important;
                            }
            }
            .btn {
                padding: 12px 25px;
                min-width: unset;
                height: auto;
                box-shadow: none;
                font-size: 14px;
                font-weight: 500;
            }

            .add-bulk-btn {
                color: $color-body-text-1;
                background-color: transparent;
                border: 1px solid $color-body-text-1;
            }

            .proceed-btn {
                color: $color-light;
                background-color: $color-secondary;
                border: 1px solid $color-secondary;

            }
        }
    }
}