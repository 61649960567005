.checkout-page {
    padding: 50px 0px 0;

    @media screen and (max-width:600px) {
        padding: 30px 0px 0;
    }

    .checkout-page-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 18px;
        align-items: center;
        margin-bottom: 35px;

        @media screen and (max-width:585px) {
            margin-bottom: 20px;
        }
    }

    & .checkout-page-login {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: $color-dark;
        width: fit-content;

        & a {
            color: $color-secondary;
            text-decoration-line: underline;
        }
    }

    h1 {
        @include fontStyles($color-dark, 25px, 130%);


        @media screen and (max-width:585px) {

            @include fontStyles($color-dark, 18px, 130%);
        }
    }

    .checkout-page-container {
        padding-bottom: 50px;

        .shipping-address-form {
            padding: 0;
            background-color: $color-light;
            border-radius: 8px;



            .MuiFormControlLabel-root {
                margin-top: -3px;
                margin-left: 0px;
                margin-bottom: 5px;
                width: fit-content;

                .MuiFormControlLabel-label {
                    @include fontStyles(#172434, 14px);
                    margin-top: 2px;
                }
            }
        }

        .address-inner-grid {
            padding: 0 25px 0 11px;

            @media screen and (max-width:585px) {
                padding: 0px;
            }
        }

        .form-tabs {
            background-color: $color-light;
            border-radius: 8px;
            padding: 25px 0;
            margin-bottom: 25px;

            @media screen and (max-width:585px) {
                padding: 20px 0px;
            }

            .MuiPaper-root {
                border-radius: 0 !important;
                background-color: transparent;
                box-shadow: none;

                .MuiAccordionDetails-root {
                    padding: 15px 0px 0;
                    margin-top: 15px;
                    border-top: 1px solid rgba(170, 182, 188, 0.4);

                    .address-inner-grid {
                        padding: 0 25px;

                        @media screen and (max-width:585px) {
                            padding: 0px 15px;
                        }
                    }

                    .checkbox {
                        border-top: 1px solid rgba(170, 182, 188, 0.4);
                        padding-top: 15px;
                        margin-top: 15px;
                        padding-left: 25px;

                        .MuiTypography-root {
                            margin-left: 10px;
                        }

                        @media screen and (max-width:585px) {
                            padding-left: 15px;
                        }
                    }
                }

                .MuiAccordionSummary-expandIconWrapper {
                    margin-left: 7px;
                    width: 14px;
                    height: 12px;

                    &.Mui-expanded {
                        margin-top: -7px
                    }

                }

                .MuiAccordionSummary-root {
                    padding: 0;
                    min-height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                }

                .MuiAccordionSummary-content {
                    margin: 0;
                }

                .MuiAccordionDetails-root {

                    @media screen and (max-width:425px) {
                        padding: 8px;
                    }
                }
            }

            &.orders-tab {
                background-color: transparent;
                margin-bottom: 0;
                padding: 0;

            }

            .MuiGrid-item {
                padding-top: 0;
            }

            .address-form {
                padding: 25px 3px 0;

                .phone-number-form {
                    margin-top: 5px;

                    @media screen and (max-width:1200px) {
                        margin-top: 0;
                    }
                }
            }

            .address-form-header {
                @include displayFlex($justifyContent: space-between);
                width: 100%;
                padding: 0 25px;

                @media screen and (max-width:585px) {
                    padding: 0 15px;
                }

                .add-address-btn {
                    @include displayFlex($justifyContent: space-between);
                    padding: 0;
                    background: none;
                    border: none;
                    height: auto;
                    margin-bottom: 5px;

                    @media screen and (max-width:585px) {
                        svg {
                            height: 10px;
                            width: 10px;
                        }
                    }

                    span {
                        @include fontStyles($color-secondary, 14px, 150%);
                        margin-left: 10px;

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-secondary, 12px, 150%);
                            margin-left: 5px;

                        }
                    }
                }
            }

            .device-info-message {
                @include displayFlex(row, flex-start);
                margin: 20px 0;
                background-color: rgba(200, 225, 255, 0.32);
                padding: 15px;
                border-radius: 15px;

                p {
                    color: $color-body-text-2;
                    font-size: 12px;
                    font-style: normal;
                    max-width: 100%;
                    font-weight: $font-weight-medium;
                    line-height: 18px;

                    span {
                        color: $color-secondary;
                    }
                }
            }

            .form-title {


                @include fontStyles($color-text-secondary, 18px);

                @media screen and (max-width:585px) {
                    @include fontStyles($color-text-secondary, 14px, 150%);

                }
            }

            .selected-plan {
                @include fontStyles($color-body-text-1, 14px, 21.79px);
                font-style: italic;

                span {
                    @include fontStyles($color-secondary, 14px, 21.79px);
                }
            }

            .form-subtitle {
                @include fontStyles($color-body-text-2, 12px);
                display: block;
                margin-top: 5px;

                @media screen and (max-width:585px) {
                    @include fontStyles($color-body-text-2, 12px, 150%);
                }
            }



            .save-address-btn {
                width: 100%;
                padding-right: 25px;

                @media screen and (max-width:585px) {
                    padding-right: 15px;
                }

                @include displayFlex($justifyContent: flex-end, $alignItems: flex-end);

                .btn {
                    box-shadow: none;
                    @include fontStyles($color-footer-link, 14px, 150%);
                    background: $color-secondary;
                    padding: 10px 20px;
                    height: auto;
                    transition: none;
                    min-height: unset;
                    box-shadow: none;
                    height: auto;
                    border: none;
                    border-radius: 8px;
                }

                .cancel-btn {
                    box-shadow: none;
                    @include fontStyles($color-body-text-1, 14px, 150%);
                    background: transparent;
                    padding: 10px 20px;
                    height: auto;
                    transition: none;
                    min-height: unset;
                    box-shadow: none;
                    border: none;
                }
            }

        }

        .form-btn {
            width: 100%;
            margin: 20px 0px;
            @include displayFlex($justifyContent: flex-end, $alignItems: flex-end);

            .shopping-btn {
                box-shadow: none;
                @include fontStyles($color-footer-link, 14px, 150%);
                background: $color-secondary;
                padding: 12px 25px;
                height: auto;
                transition: none;
                min-height: unset;
                border-radius: 8px;
                min-width: unset;
                width: fit-content
            }
        }

        .order-summary-box {
            border-radius: 8px;
            background: $color-light;
            position: sticky;
            top: 30px;
            padding: 25px 0;
            margin-bottom: 25px;

            @media screen and (max-width:991px) {
                margin-bottom: 0;
            }

            @media screen and (max-width:585px) {
                padding: 20px 0px;
            }

            .main-title {
                padding: 0 25px;
                @include fontStyles($color-text-secondary, 18px);

                @media screen and (max-width:585px) {
                    padding: 0px 15px;
                }
            }

            .announcement-section {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #F6F8FA;
                border-radius: 8px;
                gap: 12px;
                margin: 5px 25px 25px 25px;
                padding: 10px;

                @media screen and (max-width:585px) {
                    margin: 0 15px 15px 15px;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 154%;
                    color: #737A84;
                    flex: 1;

                    @media screen and (max-width:600px) {
                        font-size: 12px;
                    }
                }

                span {
                    color: $color-secondary;
                }

            }

            .pricing-section {
                border-top: 1px solid rgba($border-color, 0.4);
                border-bottom: 1px solid rgba($border-color, 0.4);
                padding: 15px 25px 0;
                margin: 0 0 20px 0px;
                width: 100%;

                @media screen and (max-width:585px) {
                    padding: 15px 15px 0px;
                    margin: 15px 0;
                }

                .inner-section {
                    width: 100%;
                    @include displayFlex($justifyContent: space-between);
                    margin-bottom: 15px;

                    .discount-section {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .discount-copy {
                            font-style: italic !important;
                            font-weight: 500 !important;
                            font-size: 10px !important;
                            line-height: normal !important;
                            color: #8899B4 !important;
                        }
                    }

                    .title {
                        @include fontStyles($color-body-text-1, 16px, 150%);

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-body-text-1, 14px, 150%);
                        }
                    }

                    .detail {
                        @include fontStyles($color-dark, 16px, 150%);

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-dark, 12px, 150%);
                        }

                        &.discount-tag {
                            color: #27AE60;
                            background-color: transparent;
                            padding:0;

                        }
                    }
                }
            }

            .total-section {
                padding: 0 25px;
                width: 100%;
                @include displayFlex($justifyContent: space-between);

                .inner-section {
                    width: 100%;
                    @include displayFlex($justifyContent: space-between);




                    .title {
                        @include fontStyles($color-dark, 18px, 150%, 600);

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-dark, 14px, 150%, 600);
                        }
                    }

                    .detail {
                        @include fontStyles($color-dark, 20px, 150%, 600);

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-dark, 14px, 150%, 600);
                        }
                    }
                }
            }

            .order-summary-footer {
                padding: 0 25px;

                .promo-code-section {

                    .promo-code-textfield {
                        @include displayFlex($alignItems: flex-end);
                        flex-wrap: nowrap;

                        .form-textfield {
                            width: 80%;
                            margin-bottom: 0;

                            input {
                                border-radius: 10px 0px 0px 10px;
                            }
                        }

                        .apply-btn {
                            width: 20%;
                            height: auto;
                            flex: 1;
                            background-color: $color-secondary;
                            border-radius: 0 10px 10px 0px;
                            @include fontStyles($color-footer-link, 14px);
                            padding: 12px;
                            box-shadow: none;
                        }
                    }
                }

                .secure-pay-btn {
                    width: 100%;
                    height: auto;
                    margin: 15px 0;
                    background-color: $color-secondary;
                    border-radius: 8px;
                    @include fontStyles($color-footer-link, 14px);
                    padding: 16px 25px;
                    box-shadow: none;

                    svg {
                        margin-right: 8px;
                    }
                }

                .privacy-text {
                    @include fontStyles($placeholder-color, 14px, normal);
                    margin-bottom: 15px;

                    @media screen and (max-width:585px) {
                        @include fontStyles($placeholder-color, 12px, normal);
                    }

                    .link {
                        text-decoration: none;
                        color: $color-secondary;
                    }
                }
            }
        }

        .order-review {
            padding: 25px 25px 15px;
            border-top: 1px solid rgba(170, 182, 188, 0.4);
            margin-top: 15px;

            .order-section-one {
                @include displayFlex($justifyContent: space-between, $alignItems: flex-start);
                margin-bottom: 25px;
                position: relative;

                .price-action {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                    height: 84px;
                }

                .product-details {
                    @include displayFlex();
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex: 1;

                    @media screen and (max-width:820px) {
                        max-width: 280px;
                    }

                    @media screen and (max-width:767px) {
                        max-width: 340px;
                    }

                    @media screen and (max-width:700px) {
                        @include displayFlex(row, flex-start, flex-start);
                        max-width: 393px;
                    }

                    .img-section {
                        background-color: $form-textfield-bg;
                        padding: 10px 20px 5px;
                        border-radius: 8px;
                        margin-right: 15px;

                        img {
                            height: 66px;
                            width: 67px;
                            object-fit: contain;

                            @media screen and (max-width:767px) {
                                height: 65px;
                                width: 50px;
                            }
                        }
                    }

                    .product-name {
                        @include displayFlex(column, flex-start, flex-start);
                        max-width: 185px;
                        margin-bottom: 7px;

                        @media screen and (max-width:1800px) {
                            max-width: 130px;
                        }

                        @media screen and (max-width:1300px) {
                            max-width: 120px;
                        }

                        @media screen and (max-width:1200px) {
                            max-width: 200px;
                        }

                        @media screen and (max-width:820px) {
                            max-width: 153px;
                        }

                        @media screen and (max-width:767px) {
                            max-width: 180px;
                        }

                        @media screen and (max-width:700px) {
                            max-width: 255px;
                        }

                        @media screen and (max-width:485px) {
                            max-width: 160px;
                        }

                        @media screen and (max-width:375px) {
                            max-width: 135px;
                        }

                        span {
                            @include fontStyles();

                            @media screen and (max-width:375px) {
                                font-size: 12px;
                            }
                        }

                        p {
                            @include fontStyles($color-dark, 14px);
                            margin-top: 5px;
                            font-weight: $font-weight-semibold;

                            @media screen and (max-width:700px) {
                                @include fontStyles($color-dark, 12px);
                            }

                            span {
                                font-weight: $font-weight-medium;
                                @include fontStyles($color-body-text-2, 14px);
                                text-transform: capitalize;

                                @media screen and (max-width:700px) {
                                    @include fontStyles($color-body-text-2, 12px);
                                }
                            }

                        }

                    }
                }

                .trash-btn {
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    width: auto;
                    min-width: auto;
                    height: auto;

                    @media screen and (max-width:700px) {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }

                .item-counter {
                    svg{
                        width: 11px;
                        height:11px;
                    }
                    @include displayFlex();

                    width: fit-content;
                    margin-top: 15px;
                    border-radius: 8px;
                    border: 0.5px solid #8899B4;
                    padding: 6px 8px;

                    @media screen and (max-width:700px) {
                        position: absolute;
                        bottom: 0;
                        left: 108px;
                    }



                    .btn {
                        padding: 0;
                        background: none;
                        box-shadow: none;
                        height: auto;
                        min-width: auto;
                        transition: none;

                    }

                    .form-textfield {
                        margin-bottom: 0;
                        background: none;
                        border: none;
                        width: fit-content;
                        background-color: transparent;

                        .MuiInputBase-root {
                            background-color: transparent;
                        }

                        input {
                            width: 25px;
                            max-width: 35px;
                            text-align: center;
                            margin-bottom: 0;
                            background: none;
                            border: none;
                            padding: 0;
                            width: fit-content;
                            border: 0;
                            border-radius: 0px;
                            color: #021021 !important;
                            margin: 0 2px;
                            background-color: transparent !important;

                            @media screen and (max-width:700px) {

                                max-width: 27px;
                            }
                        }
                    }
                }

                .product-total-price-section {
                    @include displayFlex(column, flex-end, flex-end);

                    @media screen and (max-width:700px) {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        @include displayFlex(column, flex-end, flex-end);
                    }

                    .qty-price {
                        font-size: 12px;
                        color: #8899B4;
                        font-weight: 500;
                    }

                    .price-with-discount {
                        display: flex;
                        gap: 5px;
                        flex-direction: column;
                        align-items: flex-end;

                    }

                    .discounted-price {
                        text-decoration: line-through;
                        @include fontStyles($color-red, 12px, normal);
                    }

                    .total {
                        @include fontStyles($color-dark, 16px, normal, 600);
                    }
                }
            }

            .order-review-footer {
                @include displayFlex(column, space-between);
                width: 100%;
                padding: 25px;
                margin-top: 20px;
                background-color: $color-light;
                border-radius: 8px;


                .subtotal {
                    @include displayFlex(row, space-between);
                    width: 100%;

                    .title {
                        @include fontStyles($color-body-text-1, 18px, 150%);

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-body-text-1, 14px, 150%);
                        }
                    }

                    .total-price {
                        @include fontStyles($color-dark, 22px, 150%, 600);

                        @media screen and (max-width:991px) {
                            @include fontStyles($color-dark, 16px, 150%);
                        }

                        @media screen and (max-width:585px) {
                            @include fontStyles($color-dark, 12px, 150%);
                        }
                    }
                }
            }
        }

        .order-form {

            background-color: $color-light;

            border-bottom: 1px solid rgba($border-color, 0.4);


            &.subscription-form {

                border-bottom: 0;
                padding-top: 20px;

                @media screen and (max-width:700px) {

                    position: relative;
                }

                @media screen and (max-width:633px) {
                    height: auto;
                    position: unset;
                }

                .order-section-one {
                    margin: 0;

                    .img-section {
                        padding: 0;
                        background-color: transparent;

                        img {
                            height: auto;
                            width: auto;

                            @media screen and (max-width:425px) {
                                height: 47px;

                            }
                        }
                    }

                }

                .sub-text {

                    font-style: italic !important;
                    font-weight: 500 !important;
                    font-size: 10px !important;
                    line-height: normal !important;
                    color: #8899B4 !important;
                }

                .subscription-plan {
                    min-width: 109px;
                    padding: 0 10px;
                    border: 1px solid #8899B4;
                    border-radius: 8px;
                    background-color: #ffffff;
                    height: 35px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    max-width: 112px;
                    position: relative;


                    &__dropdown {
                        position: absolute;
                        top: 45px;
                        left: 0;
                        width: 100%;
                        background-color: #ffffff;
                        border: 1px solid #8899B4;
                        border-radius: 8px;
                        padding: 10px 15px 0;
z-index: 1;
                        .active-item {

                            p {
                                color: $color-secondary !important;
                            }
                        }

                        p {
                            color: #021A37 !important;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 140%;
                            margin-bottom: 15px;
                            cursor: pointer;
                        }
                    }

                    button {
                        height: auto;
                        padding: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                        width: 100%;
                        background: transparent;

                        p {
                            color: #021A37 !important;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 140%;

                        }
                    }
                }
            }
        }



        .payment-form {
            border-top: 1px solid rgba($border-color, 0.4);
            border-bottom: 1px solid rgba($border-color, 0.4);

            .payment-form-inner {
                padding: 0 25px 25px;

                .payment-method-detail {
                    @include displayFlex(row, space-between);
                    width: 100%;

                    .detail-body {
                        @include displayFlex();
                    }

                    .method-name {
                        margin-left: 15px;
                        @include displayFlex(column, flex-start, flex-start);

                        p {
                            @include fontStyles($fontSize: 16px, $lineHeight: normal);
                            margin-bottom: 4px;
                        }

                        span {
                            @include fontStyles($color-body-text-2, 12px, 150%);
                        }
                    }
                }
            }
        }

    }
}

.error-msg {
    @include fontStyles($color-red);
}

.account-linkage {

    @include displayFlex(column);
    width: 100%;

    .account-linkage-form {
        padding: 35px 25px 0px;
        width: 100%;
        margin-top: 15px;
        border-top: 1px solid rgba($border-color, 0.4);

        @media screen and (max-width:585px) {
            padding: 25px 15px 0px;
        }
    }

    .save-btn {
        border-top: 1px solid $color-body-text-3;
        padding: 20px 25px;
        width: 100%;
        @include displayFlex($justifyContent: flex-end, $alignItems: flex-end);

        .btn {
            box-shadow: none;
            @include fontStyles($color-footer-link, 14px, 150%);
            background: $color-secondary;
            padding: 10px 20px;
            height: auto;
            transition: none;
            min-height: unset;
        }
    }
}

.no-subscription-section {

    padding: 0 0 20px 0px;
    margin: 0px 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    @media screen and (max-width:600px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 15px;
        gap: 15px;
    }

    .susbcription-header {
        display: flex;
        flex: 1;
        flex-direction: column;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            font-style: normal;
            color: #021021;

            @media screen and (max-width:600px) {
                font-size: 12px;
            }
        }

        span {
            font-style: italic !important;
            font-weight: 500 !important;
            font-size: 10px !important;
            line-height: normal !important;
            color: #8899B4 !important;

            @media screen and (max-width:600px) {
                max-width: 100%;
            }
        }
    }

    button {
        border-radius: 8px;
        background-color: $color-secondary !important;
        display: flex;
        flex-direction: row;
        box-shadow: none;
        align-items: center;
        gap: 5px;
        font-size: 13px;
        color: $color-light;

        span {
            max-width: auto;

        }
    }
}
.coupon-code-error-section{
    display: flex;
    flex-direction: column;
    gap:4px;
    margin-bottom: 10px;;
    align-items: flex-start;
    .error-code{
        font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #F13737;
    }
}
.coupon-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F6FB;
    padding: 3px 3px 3px 18px;
    gap: 10px;
    margin: 0 0 15px 0;
    border-radius: 8px;
width: 100%;
    .coupon-textfield {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        background: none;
        outline: none;
        border: none;
        .MuiInputBase-root{
            &::before,&::after{
                content: none;
            }
        }
        input {
            background: none;
            outline: none;
            border: none;
            background-color: #F4F6FB;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            font-family: "Montserrat", sans-serif;
@media screen and (max-width:600px) {
    font-size: 12px;
}
            &::placeholder {
                color: #A0B0D0;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
                font-family: "Montserrat", sans-serif;
                @media screen and (max-width:600px) {
                    font-size: 12px;
                }
            }
        }

    }

    button {
        background-color: #ECEEF4;
        padding: 10px 18px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #021021;
        border-radius: 8px;
        box-shadow: none;
        @media screen and (max-width:600px) {
            font-size: 12px;
        }
    }

    &.success {
        border: 1px solid #179B2D;
        background-color: #F8FFF9;
        padding: 5px 15px;
        @media screen and (max-width:600px) {
            padding: 5px 10px;
        }
        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            flex:1;

            p {

                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: #179B2D;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                @media screen and (max-width:600px) {
                    font-size: 12px;
                    gap:4px;
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    color: #172434;
                    @media screen and (max-width:600px) {
                        font-size: 12px;
                    }
                }
            }

            .discount {
                font-style: italic;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                color: #8899B4;
                @media screen and (max-width:600px) {
                    font-size: 12px;
                }

            }
        }

        button {
            border: none;
            outline: none;
            color: #F13737;
            background: none;
            padding: 0;
            box-shadow:none;

        }
    }
    &.error {
        border: 1px solid #F13737;
        background-color: #FEF6F6;
        
        margin-bottom: 0;
        padding: 5px 15px;
        @media screen and (max-width:600px) {
            padding: 5px 10px;
        }
        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
flex:1;
            p {

                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: #F13737;
                @media screen and (max-width:600px) {
                    font-size: 12px;
                }
            }
        }
button{
    border: none;
            outline: none;
            color: #F13737;
            background: none;
            padding: 0;
            box-shadow:none;

}
         
    }
}