.create-geofence{
    .geofence-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        .back-btn{
            padding: 0;
            margin-right: 10px;
            min-width: auto;
            height: unset;
        }
        p{
            color:$color-text-secondary;
            font-weight: $font-weight-medium;
            line-height: 24.52px;
            font-size: 18px;
        }
    }
    .geofence-body{
        margin-top:20px;
        // height:69vh;
        //     overflow: hidden;
        //    overflow-y: auto;
            position: relative;
            
        .location-info-msg {
            @include displayFlex(row, flex-start);
            margin: 0 0 10px 0;
            background-color: rgba(200, 225, 255, 0.32);
            padding: 15px;
            border-radius: 15px;

            p {
                color: $color-body-text-2;
                font-size: 12px;
                font-style: normal;
                max-width: 100%;
                font-weight: $font-weight-medium;
                line-height: 18px;
            }
        }
        
        .form-location-field{
          position: relative;
            
            button{
            height: auto;
            min-width: unset;
            padding: 0;
            background-color: $form-textfield-bg;
            position: absolute;
            right: 10px;
            bottom: 25px;
            }
               input{
                margin-right: 25px;
               }
        }
        .radius-field{
            position: relative;
            .unit{
                position: absolute;
                bottom: 15px;
                right: 0;
                background-color: #E8EDF2;
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: #4F5661;
                padding: 11px;
                border-radius: 0 10px 10px 0;
            }
        }
        label{
            color: #021021;
            font-size: 13px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
            font-family: inherit;
            letter-spacing: 0.00938em;
            display: flex;
            align-items: center;
            flex-direction:row;
            margin: 0;
            width: 48%;
            span{
                margin-right: 5px;
            }  
        }
        .select-shape{
        position: relative;
        margin-bottom: 15px;
        .reset-btn{
            position: absolute;
    bottom: 8px;
    height: auto;
    right: 0px;
    font-size: 12px;
    color:#8899B4;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
        }
            .MuiFormControl-root{
                width: 100%;
                .MuiFormGroup-root{
                    width: 80%;
                    justify-content: space-between;
                }
            }
            .MuiFormLabel-root{
                color: #021021;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10px;
                font-family: inherit;
                letter-spacing: 0.00938em;
            }
            .MuiRadio-root{
                visibility: hidden;
                padding:0;
                height: auto;
                width: 0;
            }
            .shape-radiobox{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #F8F8F8;
                border-radius: 8px;
                height: 46px;
                padding-left: 15px;
                margin-left: 0;
            }
            .selected-shape-radiobox{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: rgba(26, 116, 226, 0.10);
                border-radius: 8px;
                height: 46px;
                padding-left: 15px;
                margin-left: 0;
                .MuiFormControlLabel-label{
                    color:$color-secondary;
                }
                svg{
                    path{
                        fill:$color-secondary;
                       
                    }
                    circle{
                        stroke: $color-secondary;
                    }
                   
                }
            }
            .Mui-checked{
                color:$color-secondary;
            }
            .MuiFormControlLabel-label{
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                >div{
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 8px;
                    }
                }
            }
        }
        .geofence-accordion{
            margin-bottom: 15px;
            .MuiPaper-root{
                box-shadow: none;
                margin-top:8px;
                background-color: transparent;
                .MuiButtonBase-root {
                    padding:0;
                    margin:0;
                    height: auto;
                    min-height: unset;
                    .MuiAccordionSummary-content{
                        padding:0;
                        margin: 0;
                        border-radius: 10px;
                        color: #a0b0d08f  !important;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 140%;
                        background-color: $form-textfield-bg;
                        padding:12px;
                    }
                }
                .MuiAccordionSummary-expandIconWrapper{
                    transition: none;
                    svg{
                        position: absolute;
                        right:15px;
                    }
                    &.Mui-expanded{
                        transition: none;
                        svg{
                            right: -25px;
                            top:-3px;
                        }
                    }
                }
                .MuiAccordionDetails-root{
                    background-color: $form-textfield-bg;
                    padding:10px 12px;
                    border-radius: 10px;
                    margin-top:8px;
                    // position: absolute;
                    z-index:2;
                    width: 100%;
                    .search-input{
                        border: 1px solid $color-body-text-3;
                        background-color: #F8F8F8;
                        border-radius: 30px;
                        flex:1;
                        padding:10px 10px 10px 3px;
                        position:relative;
                        .MuiInputBase-root{
                            width:100%;
                        }
                        @include displayFlex($justifyContent:space-between);
                        input{
                            width:122px;
                            @include fontStyles($fontSize:12px,$lineHeight:normal);
                            padding-left: 6px;
                            padding-bottom: 0;
                            flex:1;
                            @media screen and (max-width:360px) {
                                width:85px;
                            }
                        }
                        ::placeholder{
                            color:$placeholder-color;
                            
                            font-size: 12px;
                        }
                       
        
                    }
                    .view-all-btn{
                        color:$color-secondary;
                        font-size: 14px;
                        line-height: normal;
                        width: 100%;
                        text-align: center;
                        margin-top: 5px;
                        box-shadow: none;
                        background-color: transparent;
                        height: auto;
                    }
                    .MuiTypography-root {
                        flex:1;
                    }
                    .geofence-group-listing{
                        max-height: 105px;
                        overflow-y:auto ;
                    }
                    .arrow{
                        padding:0;
                        height: auto;
                        width: unset;
                        box-shadow: none;
                        min-width: unset;
                    }
                   
                    .MuiFormControlLabel-root {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        margin: 10px 0;
                        padding: 10px 0;
                        border-bottom: 1px solid #C8D1E8;
                    }
                    .group-names-details{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        .group-name{
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            &.tracker-name{
                                align-items: center;
                            }
                        }
                        img{
                            width:33px;
                            height: 33px;

                        }
                        .group-details{
                            margin-left: 8px;
                            p{
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 150%;
                                color: #172434; 
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 135px;
                            }
                            span{
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 150%;
                                color: #8899B4;  
                            }
                        }
                    }
                }
            }
        }
    }
    .geofence-footer{
        padding:10px 0 0 ;
        display: flex;
        width: 100%;
        // position: absolute;
        // bottom: 0;
        justify-content: space-between;
        align-items: center;
        // box-shadow: 0px 0px 40px -5px rgba(1, 11, 35, 0.2);
        background-color: #ffffff;
        
        .cancel-btn{
            color:#8899B4;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            padding:0;
            height: auto;
            box-shadow: none;
        }
        .create-geofence{
           color:#ffffff;
           background-color: $color-secondary;
           font-size: 14px;
           border-radius: 30px;
           padding:12px 25px;
           height: auto;
           font-weight: 500;
           box-shadow: none;
        }
    }
}
.single-selection{
    color:#021A37 ;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    .details{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right:5px;
    }
    img{
           width: 27px;
    height: 27px;
    margin-right: 2px;
    }
     
}
.location-drag-msg{
    position: absolute;
    top: 25px;
    left: 40%;
background-color: $color-light;
 
max-width: 80%;;
display: flex;
justify-content: center;
align-items: center;
border:1px solid rgba(189, 201, 226, 0.63);
padding:10px 20px;
border-radius: 10px;
z-index:2;
@media screen and (max-width:600px ){
    left: 15px;
}
p{
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #4F5661;
}

}
.geofence-detail-popup{
    min-height: 45vh;
    overflow-y: auto;
    max-height: 93vh;
    @media screen and (max-width:600px ){
        max-height: 65vh;
    }
}
