.track-slide {
    padding: 15px 19px;
    border-radius: 25px;
    min-width: calc(50% - 17px);

    display: flex;
    align-items: center;
    gap: 3.6rem;
    transition: all 0.3s ease;

    &__img-container {
        width: 313px;
        height: 100%;
        &__image {
            min-width: 100%;
            height: 100%;
            // max-height:270px;
            border-radius: 20px;
            object-fit: contain;
        }
    }

    &__content {
        .title {
            font-size: 2rem;
            font-weight: 500;
            line-height: normal;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .desc {
            color: #4F5661;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            a{
                color: $color-secondary;
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 1400px) {
        justify-content: flex-start;
        align-items: flex-start;

        &__img-container { 
            &__image {
                width: 200px;
            }
        }
    }

    @media (max-width: 1280px) {
        min-width: 65%;
        align-items: center;

        &__img-container { 
            &__image {
                width: auto;
            }
        }
    }

    @media (max-width: 1100px) {
        min-width: 100%;
    }

    @media (max-width: 752px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        &__img-container { 
            width: 100%;
            height: 290px;

            &__image {
                width: 100%;
                height: 100%;
                border-radius: 15px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}