.cancel-subscription-plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding:25px;
    @media screen and (max-width:585px) {
        padding: 15px;
    }
    img{
        margin-bottom: 25px;
    }
    p{
        @include fontStyles($color-text-secondary, 20px, 150%);
        margin-bottom: 15px;
        text-align: center;
        padding:0 15px;
        @media screen and (max-width:585px) {
            padding: 0;
        }
    }
    span{
        @include fontStyles($color-body-text-1,14px,150%);
        text-align: center;
        margin-bottom: 15px;
        padding:0 15px;
        @media screen and (max-width:585px) {
            padding: 0;
        }
    }
    button{
        transition: none;
        box-shadow: none;
        background-color: $color-secondary !important;
        color: $color-light;
        font-size: 14px;
        font-weight: 500;
        min-width: unset;
        width: auto;
        padding: 8px 25px !important;
        height: auto;
        margin-bottom: 12px;
        border-radius: 8px;
        margin-top: 5px;
    }
    .cross-btn{
        background-color: transparent !important;
        padding:0 !important;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width:100%;
        img{
            margin:0;
        }
    }
    .cancel-btn{
        background-color: $color-light !important;
        color:$color-secondary !important;
        margin-bottom: 12px;
        border:1px solid $color-secondary;
    }
}