.alert-msg{
  background-color: $color-light;
  border-radius: 20px;
  bottom: unset;
  padding-right: 15px;
  top: 6%;
  min-width: 246px;
  .MuiAlert-icon{
    display: none;
  }
  .MuiAlert-message{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #021021;
  }
  .inner-content{
    margin-left: 2px;
  }
  @media screen and (max-width:575px) {
    top:7%;
  }
  .MuiAlert-root{
    width: 100%;
  }
  &::before{
    position: absolute;
    top:10px;
    left: 0;
    content: " ";
    border-radius: 2px;
    width: 4px;
    height: 65%;
    background-color:#8899B4 ;
  }
  & svg{
    color:#8899B4;
  }
  .header{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #021021;
    max-width: 417px;
    @media screen and (max-width:575px) {
      max-width: unset;
    }
  }
  .detail{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    max-width: 417px;
    color: #8899B4;
    display: inline-block;
    text-align: left;
    @media screen and (max-width:575px) {
      max-width: unset;
    }
  }
}
.MuiSnackbar-root:has(.MuiAlert-filledSuccess){
  background-color: $color-light !important;
  &::before{
    background-color:#179B2D ;
  }
  & svg {
    color: #179B2D !important;
  }
  .MuiAlert-message{
     
    color: #021021;
  }
}
.MuiSnackbar-root:has(.MuiAlert-filledInfo){
  background-color: $color-light !important;
  &::before{
    background-color:#3A70E2 ;
  }
  & svg {
    color: #3A70E2 !important;
  }
  .MuiAlert-message{
     
    color: #021021;
  }
}
.MuiSnackbar-root:has(.MuiAlert-filledError){
  background-color: $color-light !important;
  &::before{
    background-color:#F13737 ;
  }
  & svg {
    color: #F13737 !important;
  }
  .MuiAlert-message{
     
    color: #021021;
  }
}
.MuiSnackbar-root:has(.MuiAlert-filledWarning){
  background-color: $color-light !important;
  &::before{
    background-color:#FF8F00 ;
  }
  & svg {
    color: #FF8F00 !important;
  }
  .MuiAlert-message{
     
    color: #021021;
  }
}
.MuiAlert-filled {
  background-color: unset;
  padding: 0px 0px;
  margin-top: 10px;
  color: #FFFFFF;
}


.MuiAlert-root {
  display: flex;
  align-items: center;
}
