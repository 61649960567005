.MuiTable-root {
  border-collapse: separate;
  border-spacing: 0 10px;

  .MuiTableHead-root .MuiTableCell-root {
    color: white;

    &:first-child {
      padding-left: 80px;
    }

    &:last-child {
      padding-right: 80px;
    }
  }

  .MuiTableCell-root {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:not(.MuiTableHead-root .MuiTableCell-root) {
      border-top: 1px solid rgba($color-border, 0.4);
      border-bottom: 1px solid rgba($color-border, 0.4);

      &:first-child {
        border-left: 1px solid rgba($color-border, 0.4);
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
        margin-left: 20px;
        left: 60px;
        position: relative;
      }

      &:last-child {
        border-right: 1px solid rgba($color-border, 0.4);
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        right: 60px;
        position: relative;
      }
    }
  }

  .MuiTableRow-root:not(.MuiTableHead-root .MuiTableRow-root) {
    background: white;
  }


}