.notifications-page {
  &__filters {
    padding: 20px 44px;
    margin-bottom: 15px;
     background-color: $color-light;
     border-radius: 20px;
    @media screen and (max-width: 1024px) {
      padding: 20px;
    }
      .btn{
      height: auto;
      padding:8px 15px;
      font-size: 14px;
      }
      .form-textfield{
        margin-bottom: 0;
        input{
          background-color: #F8F8F8 !important;
          padding:15px;
        }
      }
    .report-filters {
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 30px;
       
        justify-content: center;
      }
      .MuiInputBase-root:has(> .MuiSelect-select) 
      {
        border:none;
        .MuiInputBase-input{
          padding: 15px 23px 15px 18px;
          max-width: 100%;
          position: relative;
          &::after{
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path d="M2.89001 6.33984L7.50801 10.9578C7.7716 11.2196 8.12802 11.3666 8.49951 11.3666C8.87101 11.3666 9.22743 11.2196 9.49101 10.9578L14.11 6.33984" stroke="%23021021" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            position: absolute;
            right:6px;
            top: 17px;
          }
        }
        svg{
          display: none;
        }
      }
    }
  }

  &__listing {
    padding-bottom: 20px;
    margin-bottom: 60px;
  }

  &__listing-header {
    padding: 31px 44px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 786px) {
      padding: 20px;
    }

    h4 {
      font-size: 24px;
      font-weight: 500;

      @media screen and (max-width: 786px) {
        font-size: 14px;
      }
    }

    &__sorting-controls {
      display: flex;
      align-items: center;
      gap: 22px;
      color: $color-dark;

      .MuiInputLabel-root {
        color: $color-dark;
      }

      .MuiInputBase-root {
        width: 150px;
      }
    }

    &--right {
      margin-left: auto;
      .MuiInputBase-root:has(> .MuiSelect-select) 
      {
        border:1px solid $placeholder-color;
        position: relative;
        &:after{
          position: absolute;
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none"><path d="M1 1L5.5 6.175L10 1" stroke="%238899B4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          right:15px; 
          cursor: pointer;
        }
        .MuiInputBase-input{
          padding: 12px 0 12px 18px;
          
        }
        svg{
          visibility: hidden;
        }
      }
    }
  }

  .tablelike {
    --cols: 4;
  }
}