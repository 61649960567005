.settings-layout {
  display: flex;
  position: relative;
  background: #e8edf2;
  @media screen and (max-width:1024px) {
    height:100vh;
  overflow: hidden;
    &::after{
      content: "";
      position: absolute;
      width:100%;
      height: 100%;
      background-color: rgba(1, 9, 29, 0.4);
    }
    &--collapsed{
      height:auto;
  overflow: auto;
      &::after{
        content: none;
      }
    }
  }
}




.profile-settings-content {
  margin-top: 32px;
}