.customer-slide {
    padding: 29px 24px;
    display: flex;
    flex-direction: column;
    background-color: $color-light;
    box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    margin-bottom: 10px;
    min-width: calc(100%/3 - 26px);
    position: relative;

    &__desc {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: $color-dark;
        margin-top: 14px;
        margin-bottom: 29px;

        @media (max-width: 1024px) {
            // height: 84px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        &--name {
            color: $color-dark;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }

        &--desigination {
            color: $color-body-text-1;
            font-size: 8px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__collen {
        position: absolute;
        right: 19px;
        bottom: 16px;
    }

    @media (max-width: 1600px) {
        height: 240px;
    }

    @media (max-width: 1024px) {
        min-width: calc(50% - 23px);
    }

    @media (max-width: 700px) {
        min-width: 100%;
        height: auto;
    }
}