::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none; 
    border-radius: 10px;
    background-color: rgba($color-secondary,0.22);
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-secondary; 
    border-radius: 10px;
  }