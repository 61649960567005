.devices-plan-summary{

.edit-plan-summary-header{
	margin:15px 0 5px;
	.summary-section{
		color:$color-dark;
		width: 100%;
		display: flex;
		padding: 0px;
		align-items: center;
		border-radius: 0px;
		margin-bottom: 5px;
		flex-direction: row;
		justify-content: space-between;
		background-color: transparent;
	}
	.device-details{
		@include displayFlex();
		 img {
			width: 50px;
			height: 50px;
			margin-right: 3px;
		}
		.device-name {
			@include fontStyles($color-dark,16px,normal);
		}
		.device-status{
			margin-bottom: 0;
			margin-left: 10px;
		}
	}
	.edit-icon {
		padding: 0px !important;
		background: transparent !important;
		border: none !important;
		cursor: pointer;
	}
}
.device-summary-details{
    padding: 20px 15px 5px;
    border-radius: 20px;
    margin-bottom: 15px;
	@include displayFlex(column,flex-start,flex-start);
    background-color: $address-form;
    .title{
		@include fontStyles($color-body-text-2,14px,normal);
		width:100%;
	}
	.summary-section-body{
		margin: 15px 0px;
    padding: 15px 0px 0px;
    border-top: 1px solid rgba($border-color, 0.4);
    border-bottom: 1px solid rgba($border-color, 0.4);
	width:100%;
		.inner-section{
			margin-bottom:15px;
			@include displayFlex($justifyContent:space-between);
			.plan-detail{
				@include displayFlex(column,$alignItems:flex-start);
				@include fontStyles($color-text-secondary,14px,150%);
			}
			.plan-sub-detail{
				@include fontStyles($color-body-text-1,12px,normal,$fontWeight:400);
			}
			.plan-cost{
				@include fontStyles($color-text-secondary,14px,150%);
				display: flex;flex-direction: column;
				align-items: flex-end;
			}
			.subject,.detail{
				@include fontStyles($color-text-secondary,14px,150%);
			}
		}
	}
	.summary-section-footer{
		@include displayFlex(row,space-between,center);
		width:100%;
		.inner-section{
			@include displayFlex($justifyContent:space-between);
			width:100%;
		}
		.subject,.detail{
			@include fontStyles($color-text-secondary,14px,150%);
		}
		.price{
			@include fontStyles($color-text-secondary,18px,150%,$fontWeight:700);
		}
	}
}
.saving-section{
	margin:15px 0px;
	border-radius: 8px;
	p{
		font-size: 12px;
	}
}
.proceed-btn{
	padding: 14px 25px;
	width:100%;
	@include fontStyles($color-light,14px,normal);
	background-color: $color-secondary;
	border-radius: 8px;
	box-shadow: none;
	transition:none;
	height:auto;
}
}