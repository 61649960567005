.text-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .border {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        img {
            width: 100%;
        }
    }

    .title {
        color: $color-dark;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.6rem;
    }

    .description {
        color: $color-body-text-2;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    @media (max-width: 1024px) {
        margin-top: 34px;
    }
}