.MuiSwitch-track {
  background-color: #D9E0ED;
  opacity: 1 !important;
}

.MuiSwitch-root {
  height: 25px;
  border-radius: 20px;
  width: 45px;
  padding: 0;
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: $color-secondary;
}

.MuiSwitch-switchBase {
  padding: 3px;
}

.MuiSwitch-thumb {
  width: 18px;
  height: 19px;
  color: white;
}

.switch-label {
  margin-left: 0;

  & .MuiSwitch-root {
    margin-right: 11px;
  }
}