@keyframes disappear {
  50% {
    opacity: 0.5;
    width: 50%;
  }

  100% {
    opacity: 0;
    width: 0;
    visibility: hidden;
  }
}

.sidebar {
  width: 400px;
  background-color: white;
  transition: width 500ms;
min-height: 100vh;
  --right-padding: 15px;
  --left-padding: 148px;

  $top-padding: 44px;
  @media screen and (max-width: 1600px) {
    width:300px;
    --left-padding: 78px;
  }
  @media screen and (max-width: 1300px) {
    width:240px;
    --left-padding: 25px;
  }
  @media screen and (max-width: 1024px) {
    position: absolute;
    top:0;
    z-index:2;
    width:260px;
    --left-padding: 35px;
  }
  .tabs-container {
    background-color: white;
    justify-content: space-around;
    padding-left: var(--left-padding);
    padding-right: var(--padding-right);
    margin-top: 26px;
    transition: padding 500ms;
    @media screen and (max-width:585px) {
      margin-top: 12px;
   }
    // @media screen and (max-width: 786px) {
    //   background-color: transparent;
    //   justify-content: start;
    //   padding-left: 0;
    //   padding-right: 0;
    //   gap: 14px;
    // }
  }

  .tab-item {
    padding-top: 15px;
    min-height: unset;
    font-size: 14px;
    text-transform: none;
    padding-left: 0;
    padding-right: 0;
    justify-content: start;
    font-weight: 500;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #8899B4;

    // @media screen and (max-width: 786px) {
    //   background-color: white;
    //   padding-left: 12px;
    //   padding-top: 12px;
    //   padding-right: 12px;
    //   border-width: 1px;
    //   border-radius: 300px;
    // }

    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-basis: content;
      color: $color-body-text-1;
      margin-right: 16px;
    }
  }

  &--collapsed {
    width: 75px;
    --left-padding: 0;
    @media screen and (max-width: 1024px) {
      position: relative;
      width: 60px;
    }
    @media screen and (max-width:585px) {
      width:48px;
    }
    @media screen and (max-width: 390px) {
      width:40px;
    }
    .tab-item {
      min-width: 0;
      width: 100%;

      &>span {
        animation: disappear 500ms both;
        min-width: 0;
      }

      &>svg {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--collapsed &__collapse {
    transform: translateX(50%) ;
    svg{
      transform: rotateZ(180deg);
    }
  }

  &:not(&--collapsing):not(&--collapsed) &__footer {
    display: block;
    padding-bottom: 20px;;
  }


  &__content {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    .MuiTabs-indicator {
      display: none;
    }
  }

  &__back-link {
    display: flex;
    gap: 16px;
    align-items: center;
    color: $color-body-text-1;
    border-bottom: 1px solid rgba($color-border, 0.4);
    font-weight: 500;
    font-size: 14px;
    transition: all 500ms;
    padding-top: $top-padding;
    padding-bottom: 28px;
    padding-left: calc(var(--left-padding) + 4px);
@media screen and (max-width: 585px) {
       padding-top: 12px;
    padding-bottom: 12px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--collapsed &__back-link {
    text-overflow: clip;
    gap: 0;
    justify-content: center;

    span {
      animation: disappear 500ms both;
      min-width: 0;
    }
  }

  &__collapse {
    position: absolute;
    top: 31px;
    right: -9px;
    transform: translateX(50%);
    transition: transform 500ms;
    cursor: pointer;
    box-shadow: none;
    height: auto;
    min-width: unset;
    background-color: #f8f8f8 !important;
    border-radius: 0 8px 8px 0;
    padding: 12px 6px;
    @media screen and (max-width: 585px) {
      top:10px;
    }
  }

  &__logout {
    border-top: 1px solid rgba($color-border, 0.4);
    padding-top: 28px;
    border-left: none;
    border-right: none;
    background: none;
    cursor: pointer;
    min-width: 0px;
    width: 100%;
    margin-top: 28px;
    @media screen and (max-width:585px) {
       margin-top: 12px;
       padding-top:12px;
    }
  }

  &__footer {
    font-size: 12px;
    font-weight: 500;
    padding-left: var(--left-padding);
    padding-right: var(--right-padding);
    padding-top: 28px;
    color: $color-body-text-1;
    display: none;

    .link {
      font-size: 12px;
    }
  }

  .Mui-selected {
    svg,span {
      color: $color-secondary;
    }
  }

  .tabs-scroller {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}