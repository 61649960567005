@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

.new-homepage {
    position: relative;

    .header-user {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        background-image: none;
        padding: 25px 0;
        @media screen and (max-width:600px) {
            padding:20px 0;
        }
    }

    .hero-section {
        background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/hero-section.webp");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        padding: 60px 0 40px;
        color: white;
        background-color: #C8E4FA;
        height: auto;

        @media screen and (max-width:600px) {
            padding:35px 0;
        }
 

        .hero-section-inner {
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .text-stroke-trick {
                font-weight: 800;
                font-size: 64px;
                line-height: 65px;
                text-align: center;
                text-transform: uppercase;
                color: #1A74E2;

                @media screen and (max-width:991px) {
                    font-size: 54px;
                    line-height: 65.83px;
                }
                @media screen and (max-width:600px) {
                    font-size: 34px;
                    line-height: 53.64px;
                }
            }

            h2 {
                font-weight: 800;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                letter-spacing: 1px;
               text-transform: initial;
                color: #3D3D3D;

                @media screen and (max-width:991px) {
                    font-size: 24px;
                    line-height: 36px;
                }

                 

                @media screen and (max-width:600px) {
                    font-size: 16px;
                    line-height: 19.5px;
                }
            }

            .subtitle {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #3D3D3D;
                z-index: 1;
                margin-top: 20px;
                width: 820px;
                max-width: 90%;
                @media screen and (max-width:991px) {
                    font-size: 24px;
                    line-height: 34px;
                }

                @media screen and (max-width:600px) {
                    font-size: 14px;
                    line-height: 19.9px;
                    max-width: 95%;
                }
            }

            .hero-section-detail {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 30px 0;
                z-index: 1;
                width: 100%;
                position: relative;
                bottom: unset;
                

                img {
                    height: 354px;
                    width: 404px;

                    @media screen and (max-width:600px) {
                        width: 252px;
                        height: 220px;
                    }
                }
            }

            .device-feature {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 30px;
                word-spacing: 1rem;

                @media screen and (max-width:991px) {
                    font-size: 22px;
                    line-height: 34px;
                }

                @media screen and (max-width:600px) {
                    font-size: 16px;
                    line-height: 30px;
                    max-width: 348px;


                }
            }

            .detail-list {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 30px;
                width: 100%;
                justify-content: space-between;

                flex-wrap: wrap;

                @media screen and (max-width:991px) {
                    align-items: flex-start;
                }

                @media screen and (max-width:600px) {
                    justify-content: center;
                }


                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 20%;

                    @media screen and (max-width:600px) {
                        width: 33%;
                        margin-bottom: 20px;
                    }

                    p {

                        font-weight: 700;
                        font-size: 44px;
                        line-height: 54px;
                        text-align: center;
                        color: #FFFFFF;

                        @media screen and (max-width:991px) {
                            font-size: 32px;
                            line-height: 39px;
                        }
                    }

                    span {

                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        text-align: center;
                        color: #FFFFFF;

                        @media screen and (max-width:991px) {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            max-width: 105px;

                        }
                    }
                }
            }

            .testimonials {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 30px 0;
                width: 100%;
                justify-content: space-between;

                @media screen and (max-width:991px) {
                    margin: 70px 0 20px;
                    display: block;
                    overflow: hidden;
                    overflow-x: auto;
                    white-space: nowrap;
                    width: 100%;
                }
                &::-webkit-scrollbar{
                    display: none;
                }
                @media screen and (max-width:600px) {
                    margin-top:30px;
                }
                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 30%;
                    @media screen and (max-width:991px) {
                        margin: 0px;
                        display: inline-flex;
                        margin-right: 80px;
                        width: 33%;
                    }
                    @media screen and (max-width:820px) {
                        margin-right: 40px;
                        width: 40%;
                    }
                    @media screen and (max-width:767px) {
                        margin-right: 40px;
                        width: 60%;
                    }
                    @media screen and (max-width:425px) {
                      
                        width: 250px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #FFFFFF;
                        margin-bottom: 5px;

                        @media screen and (max-width:991px) {

                            font-size: 18px;
                            line-height: 22px;
                        }
                    }

                    span {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: #C2C2C2;
                        margin: 10px 0;
                        max-width: 377px;

                        @media screen and (max-width:991px) {
white-space: normal;
max-width: 100%;
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }

                .profile {
                    margin-top: 10px;

                    p {

                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: #C2C2C2;
                        margin: 0;

                        @media screen and (max-width:991px) {
                            font-size: 14px;
                            line-height: 22px;

                        }
                    }
                }
            }

            .btn-section {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;

                @media screen and (max-width:600px) {
                    width: 100%;
                }

                .btn {
                    background: #F0F8FF;
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    text-align: center;
                    text-transform: capitalize;
                    color: #000000;
                    padding: 12px 55px;

                    @media screen and (max-width:600px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .feature-section {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 70px 0;

        @media screen and (max-width:991px) {
            padding: 30px 0;
        }

        .feature-section-inner {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .all-features {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .btn-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 70px;

            @media screen and (max-width:600px) {
                width: 100%;
                column-gap: 60px;
            }
        }

        .shop-link {
            background: #1A74E2;
            border-radius: 8px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            text-align: center;
            text-transform: capitalize;
            color: #ffffff;
            padding: 12px 55px;

            @media screen and (max-width:600px) {
                width: 100%;
            }
        }

        .view-all-featrure-btn {
            height: auto;
            padding: 0;
            margin: 0 auto;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            text-transform: uppercase;
            color: #021021;
            background: none;
            box-shadow: none;
            margin:0 0  30px 0;
            box-shadow: none;

            @media screen and (max-width:600px) {
                width: 100%;
                 
            }

            span {
                margin-right: 8px;
                text-transform: uppercase;
            }
        }

        .all-fetaures-section {

            width: 100%;

            .header {
                font-weight: 500;
                font-size: 28px;
                line-height: 40px;
                text-align: center;
                color: #131313;
                text-align: center;
                width: 100%;
                margin: 0 0 70px 0;
                @media screen and (max-width:991px) {
                    font-size: 24px;
                    margin-bottom: 30px;
                }
                @media screen and (max-width:600px) {
                    
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 34px;
                    text-align: center;
                    color: #131313;


                }
            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;

                @media screen and (max-width:991px) {
                    justify-content: space-between;
                }

                li {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 25%;
                    margin-bottom: 50px;

                    @media screen and (max-width:1369px) {
                        width: 33.33%;
                    }

                    @media screen and (max-width:991px) {
                        width: 50%;
                        margin-bottom: 30px;
                    }

                    @media screen and (max-width:600px) {
                        width: 44%;
                    }

                    p {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #021021;
                        margin: 10px 0;
                    }

                    span {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        color: #4F5661;
                        max-width: 280px;
                    }
                }
            }
        }
    }

    .track-solution-section {
        background-color: #01152F;
        background-size: cover;
        padding: 65px 0px;
        @media screen and (max-width:991px) {
            padding: 40px 0;
        }
        @media screen and (max-width:600px) {
            padding: 30px 0;
        }

        .header {

            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 55px;

            @media screen and (max-width:991px) {
                margin-bottom: 32px;
                font-size: 24px;
            }
            @media screen and (max-width:600px) {
                margin-bottom: 24px;
                font-size: 14px;
            }
        }

        .box-inner-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
           

            @media screen and (max-width:991px) {
                flex-direction: column;
                align-items: center;
                padding-bottom: 0px;
            }

            .img-section {
                width: 60%;
               img{
                height: auto;
               }
                @media screen and (max-width:991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }

            .box-text-section {
                display: flex;
                flex-direction: column;
                width: 30%;
               gap:20px;

                @media screen and (max-width:991px) {
                    width: 100%;
                    align-items: center;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;

                    @media screen and (max-width:991px) {
                        flex-direction: row;
                        width: 100%;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                     

                    li {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 30px;
                        flex-wrap: wrap;
                        align-items: flex-start;

                        @media screen and (max-width:1369px) {
                            margin-bottom: 25px;

                        }

                        @media screen and (max-width:991px) {
                            width: 49%;

                        }

                         

                        .text-section {
                            flex: 1;
                            max-width: 266px;
                            @media screen and (min-width:2400px) {
                                max-width: 100%;
                            }
                            @media screen and (max-width:600px) {
                                max-width: 100%;
                            }
                        }

                        p {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 32px;
                            color: #E3E3E3;
                            @media screen and (max-width:600px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }

                        
                    }
                }
            }

            .learn-more-btn {
                padding: 12px 62px;
                background: #F0F8FF;
                border-radius: 8px;

                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 150%;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                text-decoration: none;

                @media screen and (max-width:600px) {
                    width: 100%;
                }
            }
        }
    }

}