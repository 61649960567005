.MuiInputBase-root:has(>.MuiSelect-select) {
  background: $color-tab-background;
  border-radius: 10px;
  svg{
    position: absolute;
    right: 15px;
    top: 18px;
  }
  .MuiInputBase-input {
    max-width: 200px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    line-height: normal;
    font-weight: 500;
    color:$form-filled-input-color;
    &:focus {
      background: none;
    }
  }

  &:focus {
    background: $color-tab-background;
  }

  &:hover {
    background: $color-tab-background;

    &::before {
      border: none;
    }
  }

  &::before {
    border: none;
  }

  &::after {
    border: none;
  }


  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

 
  .MuiOutlinedInput-notchedOutline legend>span:not(.notranslate) {
    display: none;
  }
}

.MuiFormControl-root:has(.MuiInputBase-root>.MuiSelect-select) {
  margin-top: 22px;

  .MuiInputLabel-root {
    $font-size: 14px;
    top: -#{$font-size + 11px};
    left: 0;
    font-size: $font-size;
    color: $color-dark;
    font-style: normal;
    font-weight: 500;
    transform: none;
  }


}


.MuiInputBase-root:has(>.MuiSelect-select).secondary {
  background-color: white;
  border-radius: 30px;
  border: 1px solid $color-dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  &:focus {
    background: white;
  }

  &:hover {
    background: white;
  }
}