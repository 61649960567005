.phone-number-field 
    {
        margin-top:0;
        margin-left:0;
        .MuiInputBase-root {
            background-color: $form-textfield-bg;
            border-radius: 10px;
        }
            input{
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-left: 1px solid #A0B0D033 !important;
        }
}
.react-tel-input .country-list{
    width: 280px !important;
    .search-box {
        border:  1px solid #A0B0D033 !important;
        border-radius: 8px !important;
        font-size: 12px !important;
        line-height: 15px;
        margin-left: 0 !important;
        padding: 10px 5px 10px 15px !important; 
        outline: none;
        width: 95%;
        background-color: white !important;
    }
}
.react-tel-input .country-list .country {
    padding: 7px 9px;
    font-size: 14px;
    font-weight: 500;
}