.view-geofence{
    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #C8D1E8;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .name-details{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .geofence-header{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .geofence-name{
            display: flex;
            flex-direction: row;
            align-items: center;
            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #021021;
                margin-left: 8px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 135px;
            }
        }
        .back-btn{
            height: auto;
            padding:0;
            min-width: unset;
            margin-right: 8px;
        }
    }
    .address-section{
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #C8D1E8;
        padding-bottom: 15px;
        p{
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #8899B4;
            margin-left: 15px;
            flex:1;
        }
    }
    .group-tracker-section{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #C8D1E8;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .group-section{
            display: flex;
            flex-direction: column;
            .title{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #021021;
                margin-top: 15px;
            }
            .selected-option-detail{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
                width: 100%;
                justify-content: space-between;
                .details{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex:1;
                    .name{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        img{
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                        }
                        p{
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 150%;
                            color: #8899B4;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 135px;
                        }
                    }
                    .total{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        color:$color-secondary;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .shape-distance-section{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        .label{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #021021;
            
        }
        .main-section{
           
            width: 48%;
        }
        .detail{
            background-color: #F6F8FA;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            height: 96px;
            margin-top: 10px;
            span{
                font-weight: 500;
            font-size: 14px;
            line-height: 19.07px;
            color: #021021;
            margin-top:8px;
            }
            .tooltip-info-btn{
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }
}