.peeq-features{
    background: linear-gradient(112deg, #C6E1E8 8.81%, #EEEFFC 91.65%);
    padding:30px 0 0;
    @media screen and (max-width:1600px) {
        padding:20px 0px;
    }
    .peeq-features-list{
        @include displayFlex($justifyContent:space-between);
        width:100%;
        list-style-type: none;
        @media screen and (max-width:991px) {
            justify-content: flex-start;
        }
        @media screen and (max-width:585px) {
            justify-content: center;
          }
        li{
            @include displayFlex($direction:column);
            margin-bottom: 30px;
            @media screen and (max-width:991px) {
              width:33.33%;
            }
            @media screen and (max-width:767px) {
                width:50%;
              }
              @media screen and (max-width:585px) {
                width:100%;
              }
            p{
                @include fontStyles($color-secondary,44px,normal,700);
                @media screen and (max-width:1440px) {
                    font-size: 40px;
                }
                @media screen and (max-width:1200px) {
                    font-size: 24px;
                }
                @media screen and (max-width:991px) {
                    font-size: 22px;
                }
                
            }
            span{
                @include fontStyles($color-text-secondary,18px,normal,700);
                text-transform: uppercase;
                @media screen and (max-width:1440px) {
                    font-size: 16px;
                }
                @media screen and (max-width:1200px) {
                    font-size: 14px;
                }
                @media screen and (max-width:991px) {
                    font-size: 14px;
                }
                 
            }
        }
    }
}