.geofence-group-content {
    width: 450px;
    background-color: #F6F8FA;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    gap: 14px;
    border: none;
    outline: none;

    @media screen and (max-width: 500px) {
        max-width: 85%;
    }
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    .cross-icon{
        background-color: transparent;
       padding:0;
       height: auto;
       min-width: unset;
    }
    .name-detail{
        display: flex;
        flex-direction: row;
        align-items: center;
        svg,img{
            margin-right: 8px;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #021021;
            -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
        }
    }
}
.modal-body{
    background-color: $color-light;
    border-radius: 20px;
    width:100%;
    margin:10px 0;
    padding:15px 0px;
    .title{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #021021;
        width: 100%;
        border-bottom: 1px solid #C8D1E8;
        text-align: left;
        padding:10px 15px 15px;
    }
    .group-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0 15px;
        min-height: 275px;
        max-height: 450px;
        overflow: auto;
        margin:10px 0;
        @media screen and (max-width:500px) {
            min-height: 440px;
            max-height: 440px;
        }
        .group-details{
            display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding:15px 0;
        border-bottom: 1px solid #C8D1E8;
        &:hover{
            background-color: rgba(26, 116, 226, 0.06);
            border-radius: 10px;
            border-bottom: 1px solid transparent;
        }
        img{
            margin-right: 10px;
            width: 35px;
            height: 35px;
        }
        .group-name{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #172434;
                -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 135px;
            }
            span{
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #8899B4;
            }
        }
        }
    }
    .tracker-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0 15px;
        min-height: 275px;
        max-height: 450px;
        overflow: auto;
        margin:10px 0;
        @media screen and (max-width:500px) {
            min-height: 440px;
            max-height: 440px;
        }
        .tracker-details{
            display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding:15px 0;
        border-bottom: 1px solid #C8D1E8;
        &:hover{
            background-color: rgba(26, 116, 226, 0.06);
            border-radius: 10px;
            border-bottom: 1px solid transparent;
        }
        img{
            margin-right: 10px;
            width: 35px;
            height: 35px;
        }
        .tracker-name{
            display: flex;
            flex-direction: row;
            align-items: center;

            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #172434;
                -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 135px;
            }
            
        }
        }
    }
}
     

    .btn {
        height: auto;
        min-width: unset;
        padding: 14px 25px;
    }

    
}