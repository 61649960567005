@import "./customer-slide";

.customer-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 33px;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    &__navigation {
        margin-top: 62px;
        display: flex;

        &__dots {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-inline: 24px;

            .active-dot {
                g {
                    rect {
                        fill:  #1A74E2
                    }
                }
            }
        }

        &__prev {
            transform: rotate(-180deg);
        }

        .un-active {
            svg {
                path {
                    stroke: #BDC9E2;
                }
            }
        }
    }
}