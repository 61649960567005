.email-input {
    width: 100%;
    height: 4.7rem;
    display: flex;
    align-items: center;
    padding-left: 1.3rem;
    padding-right: 0.6rem;
    border-radius: 8px;
    border: 0.998px solid $color-tertiary;

    input {
        color: $color-footer-text;
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 1.4rem;
        font-weight: 500;
        margin-left: 7.98px;

        &::placeholder {
            color: #6991F5;
        }
    }

    .submit-button {
        background-color: $color-secondary;
        margin-left: auto;
        height: 3.6rem;
        padding: 0px 2.2rem;
        font-size: 1.4rem;
        text-transform: capitalize;
        box-shadow: none;
        font-weight: 500;
        background: #1A74E2;
        border-radius: 5.31378px;

    }

    @media (max-width: 850px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}