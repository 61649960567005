@import "./feature-item";

.home-features {
    padding-top: 7rem;

    .features {
       padding-inline: 6.7rem;
       padding-block: 6.5rem;
       border-radius: 1rem;
       @media screen and (max-width:1450px) {
        padding-inline: 2.5rem;
       }

       h1 {
        text-align: center;
        font-size: 3.2rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4.8rem;

        span {
            display: block;

            @media (max-width: 400px) {
                display: inline;
            }
        }
       }

       &__container {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 3.3rem;
            margin-bottom: 4.4rem;
            @media screen and (max-width:1700px) {
                grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
                gap: 2.5rem;
            }
            @media screen and (max-width:1500px) {
                grid-template-columns: repeat(auto-fit, minmax(274px, 1fr));
               
            }
            @media screen and (max-width:1450px) {
                grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
                
            }
            @media screen and (max-width:1389px) {
                grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
            gap: 3.3rem;
                
            }
            @media screen and (max-width:937px) {
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            
                
            }
        }

        &__view-more {
            font-size: 16px;
            font-weight: 600;
            // text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1400px) {
                font-size: 15px;
            }
        }

        @media (max-width: 600px) {
            padding-inline: 4rem;
            min-width: 100vw;

            h1 {
                font-size: 24px;
                line-height: 150%;
            }
        }

        @media (max-width: 500px) {
            padding-inline: 3rem;
        }

        @media (max-width: 500px) {
            padding-inline: 2.5rem;
        }
    }

    @media (max-width: 600px) {
        padding: 5rem 0;

        .MuiContainer-root {
            margin: 0 !important;
        }
    }
}