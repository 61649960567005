.form-textarea
    {
        font-family: inherit;
        .label{
             @include displayFlex(row,flex-start,flex-start);
             .label-tag{
                color: $color-text-secondary;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 5px;
                font-family: inherit;
             }
             .info-required{
                font-size: 13px;
                
                margin-top: -2px;
             }
        }
        .textarea{
            width:100%;
            background-color: $form-textfield-bg !important;
            color: $form-filled-input-color !important;
            font-size: 14px;
            font-weight: $font-weight-medium;
            box-shadow: none;
            line-height: 140%;
            border: none;
            outline: none;
            border-radius: 10px;
            padding:15px;
            margin-bottom: 15px;
            @include displayFlex(column,space-between,flex-start);
            min-height: 138px;
            &.textarea-box{
               
                    min-height: 215px;
               
               
            }
            
            textarea{
                resize: none;
                font-family: inherit;
                border:none;
                outline: none;
                background: none;
                width: 100%;
                height: 80%;
                font-weight: $font-weight-medium;
                font-size: 14px;
                &::placeholder{
                    color:$placeholder-color;
                    font-weight: inherit;
                }
            }
            .character-count{
                @include displayFlex(row,flex-end,flex-end);
                width: 100%;
                span{
                    color:$placeholder-color;
                }
            }
        }
        
           
}