.page-header {
  padding: 20px 0;

  @media screen and (max-width: 786px) {
    padding: 15px 0;

  }

  &__title {
    font-size: 25px;
    font-weight: 500;

    @media screen and (max-width: 786px) {
      font-size: 16px;
    }
  }

  &__subtitle {
    margin-top: 3px;
    color: $color-body-text-1;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 786px) {
      margin-top: 5px;
    }
  }
}