.image-upload-surface {
  &__crop {
    width: 500px;
    max-height: 400px;
    object-fit: contain;
    text-align: center;

    &>div {
      max-height: inherit;
    }
  }
}

.image-upload-modal {
  width: 500px;

  @media screen and (max-width: 786px) {
    width: 350px;
  }

  .settings-block__footer {
    display: flex;
    justify-content: space-between;
  }
}