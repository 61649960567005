@import "./user-cart-dropdown";
@import "./cart-item";

.user-cart {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    .cart-counter{
        position: absolute;
        @include displayFlex();
        content: " ";
        background-color: $color-secondary;
        @include fontStyles($color-light,10px,normal);
        border-radius: 50px;
        top: -5px;
    right: -13px;
    padding: 3px 5px;
    min-width: 21px;
    min-height: 20px;
    }
    .cart-icon {
        cursor: pointer;
    }
}