.stat-item-section{
    display: flex;
    justify-content: space-between;
    width:100%;
    flex-wrap: nowrap;
    position: absolute;
    top:144px;
    left:0;
    @media (max-width: 1200px) {
        top:100px;
    }
    @media (max-width: 820px) {
        position: relative;
        flex-wrap: wrap;
        top:0;
    }
}
.stat-item {
    flex: min(204px, 100%/5 + 0.1%);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__number {
        color: $color-secondary;
        font-size: 4.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.822px;
        margin-bottom: 3.67px;
        @media (max-width: 1200px) {
            font-size: 32px;
        }
        @media (max-width: 820px) {
            font-size: 3.2rem;
        }
    }

    &__fact {
        color: $color-logo-header;
        text-align: center;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
        @media (max-width: 1200px) {
            font-size: 14px;
        }
        @media (max-width: 425px) {
            font-size: 13px;
        }
    }

    @media (max-width: 1028px) {
        flex: max(204px, 100%/5 + 0.1%);
    }

    @media (max-width: 820px) {
        flex: max(134px, 100%/3 + 0.1%);
        padding-inline: 20px;
        width:50%;
        margin-bottom: 25px;

    }

    @media (max-width: 600px) {
        flex: max(134px, 100%/3 + 0.1%);
        padding-inline: 0;
    }
}