.newsletter__container {
    background-repeat: no-repeat;
    min-height:100vh;
    width: 100%;
    overflow: auto;
    background-position: center;
    position: relative;
    background-size: cover;

    .newsletter__unsubscribe {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        &__wrapper {
            background-color: #fff;
            z-index: 1000;
            width: min(669px, 100%);
            border-radius: 20px;
            padding: 63px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                font-size: 24px;
                font-weight: 500;
                color: $color-text-secondary;
                text-align: center;
                margin-top: 45px;
                margin-bottom: 10px;
            }

            .email-section {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                color: $color-dark;
                text-align: center;

                a {
                    color: $color-secondary;
                    text-decoration: underline;
                }
            }

            .note {
                font-size: 12px;
                font-weight: 500;
                line-height: 140%;
                color: $color-body-text-1;
                margin-top: 20px;
                margin-bottom: 24px;
            }

            .unsubscribe__btn {
                background-color: $color-secondary;
                padding: 12px 30px;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                color: $color-light;
                border-radius: 250px;
            }
        }
    }
}

.mt-18 {
    margin-top: 18px;
}