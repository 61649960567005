.date-select {
    width: 249px;
    position: relative;

    select {
        appearance: none;
        -webkit-appearance: none;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 13px 15px;
        background-color: $form-textfield-bg;
        border: none;
        border-radius: 10px;
        color: $placeholder-color;
        cursor: pointer;

        &:focus {
            outline: none;
        }
      }

      option {
        padding: 15px;
      }

      .arrow {
        position: absolute;
        right: 20px;
        top: 17px;
        pointer-events: none;
      }
}