@import "./stat-item";

.global-reach {
    padding: 30px 0;

    &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 7rem;

        h2 {
            color: $color-secondary;
            text-align: center;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: uppercase;
        }

        h3 {
            text-align: center;
            font-size: 3.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            color: $color-logo-header;
            margin-top: 10px;
            margin-bottom: 14px;
        }

        p {
            text-align: center;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            color: $color-dark;
            width: min(762px, 100%);
        }

        @media (max-width: 700px) {
            padding-inline: 18px;
            margin-bottom: 33px;

            h3 {
                font-size: 24px;
                line-height: 150%;
                font-weight: 500;
            }

            p {
                font-size: 14px;
            }
        }
    }

    &__stats-container {
        display: flex;
        flex-wrap: wrap;
        gap: 94px;
        row-gap: 31px;
        width: 100%;
        position: relative;
        height: 975px;

        @media (max-width:600px) {
            height: unset;
        }

        @media (max-width: 820px) {
            gap: 15px;
            row-gap: 31px;
        }

        .testimonials {
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-image: url("https://d1wp0s07qdz4mg.cloudfront.net/v1/about-us-map.png");
            background-position: center;

            @media (max-width:600px) {
                background-image: none;
                overflow: hidden;
               
            }

            &__one {
                position: absolute;
                top: 0;
            }

            .testimonial__two {
                position: absolute;
                right: 0;
                top: 200px;

                @media screen and (max-width:991px) {
                    top: unset;
                }

                @media (max-width:600px) {
                    position: relative;
                    left: unset;
                    top: unset;
                    bottom: unset;
                    right: unset;
                }
            }

            .testimonial__three {
                position: absolute;
                left: 29%;
                bottom: 80px;

                @media (max-width: 991px) {
                    left: 0;
                    bottom: 0;
                }

                @media (max-width:600px) {
                    position: relative;
                    left: unset;
                    top: unset;
                    bottom: unset;
                    right: unset;
                    margin-bottom: 0 !important;
                }
            }

            .testimonial-inner {
                background-image: url('../../../../resources/images/about-testimonial-bg.svg');
                padding: 30px;
                max-width: 453px;
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (max-width:600px) {

                    background: linear-gradient(180deg, rgba(#DEEDFF, 0.6) 31%, rgba(#B0D5FF, 0.6) 100%);
                    min-height: 300px;
                    margin-bottom: 105px;
                    max-width: 100%;
                    padding: 25px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 91%;
                        right: -32px;
                        width: 65px;
                        height: 65px;
                         transform: rotate(45deg);
                        background: #FBFCFE;
                        display: inline-block;
                    }
                }


                .header {

                    font-weight: 600;
                    font-size: 19px;
                    line-height: 22px;
                    color: #0E4180;
                    margin-bottom: 10px;
                    @media screen and (max-width:375px) {
                        font-size: 16px;
                    }
                }

                span {
                    
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #4F5661;
                    max-width:93%;
                    display: inline-block;

                    @media screen and (max-width:600px) {
                        max-width: 100%;
                    }
                    @media screen and (max-width:375px) {
                        font-size: 12px;
                    }
                }

                .rating {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .profile {
                    display: flex;
                    flex-direction: column;

                    p {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: #4F5661;
                    }
                }
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__one {
                position: absolute;
                top: 75px;
                left: 44%;

                @media screen and (max-width:1200px) {
                    left: 60%;
                }

                @media screen and (max-width:991px) {
                    left: unset;
                    right: 0px;
                }

                @media screen and (max-width:600px) {
                    top: -70px;
                    right: 0;
                    left: 0;
                }
            }

            &__two {
                position: absolute;
                left: 90%;
                top: 0;

                @media screen and (max-width:991px) {
                    left: 0%;
                    top: 38%;
                }

                @media screen and (max-width:600px) {
                    left: 0%;
                    top: 29.5%;
                }
            }

            &__three {
                position: absolute;
                left: 30%;
                top: 40%;

                @media screen and (max-width:991px) {
                    left: 0%;
                    top: 55%;
                }

                @media screen and (max-width:600px) {
                    right: 0%;
                    top: 29.5%;
                    left: unset;
                    ;
                }
            }

            &__four {
                position: absolute;
                left: 10%;
                top: 60%;

                @media screen and (max-width:991px) {
                    right: 0%;
                    bottom: 20%;
                    left: unset;
                    top: unset;
                }

                @media screen and (max-width:600px) {
                    left: 0%;
                    bottom:  29%;
                    right: unset;
                    ;
                }
            }

            &__five {
                position: absolute;
                right: 22%;
                bottom: 19%;

                @media screen and (max-width:1340px) {
                    right: 10%;
                }



                @media screen and (max-width:991px) {
                    right: 0%;
                    bottom: 5%;
                }

                @media screen and (max-width:600px) {
                    right: 0%;
                    bottom:  29%;
                }
            }

            p {

                font-weight: 700;
                font-size: 44px;
                line-height: 54px;
                text-align: center;
                color: #03202C;

                @media screen and (max-width:991px) {
                    font-size: 28px;
                    line-height: 34px;
                }

            }

            span {


                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #03202C;

                @media screen and (max-width:767px) {
                    max-width: 102px;
                }

                @media screen and (max-width:600px) {
                    font-size: 14px;
                    max-width: unset;
                }
            }

        }
    }

}