.pencil-banner-container {
    background-color: $color-primary;
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .pencil-banner {
        max-height: 34px;
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4.4rem;
        font-size: 1.4rem;
        color: $color-light-body-3;
        white-space: nowrap;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer/Edge */
        padding:7px 0px;
        &::-webkit-scrollbar {
            display: none; /* Chrome */
        }
    
        &__item {
            display: flex;
            align-items: center;
            p{
                font-size: 14px;
                font-weight: $font-weight-medium;
                line-height: 150%;
                color: $color-light-body-3;
            }
            img {
                margin-right: 1rem;
                width: 2.1rem;
                height: 2rem;
            }
    
            span {
                background-color: $color-light-body-3;
                height: 1px;
                width: 2.8rem;
                margin: 0 5px;
            }
        }
    
        @media (max-width: 660px) {
            animation: showcase 20s linear infinite;
        }
    
        @media (max-width: 530px) {
            justify-content: flex-start;
            padding: 0 10px;
        }
    }
}

@keyframes showcase {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}