.terms-condition-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: $address-form;
    padding:  25px;
    width: min(913px, 90%);
    border-radius: 25px;
    outline: none;
    border: none;
    &__heading {
        color: $color-order-total;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 18px;

        @media (max-width: 700px) {
            font-size: 16px;
        }

        @media (max-width: 500px) {
            font-size: 14px;
        }

        @media (max-width: 400px) {
            padding-inline: 5px;
            text-align: center;
        }
    }
    a{
        color:$color-secondary;
    }
    .date-section{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        span{
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #8899B4;
        }
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #172434;
            @media screen and (max-width:600px) {
                font-size: 18px;
            }
        }
       }
    &__content-container {
        padding: 26px 18px;
        padding-bottom: 20px;
        background-color: $color-light;
        border-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .content {
            padding: 26px 20px;
            background-color: $address-form;
            border-radius: 20px;
            max-height: 324px;
            overflow-y: auto;

              &::-webkit-scrollbar {
                width: 7px;

                @media (max-width: 600px) {
                    width: 4px;
                }
              }
               
              &::-webkit-scrollbar-thumb {
                background: rgba(81, 160, 255, 0.32); 
                border-radius: 10px;
              }

            .heading {
                color: $color-order-total;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                margin-bottom: 10px;

                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }

            .description {
                color: $color-dark;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 18px;

                @media (max-width: 500px) {
                    font-size: 12px;
                }
            }

            .mb-0 {
                margin-bottom: 0 !important;
            }
    
            .mb-30 {
                margin-bottom: 30px !important;
            }
    
            .font-18 {
                font-size: 16px !important;
            }

            .point {
                // color: $color-secondary;
                font-size: 14px;
                font-weight: 600;

                @media (max-width: 500px) {
                    font-size: 14px;
                }
            }
            @media (max-width: 600px) {
                padding: 10px 10px;
            }
        }  

        .instruction {
            color: $color-body-text-1;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-top: 20px;

            span {
                color: $color-secondary;
            }
        }

        @media (max-width: 600px) {
            padding: 10px 10px;
        }
    }

    .divider {
        height: 1px;
        background-color: $color-border;
    }

    &__footer {
        background-color: $color-light;
        padding: 15px 26px 26px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;
svg{
    width: 14px;
    height: 14px;
}
        .checkbox-container {  
            display: flex;
            align-items: center;
            gap: 8px;

            label {
                color: $color-body-text-2;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;

                @media (max-width: 700px) {
                    font-size: 12px;
                }
            }          
        }

        button {
            display: flex;
            width: 109px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            background-color: $color-secondary;
            color: $color-light;
            border: none;
            outline: none;
            font-size: 14px;
            border-radius:30px;
            cursor: pointer;
            &:hover{
                background-color: $color-secondary;
                color: $color-light;
            }
            @media (max-width: 700px) {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 400px) {
        padding: 8px;
    }
}