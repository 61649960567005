.cart-item {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    justify-content: space-between;
 
    .product-image {
       padding:15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F3F5F6;
        border-radius: 12px;

        img {
    width: 58px;
    height: 58px;
    object-fit: contain;
        }
    }

    .product-detail {
        display: flex;
        flex-direction: column;
        gap: 11px;
      flex:1;
        svg {
            cursor: pointer;
        }
        .price-with-discount {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .discounted-price {
                text-decoration: line-through;
                @include fontStyles($color-red, 12px, normal);
            }
            .price{
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .qty-price {
                font-size: 12px;
                color: #8899B4;
                font-weight: 500;
            }
        }
        .name-price {
            display: flex;
            gap: 61px;
             justify-content: space-between;
            
            .name {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                color: $color-text-secondary;
               
            }
            .color {
                @include fontStyles($color-dark, 12px);
                margin-top: 5px;
                text-transform: capitalize;
               
                
                span{
                    @include fontStyles($color-body-text-2, 12px);
                    text-transform: capitalize;
                   
                }

            }
           

            

             
        }

        .item-counter {
            @include displayFlex(row,flex-start);
            
            border-radius: 8px;
                    border: 0.5px solid #8899B4;
                    padding:6px 8px;
            flex-wrap: nowrap;
            width: fit-content;
            svg{
                width: 11px;
                height:11px;
            }
            .btn{
                padding:0;
                min-width: unset;
                height:auto;
                background: none;
                width: auto;
            }
            .form-textfield{
                padding:0;
                background-color:transparent !important;
                margin-bottom: 0;
                .MuiInputBase-root{
                    background-color: transparent  !important;
                }
                input{
                    width: 25px;
                            max-width: 35px;
                    text-align: center;
                    margin-bottom: 0;
                    background: none;
                    background-color: transparent !important;
                    border: none;
                    border:0;
                    
                    width: fit-content;
                    margin: 0 2px;
                    padding: 0;
                    color:#021021 !important;
                   
                }
            }

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
               
            }
        }
    }

     .bottom-section{
        @include displayFlex();
        justify-content: space-between;
        width: 100%;
        gap:5;
     }
     
}