// light theme variables

$color-primary: #0E4180;
$color-secondary: #1A74E2;
$color-tertiary: #6991F5;
$color-main-bg: #F6F8FA;
$color-light-body-3: #C8D1E8;
$color-body-3: #2E3B4B;
$color-body-text-1: #8899B4;
$color-body-text-2: #4F5661;
$color-body-text-3: #BDC9E2;
$color-border: #AAB6BC;
$color-red: #F24343;
$color-border: #AAB6BC;

$color-why-bg: rgba(245, 245, 246, 0.20);

$color-order-status-pending-bg: #F8F4DC;
$color-order-status-pending: #E66D13;
$color-order-status-not-paid-bg: #F7EAEA;
$color-order-status-not-paid: #E0423B;
$color-order-status-deliverd-bg: #DDF3E0;
$color-order-status-deliverd: #287F36;

$color-discounts: #179B2D;

$color-order-border: #E3E7ED;

$color-text-secondary: #021021;
$color-light: #ffffff;
$color-dark: #172434;
$color-order-total: #01091D;
$color-client-light: #F5F1FF;
$address-form:#F6F8FA;
$color-tab-background: #F8F8F8;

$color-logo-footer: #ffffff;
$color-footer-text: #ffffff;
$color-footer-link: #F4F6FB;

$color-logo-header: #021021;
$border-color:#AAB6BC;
$color-grey: #6A6A6A;
$color-davy-grey: #595959;

$color-header-background: #FBFDFF;

$color-dropdown-background: #F0F7FF;
$color-features-background: #e6f4ff;
$color-our-journey-bg: #F0F7FE;
$color-who-we-are-bg: #F7FAFE;
$color-customer-experience-bg: rgba(245, 245, 246, 0.20);

$color-hero-gradient-light: linear-gradient(180deg, #BEDEE4 0%, #F5F1FF 100%);
$color-get-started-gradient-light: linear-gradient(180deg, #BEDEE4 0%, #F5F1FF 100%);
$onboarding-background-linear-gradient: linear-gradient(180deg, #bedee4b8 0%, #f5f1ffe8 100%);

$form-border-radius: 20px;

$font-weight-medium:500;
$font-weight-regular:400;
$font-weight-semibold:600;

$form-textfield-bg:#F3F5F6;
$placeholder-color:#A0B0D0;
$form-filled-input-color:#021A37;
$color-user-dropdown-gradient: linear-gradient(0deg, $color-dropdown-background 0%, $color-dropdown-background 100%), $color-light;

$filter-bg:#E8EDF2;


$form-textfield-bg: #F3F5F6;
$placeholder-color: #A0B0D0;
$form-filled-input-color: #021A37;
$color-user-dropdown-gradient: linear-gradient(0deg, $color-dropdown-background 0%, $color-dropdown-background 100%), $color-light;
$color-order-table-header: linear-gradient(90deg, #5A7EE9 1.16%, #1A74E2 99.02%);
