.settings-block {
  background-color: white;
  border-radius: 20px;

  &__header {
    padding: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($color-border, 0.4);
    display: grid;
    grid-template-columns: 3fr 1fr;

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr 1fr;
    }

    .btn {
      padding: 0;
      justify-self: flex-end;
      white-space: nowrap;
    }
  }


  &__header-title {
    font-size: 18px;
    font-weight: 500;
  }

  &__content--right {
    justify-self: end;
  }

  &__body {
    padding: 32px 25px;

  }

  &__footer {
    display: grid;
    padding: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba($color-border, 0.4);
    .btn{
      background-color: $color-secondary;
      height: auto;
      padding:10px 20px;
      border-radius: 8px;
      font-family: inherit;
      font-size: 14px;
      line-height: 150%;
      font-weight: 500;
    }
    .cancel-btn{
      background-color: transparent;
      padding:0;
    }
  }
}