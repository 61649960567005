.filter-section-modal{
	position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 893px;
    background-color: $color-main-bg;
    border: none;
    border-radius: 17px;
    box-shadow: none;
    color: $color-light;
    padding: 0!important;
    outline: none;
	@media screen and (max-width:991px) {
		 width:96%;
	}
	@media screen and (max-width:870px) {
		width:75%;
   }
   @media screen and (max-width:585px) {
	width:90%;
}

.filter-section-modal-body {
	border-radius: 20px;
	padding: 15px 25px;
	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
        padding-bottom: 15px;
		.filter-title {
			display: flex;
			align-items: center;
			span {
				margin-left: 1rem;
                @include fontStyles($color-text-secondary,18px);
			}
		}
	}
	.modal-footer {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding-top: 25px;
		margin-bottom: 5px;
		gap:15px;
		 
		.walkthrough-btn{
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: 120%;
			padding: 12px 10px !important;
			text-transform: capitalize;
			border-radius: 8px;
			display: flex;
			align-items: center;
			flex-direction: row;
			color: $color-body-text-1;
			background-color: transparent;
			border:1px solid $placeholder-color;
			@media screen and (max-width:585px) {
				margin-bottom:10px;
			}
			span{
				margin-left: 8px;
			}
		}
		.clear-btn {
			color: $color-body-text-1;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0 !important;
			text-transform: capitalize;
            box-shadow: none;
			background:none;
			height:auto;
			margin-right: 8px;
		}
		.filter-btn {
			color: $color-light;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 120%;
			padding: 12px 25px !important;
			text-transform: capitalize;
			border-radius: 8px;
			background-color: $color-secondary;
		}
	}
	.modal-body {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		// flex-wrap: wrap;
		border-top: 1px solid $filter-bg;
		border-bottom: 1px solid $filter-bg;
		padding: 20px 0px;
		@media screen and (max-width:870px) {
			flex-direction: column;
			height: 450px;
			flex-wrap: nowrap;
			overflow-x: hidden;
			overflow-y: auto;
		}
		.filter-detail-sections {
			border-radius: 20px;
			background: $color-light;
			padding: 15px 0px 0;
			margin-right: 15px;
			max-width: 283px;
			margin-bottom: 8px;
			min-height: 305px;
			flex:1;
			@media screen and (max-width:870px) {
				max-width: 100%;
				min-width: 100%;
				width: 100%;
				min-height:unset;
				margin-right: 0;
				margin-bottom: 20px;
				padding-bottom: 6px;
			}
		}
	}
	.devices-filter-accordion {
	padding:0 10px;
		.MuiSvgIcon-root {
			color: $color-body-text-1;
		}
	}
	.filtered-devices-list {
		padding: 0;
		list-style-type: none;
		margin: 0;
        span{
            @include fontStyles($color:$color-body-text-1,$fontSize: 11px);

        }
		li {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid rgba(134, 152, 174, 0.4);
			padding: 10px 0px;
			.device-detail-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
			.device-name-detail {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.device-detail-body {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				p {
					color: $color-dark;
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					margin: 0;
				}
				img {
					width: 25px;
					height: 25px;
					margin: 0 10px 0 5px;
				}
			}
		}
		.device-name-detail {
			img {
				width: 35px;
				height: 35px;
			}
			.device-name {
				color: $color-dark;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin: 0 8px;
			}
		}
	}
	.selected-filter-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		span {
			color: $color-dark;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 140%;
			margin-right: 5px;
		}
	}
}
.filter-detail-sections {
	.devices-filter-accordion {
		position: relative;
		.MuiAccordionSummary-root {
			padding: 0;
			min-height: auto;
		}
		.MuiAccordionSummary-expandIconWrapper{
			position: absolute;
            right: 18px;
			
		}
		.info-tooltip{
			position: relative;
			width:14px;
			height: 17px;
			@media screen and (max-width:870px) {
				position: static;
			}
			.info-icon-btn{
			position: absolute;
			right: 0px;
			top: 0px;
			min-width: auto;
			box-shadow: none;
			background: transparent;
			z-index:1;
			min-height: auto;
			height: auto;
			padding: 0;
			@media screen and (max-width:870px) {
				position: static;
			}
			
		}
		.info-tooltip-detail{
			display: none;
			border-radius: 10px;
			background-color: $color-light;
			border:1px solid $placeholder-color;
			color:$color-body-text-2;
			padding:12px 10px;
			font-weight: 500;
			position: absolute;
			z-index:2;
			min-width: 261px;
			top:-16px;
			left:25px;
			&::after{
				content: "";
				height: 10px;
				width: 10px;
				position: absolute;
				background-color: $color-light;
				top: 19px;
				left: -6px;
				border-top: $placeholder-color solid 1px;
				border-left:  $placeholder-color  solid 1px;
				transform: rotate(315deg);
				@media screen and (max-width:870px) {
					right: 10px;
					top: -6px;
					left: unset;
					transform: rotate(45deg);
				}
				 
			}
			@media screen and (max-width:870px) {
				top:40px;
			right:0px;
			max-width:375px;
			left: unset;
			}
			@media screen and (max-width:870px) {
			min-width: unset;
			}
		}
		&:hover{
			.info-tooltip-detail{
				display: flex;
				
			}
		}
		}
	}
}
.quick-actions-section{
    border-radius: 20px;
	background: $color-light;
	padding: 15px 0;
	@media screen and (max-width:870px) {
		width:100%;
	}
    .quick-actions-btn{
        padding:0 15px;
    }
}
.devices-filter-accordion {
	.MuiPaper-root.MuiAccordion-root {
		background-color: $address-form;
		border: 0;
		box-shadow: none;
		border-radius: 10px;
	}
	.MuiAccordionSummary-content {
		margin: 0;
	}
	.total-devices {
		background-color: $filter-bg;;
		padding: 2px;
		border-radius: 50%;
		min-width: 23px;
		min-height: 23px;
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			color: $color-dark;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
	.MuiFormControlLabel-root {
		margin-right: 8px;
		.MuiFormControlLabel-label {
			font-size: 13px !important;
			font-weight: 500;
			line-height: 150%;
            color:$color-dark;
		}
	}
	.MuiAccordionDetails-root {
		padding: 0 15px 5px;
		background: $filter-bg;
		border-radius: 0px 0px 20px 20px;
		max-height: 9rem;
		overflow-y: auto;
	}
}
}