@import "./track-slide";

.track-solution {
    padding: 7rem 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: $color-secondary;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: uppercase;

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    h3 {
        text-align: center;
        font-size: 3.2rem;
        font-weight: 500;
        line-height: normal;
        margin-top: 6px;

        @media (max-width: 600px) {
            padding: 0 2rem;
            margin-top: 8px;
            font-size: 24px;
        }
    }

    .slider-container {
        margin-top: 46px;
        width: 100%;
        height: auto;

        .track-slider {
            width: 100%;
            // height: 330px;
            position: relative;

            &__wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 34px;
                overflow-x: hidden;
                scroll-behavior: smooth;
            }

            .next {
                position: absolute;
                right: -35px;
                top: 50%;
                transform: translate(0%, -50%);
                cursor: pointer;
                box-shadow: none;
                background-color: transparent;
            }

            .prev {
                position: absolute;
                left: -35px;
                top: 50%;
                transform: translate(0%, -50%) rotate(-180deg);
                cursor: pointer;
                box-shadow: none;
                background-color: transparent;
            }

            &__pagination {
                margin-top: 44px;
                display: none;
        
                &__dots {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-inline: 15px;
        
                    .active-dot {
                        g {
                            rect {
                                fill:  #1A74E2
                            }
                        }
                    }
                }
        
                &__prev {
                    transform: rotate(-180deg);
                }
        
                .un-active {
                    svg {
                        path {
                            stroke: #BDC9E2;
                        }
                    }
                }
        
                @media (max-width: 600px) {
                    display: flex;
                    justify-content: center;
                }
            }

            @media (max-width: 600px) {
                height: auto;

                .next {
                    right: -30px;
                    top: 50%;
                }

                .prev {
                    left: -30px;
                    top: 50%;
                }
            }
        }

        @media (max-width: 1100px) {
            padding-inline: 0;
        }

        @media (max-width: 600px) {
            margin-top: 25px;
        }
    }

    @media (max-width: 600px) {
        padding: 5rem 0;
    }
}